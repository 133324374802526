.map-container {
	margin-top: 10px;

	.map-title {
		font-weight: 700;
		margin: 0 35px;
		font-size: x-large;
		color: #4e6174;
		margin-bottom: 15px;
	}

	.coronavirus-title {
		font-size: 24px;
		font-weight: 600;
	}

	@media (max-width: 768px) {

		.map-title {
			font-weight: 700;
			//margin: 0 35px;
			font-size: x-large;
			color: #4e6174;
			margin-bottom: 15.78px;
		}

		.coronavirus-title {
			font-size: 20px;
		}

	}

	.quick-facts {
		background-color: #152234;
		color: grey;
		margin-left: 50px;
		border-radius: 12px;

		.country-select {
			padding: 19.725px;
			margin-top: 20px;

			.select-topic {
				font-weight: 400;
				color: white;
				margin-bottom: 10px;
			}

			.dropdown-options {
				color: white;
				background-color: #152234;
			}
		}

		.stats {
			padding: 15.78px;
			padding-bottom: 60px;

			.stats-header {
				font-weight: 700;
				color: #1284ff;
			}

			.stats-figure {
				color: white;
			}
		}
	}

	@media (max-width: 768px) {
		.quick-facts {
			margin: 0;
			border-radius: 0;
		}
	}

	.covid-map {
		background-color: #fcfcfc;
		border-radius: 12px;

		.covid-map-section {
			padding: 27.2px;
		}

		.map-worldwide-title {
			width: auto;
			font-size: 20px;
			text-transform: capitalize;
			display: flex;
			text-align: center;
			align-items: center;
		}
		.covid-map-content {
			padding: 19.2px;

			.tab-table {
				color: inherit;

				.first-td {
					font-weight: 600;
				}
			}

			.content {
				overflow: auto;
				fill: #3a414b;
			}
		}

		.covid-map-view {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px;
			color: #142234;
			border: 1px solid #142234;
			width: max-content;
			border-radius: 5px;
		}

		.tab-inactive {
			color: black;
			background-color: unset;
			border-color: #ff000000;
			margin: 0 auto;
		}

		.tab-inactive:hover {
			color: #fff;
			text-decoration: none;
		}

		.tab-active {
			background-color: #4e6174;
			border-color: #4e6174;
			color: #fff !important;
		}

		.tab-active:hover {
			background-color: #4e6174;
			border-color: #4e6174;
		}

		.tab-active:focus {
			box-shadow: none;
		}

		.select-map-dropdown {
			margin-top: 6px;
		}

		.list-data {
			height: 550px;
			overflow: scroll;
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
		}

		.list-data::-webkit-scrollbar {
			display: none;
		}
	}

	@media (max-width: 768px) {
		.covid-map {
			background-color: #fcfcfc;
			border-radius: 12px;

			.covid-map-section {
				padding: 0;
			}

			.map-worldwide-title {
				width: auto;
				font-size: 18px;
				text-transform: capitalize;
			}

			.covid-map-content {
				padding: 0;
				margin-top: 10px;

				.tab-table {
					color: inherit;

					.first-td {
						font-weight: 600;
					}
				}

				.content {
					overflow: auto;
					fill: #3a414b;
				}
			}

			.covid-map-view {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
				border: none;
				width: max-content;
				border-radius: 5px;
				background-color: #efefef;
				.tab-active {
					background-color: #142234;
				}
			}

			.tab-inactive {
				color: black;
				background-color: unset;
				border-color: #ff000000;
				margin: 0 auto;
			}

			.tab-inactive:hover {
				color: #fff;
				text-decoration: none;
			}

			.tab-active {
				background-color: #4e6174;
				border-color: #4e6174;
				color: #fff !important;
			}

			.tab-active:hover {
				background-color: #4e6174;
				border-color: #4e6174;
			}

			.tab-active:focus {
				box-shadow: none;
			}

			.select-map-dropdown {
				margin-top: 6px;
			}

			.list-data {
				height: 550px;
				overflow: scroll;
				-ms-overflow-style: none;  /* IE and Edge */
				scrollbar-width: none;  /* Firefox */
			}

			.list-data::-webkit-scrollbar {
				display: none;
			}
		}
	}
}

.examples {
	width: 1000px;
	margin: 0 auto;
	font-family: Arial, sans-serif;
	text-align: center;

	&__block {
		margin-bottom: 80px;
		padding-bottom: 80px;
		border-bottom: 1px dotted black;

		&__map {
			display: inline-block;
			width: 500px;

			//&--stroke {
			//  .svg-map {
			//    stroke-width: 0.5 !important; // Australia stroke is larger than other maps
			//  }
			//}

			&__tooltip {
				position: fixed;
				width: auto;
				min-width: 200px;
				padding: 10px;
				//overflow-x: scroll;
				border: 1px solid darkgray;
				background-color: #fefefe;
				box-shadow: 5px 5px 10px black;
			}
		}
	}
}
.mapColor {
	stroke: whitesmoke;
	fill: #3a414b;
	box-sizing: border-box;
}
.svg-map {
	stroke-width: 5px !important; // Australia stroke is larger than other maps
}

/*WORLD MAP ENDS*/

// for mobile phones
@media screen and (min-width: 320px) and (max-width: 480px) {
	.examples__block__map__tooltip {
		position: sticky;
	}
	.list-data {
		max-height: 60rem;
	}
	.toggle {
		float: none;
		width: inherit;
	}
}
// for tabs
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.examples__block__map__tooltip {
		position: sticky;
	}
	.list-data {
		max-height: 60rem;
	}
	.toggle {
		width: inherit;
		float: none;
	}
}

.quick-facts-select {
	border: 1px solid white;
	width: 100%;
	color: white !important;
	.MuiSelect-icon {
		color: white !important;
	}
}
