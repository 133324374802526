.perkeso_profession-level-component {
    cursor: pointer;
    width: 158px; // 150px
    // height: 140px; // 150px
    min-height: 150px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d8dadb;
    border-radius: 19px;
    opacity: 1;
    margin-bottom: 25px;
    color: #2e485c;
    font: normal normal 600 14px/21px Poppins;
    // padding: 45px 30px 30px 30px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.perkeso_profession-level-component:hover{
    background-color: #1D3B55;
    color: #ffffff;

}

.perkeso_profession-component__profession-name_overflow{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.perkeso_profession-component__profession-name {
    //padding: 50px 50px 50px 50px;
    text-align: center;
    height: 35px;
    //font: normal normal 600 14px/21px Poppins;
}

.perkeso_preferred-profession {
    width: 145px; // 190px
    // height: 145px; // 190px
    min-height: 145px;
    background: #1d3b55 0% 0% no-repeat padding-box;
    border: 5px solid #29b11d;
    border-radius: 19px;
    opacity: 1;
    margin-bottom: 25px;
    text-align: center;
    font: normal normal 600 15px/25px Poppins;
    color: #ffffff;
    // padding: 30px 20px 0px 18px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.courses_image{
    position: absolute;
    top:28px;
    left:26.8px;
    width: 100% !important;
    img {
        height: 40px;
        width: 100px;
    }
}


