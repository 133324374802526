.individual_skill {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    border-radius: 50px;
    padding: 7px 15px;
    //height: 38px;
    margin-top: 10px;
    cursor: pointer;

    .individual_skill__name {
        margin-left: 0;
    }

    .individual_skill__update {
        display: flex;
        visibility: hidden;
        width: 0;
    }

    .individual_skill__edit {
        display: flex;
        justify-content: center;
        align-items: center;

        input {
            background: transparent;
            border: none;
            height: fit-content;
            font-size: 13px;
            padding: 0;
            color: #212529;
        }
    }
}

.individual_skill:hover {
    .individual_skill__update {
        visibility: visible;
        width: fit-content;
        transition: visibility 1500ms linear 100ms;
    }
}

.update__pencil_icon,
.update__delete_icon,
.edit__check_icon,
.edit__close_icon {
    margin-left: 10px;
    background-color: #d8dadb;
    padding: 5px;
    border-radius: 20px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
