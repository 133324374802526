.dashboard_competition_title{
  font-size: 18px;
  font-weight: bold;
  color: #2E485C;
  text-transform: none;
  padding: 65px 0px 20px 0px;
}
.dashboard_competition_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;


  .dashboard_competition_img {
    height: 240px;
    width: 100%;
    object-fit: cover;
  }
  .dashboard_all_competitions {
    border: 1px solid #ccc;
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    max-width: calc(50.40% - 20px);
    cursor: pointer;
    margin-bottom: 15px;
    margin-right: 27px;

  }

  .dashboard_all_competitions:nth-child(2n + 2) {
    margin-right: 0px;
  }

  .dashboard_competition_title {
    padding: 10px 25px;
    width: 60%;
    font-size: 16px;
    font-weight: bold;
    max-height: 65px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: #2E485C;
  }
  .dashboard_side_border{
    border-left: 1px solid #D8DADB;
  }
  .dashboard_name_num{
    padding: 10px 15px 10px 25px;
    .dashboard_num{
      color: #2E485C;
      font-size: 30px;
      font-weight: bold;
      text-transform: none;
    }
    .dashboard_name{
      color: #8F9AA0;
    }
  }
}