.activeMember {
  background-color: #FAFAFA;
}
.tabs-sub {
  border-bottom:1px solid #D8DADB;
  padding: 1.5rem 1.5rem !important;
  .upload-sub-tabs .nav-link {
    font-size: smaller;
    text-align: center;
    padding: 8px !important;
    color: #525A60 !important;
    font-weight: normal !important;
    width: 100%;
    border-radius: 40px !important;
    border-bottom: none !important;
    background: #F5F5F5 !important;
  }
  .upload-sub-tabs .nav-link.active {
    text-align: center;
    padding: 9px !important;
    color: white !important;
    font-weight: normal !important;
    width: 100%;
    border-radius: 40px !important;
    border-bottom: none !important;
    background: #525A60 !important;
  }
}
.job-card-row {
  align-items: center;
  border-bottom:1px solid #D8DADB;
  cursor: pointer;
  padding: 1.0rem 1.5rem;
  .person-name {
    color: #525A60;
    font-weight: 600;
    font-size: 14px !important;
  }
  .profile-pic {
    height: 65px !important;
    width: 65px !important;
    object-fit: cover;
    justify-content: center;
    border-radius: 10px !important;
  }
}

.job-skill-detail-organization {
    height: 100%;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 0;
    border: none;
    transition: all 0.3s;
    align-items: center;
    margin-top: 5px;
}
.org-profile_detail{
  padding: 5px;
  width: 60px;
  height: 60px;

}

.org-profile{
  background-color: #e4e6e7;
  height: 80px;
  width: 80px;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.org-profiles{
  background-color: #e4e6e7;
  height: 65px;
  width: 65px;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.org-profile-pic {
  height: 50px !important;
  width: 50px !important;
  border-radius: 17%;
  object-fit: cover;
  justify-content: center;
}


.opportunity-details {
  padding: 30px;
  .sub-title {
    margin-top:15px;
    font-size: 13px;
    font-weight: 600;
  }
  .job-desc-p {
    line-height: 26px;
    white-space: pre-wrap;
    height: 10rem;
  }
  .info-div-outer {
    border-top: 1px solid #D8DADB;
    border-bottom: 1px solid #D8DADB;
    padding: 10px 0px 10px 0px;
    margin-top: 10px ;
  }
  .nav-tabs {
    border-bottom: none !important;
  }
  .outer-icon-div{
    margin-right: 5px;
    display: flex;
  }
  .grey-text {
    color:#525A608c;
    font-weight: 400;
  }
  .job-basic-icon {
    width: 26px;
    height: 27px;
  }
  .details-icon {
    float:left;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
  }
  .info-div {
    min-width: 4.2rem;
    width: auto;
    text-align: center;
    font-size: 12px;
    justify-content: center;
  }
  .profile-pic {
    height:80px;
    width:80px;
    border-radius: 10%;
    object-fit: cover;
    justify-content: center;

  }
  .person-name {
    color:#525A60;
    font-weight: 600;
    font-size: large
  }
  .grey-small-text {
    color:#525A608c;
    font-weight: 400;
    font-size: 11px;
  }
  .grey-small-text-dark {
    font-weight: 600;
    color:#525A60;
    font-size: 11px;
  }
  .skill-component__skill-name {
    padding: 5px 15px 5px 15px !important;
  }
  .invite-btn {
    float: left;
    width: 35%;
    height: 80%;
    font-size: 12px;
  }
  .follow-btn {
    float: left;
    width: 35%;
    height: 40px;
    margin-right:10px !important;
    border: 2px solid #2e485c;
    font-size: 12px;
    font-weight: 600;
    background-color: white;
    color: #2e485c;
  }
  .compare-btn {
    width: 75%;
    height: 30px;
    margin-right: 10px !important;
    border: 1px solid #D8DADB;
    font-size: 12px;
    font-weight: 700;
    background-color: white;
    color: #2E485C;
    padding: 1px;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    align-items: center;
    label {
      margin-top:1px;
      margin-bottom: 1px;
    }
  }
  .follow-btn-filled {
    float: left;
    width: 35%;
    height: 40px;
    margin-right:10px !important;
    border: 2px solid #2e485c;
    font-size: 12px;
    font-weight: 600;
    background-color:#2e485c;
    color: white;
  }
  .btn svg {
    height: 24px !important;
    width: 24px !important;
  }
  .skill-level-component {
    display: flex;
    min-height: 30px;
    margin: 10px 0 0 20px;
    background: #f5f5f5;
    border-radius: 50px;
    height: auto;
    width: fit-content;
  }
  .skill-level-component-verified {
    display: flex;
    min-height: 30px;
    margin: 10px 0 0 20px;
    background: #E8FDF1;
    border-radius: 50px;
    height: auto;
    width: fit-content;
  }
  .invite-team-skill {
    display: flex;
  }
  .skill-match-color {
    color: #13AD13;
    text-align: right;
    font-weight: 600;
  }
  .skill-claimed-badge {
    background-color: #f5f5f5;
    height: 25px;
    width: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto 0 auto 4px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    line-height: 26px;
  }
  .skill-certified-badge {
    height: 25px !important;
    width: 25px !important;
  }
  .skill-completed-badge {
    height: 25px !important;
    width: 25px !important;
  }
  .view_button {
    align-items: center;
    font-weight: 700;
    cursor: pointer;
    background: white;
    justify-content: center;
    text-align: center;
    color: #2E485C;
  }
  .view-btn-center {
    align-content: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
  .individual__skills_list_close {
    height: 130px;
    overflow:hidden;
  }
  .individual__skills_list_open {
    height: auto;
  }
  .topbar__btn {
    display: inline;
  }
  .topbar__menu-wrap{
    top: -30px;
    margin-right: 7px;
    width: 0px;
    padding: 42px;
  }
}

.job-matches-new {
  .recommendation-view {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 10px 20px;
    border-radius: 10px;
    background-color: #F5F5F5;
    margin-bottom: 10px;
  }
  .nav-link.active {
    border-bottom: none !important;
  }
  .dashboard .dashboard__competitor-wrapper {
    display: flex !important;
    justify-content: normal !important;
    width: auto !important;
    margin-top: 10px;
  }

  @media (min-width: 1025px) and (max-width: 1280px) {

    .compare-btn {
      width: 80%;
      height: 35px;
      margin-right: 4px !important;
      font-size: 9px;
      font-weight: 600;
      padding: 5px;
    }

  }
}