.competition-tabs {
   .tab-pane {
      padding-top: 0px;
   }
}

.competition_list {
   padding: 0px 50px;
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   //justify-content: space-between;

   .competition_header_outer {
      margin-bottom: 30px;
      margin-top: 30px;
      width: 100%;
      .competition_header {
         font-size: 26px;
         font-weight: bold;
         color: $color-accent;
      }
      .host_btn,
      .filter_btn {
         margin-top: 0px;
         white-space: nowrap;
         font-size: 14px;
         width: auto !important;
         max-width: unset;
         min-width: 197px;
         padding: 0;
         height: 40px;
         margin: 0;
         color: #fff;
         svg {
            margin-top: 0px;
            position: relative;
            top:-2px;
         }
      }
      .filter_btn {
         background: $color-light-gray-2;
         border-color: $color-light-gray-2;
         //color: $colorBackgroundBody;
         color: $color-accent;
         margin-left: 10px;
         min-width: unset;
         padding: 0 20px;
         svg {
            fill: $color-accent;
            width: 28px;
            height: 28px;
         }
      }
   }
   .competition_img {
      height: 250px;
      width: 100%;
      object-fit: cover;
   }
   .all_competitions {
      border: 1px solid #ccc;
      border-radius: 16px;
      overflow: hidden;
      width: 100%;
      max-width: calc(33.33% - 20px);
      cursor: pointer;
      margin-bottom: 15px;
      margin-right: 30px;
   }
   .all_competitions:nth-child(3n + 3){
      margin-right: 0px;
   }
   .competition_title {
      padding: 20px 25px;
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      max-height: 65px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
   }
   .company_detail {
      display: flex;
      align-items: center;
      padding: 5px 25px;
      img {
         height: 30px;
         width: 30px;
         border-radius: 100%;
      }
      p {
         margin-left: 10px;
         margin-bottom: 0px;
         font-size: 14px;
         font-weight: bold;
      }
   }
   .competition_content {
      font-size: small;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      padding-bottom: 0px;
      margin-bottom: 20px;
      min-height: 92px;
   }
   .competition_team_detail {
      label {
         padding: 0 10px;
         line-height: 17px;
         font-size: 14px;
      }
      label:first-child {
         padding-left: 0px;
         border-right: 2px solid #2e485c;
      }
   }
   .competition_post_detail {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .competition_icon{
            color: $color-dark-gray;
            width: 18px;
            margin-right: 10px !important;
      }
      label {
         img {
            width: 20px;
            height: auto;
            margin-right: 10px;
            float: left;
         }
         p {
            font-size: 12px;
            white-space: nowrap;
            display: inline-block;
            margin-bottom: 10px;
         }
      }
   }
   .competition_price {
      font-size: 30px;
      font-weight: bold;
      display: flex;
      align-items: flex-start;
      span {
         font-size: 18px;
         position: relative;
         top: 3px;
      }
   }
   .MuiCardContent-root {
      padding-left: 25px;
      padding-right: 25px;
   }
}

.competition_sub_title_list {
   ul {
      padding-left: 0px;
      li {
         list-style: none;
         color: $color-dark-gray;
         margin-right: 43px;
         button {
            font-weight: normal;
            background: transparent;
            border: 0;
            padding: 0;
            color: $color-dark-gray;
         }
      }
      li.active {
         button {
            font-weight: bold;
         }
      }
   }
}
.edit_btn {
   background: transparent;
   padding: 0px;
   border: 0px;
}
.competition_sub_title_list {
   margin-bottom: 35px;
   margin-top: 13px;
}
.competition_box {
   border-top: 1px solid #e8e8e8;
   padding: 54px 0;
   display: flex;
   align-items: flex-start;
   flex-wrap: wrap;
   .competition_full {
      width: 100%;
      background: $color-light-gray-4;
      padding: 48px 10px 40px;
      text-align: center;
      margin-top: 69px;
      p {
         color: $color-dark-gray;
         width: 100%;
         margin-bottom: 37px;
      }
   }
   .competition_left_box {
      width: 25%;
      label {
         font-size: 16px;
         color: $color-accent;
         font-weight: 600;
      }
   }
   .competition_right_box {
      width: 73%;
      position: relative;
      padding-right: 100px;
      font-size: 14px;
      min-height: 50px;
      .edit_btn {
         position: absolute;
         right: 0;
         top: 0;
         // background: #f5f5f5;
         border-radius: 100%;
         display: none;
      }
      .edit_price_btn {
         margin-left: 10px;
         position: relative;
      }
      .edit_price_btn svg{
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
      .delete_price_btn svg{
         width: 19px;
         height: 19px;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
      p:first-child {
         margin-top: 0px;
      }
      ul {
         li {
            list-style:disc;
         }
      }
      ol {
         li {
            list-style:decimal;
         }
      }
      .prize_list {
         ul {
            padding-left: 0px;
            li {
               padding-left: 0px;
               list-style: none;
               display: flex;
               align-items: center;
               width: 100%;
               margin-bottom: 44px;
               .prize_img {
                  width: 72px;
                  height: 72px;
                  min-width: 72px;
                  border-radius: 100%;
                  background: #f4f4f4;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 43px;
                  img {
                     width: auto;
                  }
               }
               .prize_detail {
                  label {
                     width: 100%;
                     font-size: 16px;
                     font-weight: bold;
                     color: $color-dark-gray;
                     margin-bottom: 0px;
                     display: flex;
                     .edit_btn{
                        display: none;
                     }
                  }
                  span {
                     color: $color-dark-gray;
                     font-size: 12px;
                  }
               }
               .prize_detail:hover {
                  .edit_btn{
                     display: block;
                     cursor: pointer;;
                  }
               }
               .prize_detail_edit {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  justify-content: space-between;
                  > button {
                     white-space: nowrap;
                     min-width: 100px;
                     height: 40px;
                     margin-bottom: 0px;
                  }
                  textarea.MuiOutlinedInput-inputMultiline{
                     padding: 10px 0 0 0;
                     height: 26px;
                  }
               }
            }
            li:last-child {
               margin-bottom: 0px;
            }
         }
      }
      .red_btn {
         background: #951b1e;
         border-radius: 4px;
         border: 0px;
         font-size: 14px;
         color: #fff;
         font-weight: bold;
         max-width: 208px;
         width: 100%;
         height: 44px;
         margin-top: 30px;
      }
      .edit_content_box {
         textarea {
            padding: 20px;
            font-size: 14px;
            color: $color-accent;
            margin-bottom: 20px;
         }
      }
      .edit_save_btn {
         margin-top: 0px;
         white-space: nowrap;
         font-size: 14px;
         width: auto !important;
         max-width: unset;
         min-width: 117px;
         padding: 0;
         height: 44px;
         margin: 0;
         font-size: small;
         min-width: 98px;
         height: 38px;
      }
      .material_download_btn {
         min-width: 220px;
         position: absolute;
         right: 0;
         top: -35px;
      }
      .download_btn {
         min-width: 190px;
      }
      .material_download_span_text{
         float: right;
         font-size: 12px;
      }
   }
   .competition_right_box:hover .edit_btn {
      display: block;
   }
}
.common_red_btn {
   background: $color-brand;
   border-radius: 4px;
   border: 0px;
   font-size: 14px;
   color: $color-white;
   font-weight: bold;
   padding: 0 48px;
   height: 44px;
}
.common_red_border_btn {
   background: $color-white;
   border: 2px solid $color-brand;
   border-radius: 4px;
   font-size: 14px;
   color: $color-brand;
   font-weight: bold;
   padding: 0 15px;
   height: 44px;
}
.common_red_border_btn:hover {
   background: $color-white !important;
   color: $color-brand !important;
}
.common_red_border_btn:hover::before {
   display: none !important;
}
.host_form {
   width: 100%;
   max-width: 625px;
   margin: auto;

   .input_box {
      display: flex;
      flex-direction: column;
      > * {
         margin-bottom: 15px;
      }
      label {
         font-size: 16px;
         color: $color-dark-gray;
         margin-bottom: 10px;
      }
      input {
         border-radius: 4px;
         height: 48px;
         border: 2px solid $border-color;
         padding: 10px;
         font-size: 14px;
         color: $color-dark-gray;
         :focus {
            outline: none;
         }
      }
      textarea {
         font-size: 14px;
         color: $color-dark-gray;
         border-radius: 4px;
         padding: 10px;
         border: 2px solid $border-color;
         :focus {
            outline: none;
         }
         :focus-visible {
            outline: none;
         }
      }
      > .competition-select {
         padding: 0px;
         border: 0px solid $border-color;
         border-radius: 4px;
         width: 100%;
         text-align: center;
         .competition-select__control {
            border: 0px;
            display: flex;
            flex-wrap: nowrap;
           border: 2px solid $border-color;

         }
         .competition-select__control.css-1pahdxg-control{
            box-shadow: unset !important;
            border:2px solid #951b1e !important;
         }
         .competition-select__value-container {
            height: 43px;
         }
         .competition-select__clear-indicator {
            padding: 0px;
         }
         span.competition-select__indicator-separator {
            padding: 0px;
            display: none;
         }
         .competition-select__dropdown-indicator {
            padding-left: 3px;
         }
      }
      .half_input_box {
         align-items: center;
         justify-content: space-between;
         > span {
            min-width: 40px;
            text-align: center;
         }
         span {
            color: $color-dark-gray;
            font-size: 14px;
         }
         .react-datepicker-wrapper {
            max-width: calc(50% - 20px);
            width: 100%;
            input {
               width: 100%;
               ::placeholder {
                  color: $color-dark-gray;
                  font-size: 14px;
                  opacity: 1;
               }
            }
         }         

         .select_box {
            width: 100%;
            max-width: calc(50% - 20px);
            font-size: 14px;
            color: $color-dark-gray;
            border-radius: 4px;
            border: 2px solid $border-color;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
               padding: 10px;
            }
            .competition-select,
            .MuiInputBase-root {
               padding: 0px;
               border: 0px;
               border-left: 2px solid $border-color;
               border-radius: 0px;
               width: 83px;
               text-align: center;
               .competition-select__control {
                  border: 0px;
                  box-shadow: unset !important;
               }
               .competition-select__value-container {
                  height: 43px;
                  min-width: 30px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  .competition-select__placeholder ~ div {
                     position: absolute;
                  }
                  .competition-select__single-value ~ div {
                     position: absolute;
                  }
               }
               .competition-select__clear-indicator {
                  padding: 0px;
               }
               span.competition-select__indicator-separator {
                  padding: 0px;
                  display: none;
               }
               .competition-select__dropdown-indicator {
                  padding-left: 3px;
               }
               .MuiSelect-select {
                  padding-right: 0px;
               }
               .MuiSelect-select:focus {
                  background: transparent;
               }
               svg {
                  /* display: none; */
                  color: $border-color;
               }
            }
            .MuiInput-underline::after,
            .MuiInput-underline::before {
               display: none !important;
            }
            :focus {
               outline: none;
            }
         }
         .select_box:focus{
            border: 2px solid #951b1e;
         }
      }
      .fancy_file_upload {
         position: relative;
         input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            // z-index: 11;
            opacity: 0;
         }
         label {
            border-radius: 4px;
            height: 48px;
            border: 2px solid $border-color;
            padding: 10px;
            font-size: 14px;
            color: $color-dark-gray;
            width: 100%;
            margin: 0px;
         }
      }
      .no_border{
         .MuiInputBase-root{
            padding: 0;
         }
         textarea,input{
            border: none;
         }
         input{
            height: 30px;
            padding-left: 12px !important;
         }
      }
      .input_field_box_height{
         textarea{
            height: 25px !important;
         }
      }
   }
   .upload_card_btn {
      background: $color-brand;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      letter-spacing: 0px;
      color: $color-white;
      padding: 14px;
      border: unset;
      margin-top: 26px;
      font-weight: bold;
      margin-bottom: 20px;
   }
   .competition_add_btn{
      min-width: 200px;
   }
}
body {
   overflow-x: hidden;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
   border-color: #951b1e;
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
   border-color: transparent;
}
.host_title {
   position: relative;
   padding-bottom: 20px;
   margin-bottom: 50px;
   ::before {
      content: '';
      position: absolute;
      width: 150vw;
      left: -50vw;
      height: 2px;
      background: #b5b5c6;
      bottom: 0;
   }
   h1 {
      font-size: 26px;
      line-height: 35px;
      font-weight: bold;
   }
   .breadcrumb {
      background: transparent;
      padding: 0px;
      ul {
         display: flex;
         color: $color-light-gray;
         font-size: 14px;
         padding-left: 0px;
         li {
            padding: 0 10px;
         }
         li:first-child {
            padding-left: 0px;
         }
         li,
         a {
            font-size: 12px;
            color: $color-light-gray;
            list-style: none;
         }
      }
   }
}
.MuiPaper-root {
   min-width: 83px;
}

.competition-select__menu {
   .competition-select__option {
      text-align: left;
   }
}
.bg-warning{
   background-color:#951b1e !important
}
.custom_error_msg{
   color: #f44336 !important;
   margin-bottom: 0px !important;
   margin-top: -13px !important;
   margin-left: 14px !important;
}

.competition_card_error_msg{
   margin-left: 25% !important;
}

.add_new_prizes.disabled{
   background-color: #951B1E !important;
   border-color: #951B1E !important;
}

span.material_download_span_text.file_name_text {
   float: none !important;
   top: -28px;
   position: absolute;
   right: 222px;
}

.custom__refresh{
   background: #fff0;
   z-index: 6;
   position: fixed;
}
.position-relative {
   position: relative;
}
.custom__refresh.specific_div{
   position: absolute;
}

.prefix__svg_image{
   width: 500px;
}
.svg_top svg.mdi-icon {
   top: calc(15% - 24px);
}.mdi-icon {
   top: calc(15% - 24px);
}

.prize_icon{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 25px;
   height: 25px;
   border-radius: 50%;
   background-color: #f5f5f5;
}
.edit_btn.edit_price_btn.delete_price_btn.update__delete_icon.prize_icon{
   background-color: #f5f5f5;
}

.competition-timezone-select{
   > div {
      border: 2px solid #ccc;
      height: 50px;
      border-radius: 4px;
      >div{
         >div{
            padding-left:10px;
         }
      }
      > div:first-child {
         border: none !important;
         background: transparent;
         height: 46px;
      }
      > div:nth-child(2) {
         border: none !important;
         background: transparent;
         height: 46px;
      }
   
   }
   .css-1okebmr-indicatorSeparator{
      display: none;
   }
   .css-ikt7kq-IndicatorsContainer{
      padding-left: 0px;
   }
   .css-yulp85-indicatorContainer{
      width: 26px;
   }
   .css-1uccc91-singleValue{
      font-size: 14px;
      color: $color-dark-gray;
   }
}


.remove_form_control_underline .MuiInput-underline::before {
   display: none;
}
.remove_form_control_underline .MuiInput-underline:after {
   display: none;
}