.take-skill-quiz-modal {
   @media (min-width: 576px) {
      max-width: 600px;
      margin: 1.75rem auto;
   }

   .modal-content {
      padding: 0 !important;
   }

   .modal-header {
      padding-left: 22px;
   }

   .modal-title {
      text-align: left;
      .title {
         text-transform: none;
         color: #2e485c;
         font-size: 18px;
      }
      .subtitle {
         color: #525a60;
         font-size: 11px;
         text-transform: none;
         font-weight: 500;
      }
   }

   .modal-body {
      padding: 0;
   }

   .MuiFormGroup-root {
      display: flex !important;
      flex-wrap: unset !important;
      flex-direction: unset !important;
   }
   .MuiTypography-body1 {
      font-family: 'Poppins', sans-serif !important;
      text-transform: none;
      color: #2e485c;
      font-size: 14px;
      font-weight: 500;
   }
   .PrivateSwitchBase-root-1 {
      padding: 6px;
   }
   .MuiSvgIcon-root {
      height: 20px;
      width: 20px;
   }

   .quiz-items {
      margin-top: 10px;
      height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
         width: 0 !important;
      }
   }
   .quiz-type-options {
      padding: 0 24px;
   }
   .quiz-item {
      border-bottom: 1px solid #f8f9fa;
      padding: 12px 36px;
      &:last-child {
         border: none;
      }
      .quiz-title {
         color: #525a60;
         font-size: 14px;
         font-weight: 500;
         line-height: 2;
      }

      .quiz-action-btn {
         color: $color-brand;
         float: right;
         cursor: pointer;
         font-weight: 600;
         font-size: 14px;
         margin-right: 4px;
         line-height: 1.9;
      }

      span.skill-completed-badge {
         float: left;
         margin-right: 12px;
      }

      &:hover {
         background-color: #f8f9fa;

         .quiz-action-btn {
            transform: translate(0, 1px);
         }
      }
   }

   @media (max-width: 768px) {
      .modal-content {
         padding: 20px !important;
      }
      .quiz-type-options {
         padding: 0 0px;
      }
      .quiz-item {
        border-bottom: 1px solid #f8f9fa;
        padding: 12px 12px 12px 15px;
    }
   }
}
.MuiRadio-colorPrimary.Mui-checked {
   color: $color-brand !important;
}
.MuiTypography-body1 {
   font-family: 'Poppins', sans-serif !important;
}
.MuiFormGroup-root {
   display: flex !important;
   flex-wrap: unset !important;
   flex-direction: unset !important;
}

MuiTypography-body1 {
   font-family: 'Poppins', sans-serif;
   text-transform: none;
   color: #2e485c;
   font-size: 14px;
   font-weight: 500;
}
.PrivateSwitchBase-root-1 {
   padding: 6px;
}
.MuiSvgIcon-root {
   height: 20px;
   width: 20px;
}
