.smart-skills-show-more-container {
  color: #2e485c;

  .toggle-show-more-container {
    text-align: center;
    margin-top: 10px;

    span {
      width: fit-content;
      font-size: 11px;
      cursor: pointer;

      svg {
        margin-top: -1px;
      }
    }
  }

  .show-more-container-hidden {
    display: none;
  }

  .smart-skills-show-more-container {
    margin-top: 20px;

    .smart-skills-show-more-content {

      .title,
      .message {
        font-weight: 800;
        margin-bottom: 5px;
      }

      .title {
        font-size: 14px;
      }

      .message {
        font-size: 11px;
      }
    }
  }
}
