.perkeso_orgprofile__input {
   max-width: 193px;
   display: inline;
   margin-right: 15px;

   .react-select__control:hover {
      border-color: #951b1e !important;
   }

   .react-select__control.react-select__control--is-focused {
      border-color: #951b1e !important;
   }
}

.perkeso_orgprofile__input-button {
   display: inline;
}

.perkeso_profession_list {
   align-items: center;
   max-height: 330px;
   margin-bottom: 20px;
   margin-right: 10px;
   overflow: hidden;
}

.perkeso_profession_list:hover {
   overflow-y: scroll; /* Add the ability to scroll */
}
