.compare-employees-data-literacy-skills-row,
.compare-employees-software-skills-row,
.compare-employees-other-skills-row {
  .compare-employees-data-literacy-skills-cell,
  .compare-employees-software-skills-cell,
  .compare-employees-other-skills-cell {
    padding: 15px;
    .individual-skill {
      background: #f5f5f5;
      width: fit-content;
      border-radius: 15px;
      font-size: 12px;
      cursor: pointer;
      display: flex;
      margin: 10px 0;
      padding: 8px 12px;
      user-select: none;

      .level0 {
        @include themify($themes) {
          color: themed("colorText");
        }
        font-weight: bold;
        padding: 0;
        padding-left: 15px;
      }
      .level1 {
        @include themify($themes) {
          color: themed("colorGreen");
        }
        font-weight: bold;
        padding: 0;
        padding-left: 15px;
      }
      .level2 {
        @include themify($themes) {
          color: themed("colorLevel2Skill");
        }
        font-weight: bold;
        padding: 0;
        padding-left: 15px;
      }
      .level3 {
        color: $color-blue;
        font-weight: bold;
        padding: 0;
        padding-left: 15px;
      }
      .level4 {
        @include themify($themes) {
          color: themed("colorDeepBlue");
        }
        font-weight: bold;
        padding: 0;
        padding-left: 15px;
      }
      .level5 {
        @include themify($themes) {
          color: themed("colorLevel5Skill");
        }
        font-weight: bold;
        padding: 0;
        padding-left: 15px;
      }
    }
  }
}
