.qandACard {
    // width: 32%;
    height: auto;
    border: 1px solid #D8DADB;
    padding: 20px;
    border-radius: 15px;
    float: left;
    margin-right: 15px;
    margin-top: 15px;
    max-height: 340px;
    min-height: 320px;
    

    .qandACard-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 30px;
        line-height: 24px;
        cursor: pointer;
        height: 50px;
    }
    .qandACard-subtitle {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 400;
        font-size: small;
        margin-bottom: 30px;
        max-height: 85px;
        min-height: 85px;
        overflow: hidden;
        cursor: pointer;
    }
    .qandATotalAns {
        font-size: 14px;
        font-weight: 500;
        color: #8E8E8E;
    }
    .qandACounts {
        font-size: 13px;
        color: #8E8E8E;
        margin-left: auto;
    }
    .qandA-profile-pic {
        border-radius: 50%;
        height: 35px;
        width: 35px;
    }
    .qandA-profile-name {
        height: 30px;
        font-weight: 600;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        
        margin: 0 0 0 8px;
    }
    .qandA-profile {
        // margin-top: auto;
        padding-top: 30px;
    }
    .card-icon {
        margin-left: 16px;

        &.selected {
            color: $color-brand
        }
    }
    .qlist-bottom {
        margin-top: 20px;
        display: flex;
    }
}
.material-table__pagination {
    margin-right: 6%;
}

.paginationIcon {
    padding: 0px 0px 0px 5px !important;
}
.pagination-select {
    padding:0px 15px;
    width:70px;
    float:left;
    height:30px;
}
.pagination-options {
    display: flex;
    flex-direction: row;
    align-items: center;
}
ul.pagination {
	margin-top: 0;
	margin-bottom: 0;
}
ul.pagination li.page-item.active a.page-link {
	color: #445565 !important;
	background-color: #e3e7eb !important;
	border-color: #ced4da !important;
}
ul.pagination a.page-link {
	padding: 0.75rem 1rem;
	min-width: 3.5rem;
	text-align: center;
	box-shadow: none !important;
	border-color: #ced4da !important;
	color: #6b88a4;
	font-weight: 900;
	font-size: 1rem;
}
ul.pagination a.page-link:hover {
	background-color: #f4f4f4;
}
.icon-color {
    color: #808080;
    cursor: pointer
}

.MuiTablePagination-toolbar {
    p {
        font-weight: bold;
    }
    .MuiTablePagination-select {
        border-radius: 3px!important;
        border: 1px solid grey!important;
    } 
    .MuiSvgIcon-root {
        color: #808080a6 !important;
    }
}
.modal-question {
    .WAMuiChipInput-inputRoot-6.WAMuiChipInput-outlined-9 {
        padding-top: 10.5px;
        padding-bottom: 10.5px;
        padding-left: 14px;
        padding-right: 14px;
    }
    .WAMuiChipInput-outlined-9 input {
        margin-top: 0px;
        padding-top: 4px;
        margin-bottom: 0px;
        padding-bottom: 1px;
    }
    .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(14px, 12px) scale(1);
        pointer-events: none;
    }
    .WAMuiChipInput-chip-20 {
        float: left;
        margin: 0 8px 0px 0;
    }
    .MuiChip-deleteIcon {
        display: none;
    }
    .MuiChip-root {
        height: 20px !important;
    }
}