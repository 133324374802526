.withOutTeamTime{
    text-align: center;
    align-content: center;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    padding: 100px;
}
.grey-box-teams-left {
    border:2rem solid #F5F5F5;
    padding: 25px 25px 17px 25px;
}
.grey-box-teams-right {
    border-top: 2rem solid #F5F5F5;
    border-bottom: 2rem solid #F5F5F5;
    border-right: 2rem solid #F5F5F5;
    padding: 25px 25px 17px 25px;
}

.form-team, .own-team {
    .title-row {
        margin-top: 4%;
        margin-bottom: 4%;
    }
    .title {
        color: #2e485c;
        font-size: 23px;
        font-weight: 700;
        margin-bottom: 0px;
    }
    .sub-title {
        margin-top: 1px;
        color: #2e485c;
        font-size: 14px;
        width: 85%;
    }
    .member-title {
        color: #2e485c;
        font-size: 14px;
        font-weight: 700;
    }
    .btn-competition {margin-top: 5%;
        padding: 5px 5px;
        width: 25%;
    }
    .person-name {
        color:#525A60;
        font-weight: 600;
        font-size: 13px;
    }
    .grey-small-text {
        color:#525A608c;
        font-weight: 400;
        font-size: 11px;
    }
    .team-member-info {
        min-height: 200px;
        max-height: 100%;
    }
}
.ownTeamModal {
    color: #2E485C;
    .MuiChip-label {
        color:#2e485c
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 2px !important;
    }
    .WAMuiChipInput-inputRoot-6.WAMuiChipInput-outlined-9 {
        padding-top: 8.5px;
        padding-bottom: 8.5px;
        padding-left: 14px;
        padding-right: 14px;
    }
    .WAMuiChipInput-outlined-9 input {
        margin-top: 0px;
        padding-top: 4px;
        margin-bottom: 0px;
        padding-bottom: 1px;
    }
    .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(14px, 12px) scale(1);
        pointer-events: none;
    }
    .WAMuiChipInput-chip-20 {
        float: left;
        margin: 0 8px 0px 0;
    }
    .MuiChip-deleteIcon {
        display: none;
    }
    .MuiChip-root {
        background-color: #F5F5F5;
        height: 25px !important;
    }                                                           
}

.own-team {
    .profile-pic {
        height:45px;
        width:45px;
        border-radius: 50%;
    }
    .skill-match-color {
        color:#13AD13;
        text-align: right;
        font-weight: 600;
    }
}

.form-team {
    .team-profile-pic {
        height:50px;
        width:50px;
        border-radius: 7%;
    }
    .team-member-info {
        min-height: 200px;
        max-height: 100%;
    }
}