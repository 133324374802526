.compare-employees-add-profile-modal {
  height: auto;
  color: #2e485c;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-footer {
    border-top: none !important;
  }
  .modal-content {
    border-radius: 5px;
    text-align: center;
    width: fit-content;
    min-width: 700px;
    height: 100%;
    .header {
      display: flex;
      justify-content: space-between;

      .title-container {
        .title {
          font-weight: bold;
          font-size: 24px;
        }
      }

      .close-icon {
        svg {
          cursor: pointer;
        }
      }
    }

    .type {
      margin: 20px 0;

      .MuiRadio-colorSecondary.Mui-checked {
        color: $color-blue;
      }
    }

    .compare-employees-table {
      height: 19rem;
      width: 100%;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      .compare-employees-employee-name {
        //display: flex;
        //align-items: center;

        img {
          height: 35px;
          width: 35px;
          margin-right: 10px;
          border-radius: 50%;
        }
      }

      .compare-employees-employees-skills {
        width: 180px;
      }

      .compare-employees-select-employees-checkbox {
        width: 75px;
      }

      .MuiCheckbox-colorSecondary.Mui-checked {
        color: $color-blue;
      }
    }

    .makeStyles-table-8::-webkit-scrollbar {
      display: none;
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
    }

    .compare-employees-save-button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;

      .compare-employees-save-button {
        background: #902027;
        padding: 10px 20px;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
      }

      .compare-employees-save-button:hover {
        background: #902027;
      }
    }
  }
}
