.group-chat-page-title {
    // margin: 10px 0 0 15px;
    border-bottom: 1px solid #B5B5C6;
}

.grp-chat-sender {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.grp-chat-sender-content {
    margin-left: 25px;
}