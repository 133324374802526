.MuiBox-root-2 {
    margin-bottom: 0 !important;
}

.perkeso_rating-jobs-description {
    padding-bottom: 0;
    margin-bottom: -0.5rem !important;
    margin-top: 0.5rem !important;

    display: flex;
    align-items: center;

    .rating_value {
        margin-left: 10px;
    }
}

fieldset {
    padding: 0;
}

.MuiRating-root {
    color: #951B1E !important;
    margin-top: 5px;
}
