.profile__personal_details__container {
    overflow: hidden;

    .row {
        .col {
            display: flex;

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
