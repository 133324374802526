.organization_profile {
	width: 100%;
	margin: 0;
	padding: 0;

	.header {
		display: flex;
		align-items: center;
		padding-bottom: 50px;
		width: 20%;

		.header__back_arrow {
			font-size: x-large;
		}

		h4 {
			font-weight: 800;
			margin-left: 10px;
		}
	}

	.body {
		display: flex;

		.left_content {
			padding: 0 30px;

			img {
				width: 120px;
				height: auto;
				border: none;
				border-radius: 12%;
			}
		}

		.right_content {
			margin-right: 30px;
			width: 100%;

			.org_details {
				h4 {
					font-weight: 700;
					padding-bottom: 10px;
				}

				.descrption {
					padding-bottom: 10px;
					text-align: justify;
				}

				.descrption_hidden {
					height: 60px;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.descrption_show {
					height: fit-content;
					overflow: visible;
					text-overflow: unset;
				}

				.read_more {
					width: fit-content;
					background-color: #0092ff;
					color: #ffffff;
					padding: 10px;
					margin: 20px 0;
					border-radius: 10px;
					cursor: pointer;
				}
			}

			.org_images {
				margin-top: 30px;
				display: flex;
				flex-flow: column;

				.org_images__heading {
					display: flex;
					justify-content: space-between;
					align-items: center;

					h4 {
						font-weight: 700;
						padding-bottom: 10px;
					}

					.addNew {
						color: #0092ff;
						cursor: pointer;
					}
				}

				.ReactGridGallery_tile-overlay {
					border-radius: 5px;
				}
				.ReactGridGallery_tile-viewport {
					border-radius: 5px;
				}
				img {
					border-radius: 5px;
				}
			}

			.org_benefits {
				//margin-top: 30px;
				width: 100%;

				.org_benefits__header {
					display: flex;
					justify-content: space-between;

					h4 {
						font-weight: 700;
					}

					.add_benefits {
						display: flex;
						justify-content: space-between;
						align-items: baseline;
						cursor: pointer;
						font-weight: 100;
						font-size: small;

						p {
							color: #0092ff;
							font-weight: 600;
							padding-left: 10px;
						}
					}
				}

				.org_benefits__list {
					background-color: #fcfcfc;
					padding: 20px 15px;
					margin-top: 5px;
					border-radius: 5px;

					.benefits_container {
						display: flex;
						padding: 15px;

						.icon_container {
							display: flex;
							padding-top: 10px;
							padding-right: 15px;

							.icon_img {
								display: inline-table;
								color: $color-brand;
								padding: 12px;
								border-radius: 50%;
								background-color: #f4f4f4;
							}
						}

						.details_container {
							.heading {
								text-transform: none;
								color: #637485;
								word-break: break-word;

								.orgprofile__info-edit-icon {
									opacity: 0;
									transition: opacity 200ms linear;
								}
							}

							.description {
								font-weight: 400;
								color: #4e6174;
								display: block;
								word-break: break-all;
							}
						}
					}

					.benefits_container:hover {
						.details_container {
							.heading {
								.orgprofile__info-edit-icon {
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: 600px) {
		.header {
			padding-bottom: 20px;
		}

		.body {
			display: block;
			.left-content {
				text-align: center;
			}

			.right-content {
				margin: 0;
			}
		}
	}
}

.file {
	position: relative;
	display: inline-block;
	cursor: pointer;
	height: 2.5rem;
}
.file input {
	min-width: 14rem;
	margin: 0;
	filter: alpha(opacity=0);
	opacity: 0;
}
.file-custom {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 5;
	height: 2.5rem;
	padding: .5rem 1rem;
	line-height: 1.5;
	color: #555;
	background-color: #D8DADB;
	border: .075rem solid #ddd;
	border-radius: .25rem;
	//box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	.mdi-icon {
		float: right;
	}
}
.file-custom:after {
	//content: "Choose file...";
}
.file-custom:before {
	position: absolute;
	top: -.075rem;
	right: -.075rem;
	bottom: -.075rem;
	z-index: 6;
	display: block;
	height: 2.5rem;
	padding: .5rem 1rem;

	line-height: 1.5;
	color: #555;
	background-color: #eee;
	border: .075rem solid #ddd;
	border-radius: 0 .25rem .25rem 0;
}

/* Focus */
.file input:focus ~ .file-custom {
	box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

.empty_profile-add{
	font-size: 21px;
	color: #7a8388;
	font-weight: 500;
}

.empty_profile_container{
	margin-top: 200px;
	//height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.addNewPhoto {
	color: $color-brand;
	cursor: pointer;
	display: flex;
	font-size: 14px;
	font-weight: 600;
	.add-text {
		margin: 3px 0 0 6px;
	}
}
