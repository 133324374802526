.own-team-btn {
    float: right;
    border: 3px solid #8F9AA0;
    color: #8F9AA0;
    width: 54%;
    padding: 10px 0px;
    height: 64%;
    font-size: small;
}
.switch-class {
    .MuiSwitch-thumb {
        background-color: #951B1E;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: #E2BFBF;
    }
}