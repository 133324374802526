.container__search-control {
    input {
        height: calc(1.5em + 0.75rem + 14px);
        position: relative;
        padding-left: 37px;
    }

    svg {
        position: absolute;
        top: 11px;
        left: 24px;
    }
}

.container__button {
    width: 10.25rem;
}

.assessments__skills-section {
    padding-bottom: 1em;
}

.assessments__skills-type {
    padding-bottom: 1rem;
}

.assessments__basic {
    border: none !important;
    padding: 0.5rem 1.25rem;
}

.assessments__basic-detail {
    height: 100%;
    display: flex;
    float: left;
    cursor: pointer;
    position: relative;
    // border-radius: 0;
    // border: none;
    transition: all 0.3s;
    min-height: 50px;
}

.assessments__basic-information {
    text-align: left;
    /* margin-left: 10px; */
    /* margin-top: 0; */
    margin: auto;
    margin-left: 0;
    margin-right: 0;
}


.assessments__basic-body {
    font-weight: 500;
    /* line-height: 18px; */
    font-size: 1rem;
    margin-top: 5px;
}

.assessments__end-collapse-btns {
    position: absolute;
    //margin: 0.6rem;

    @include directify($directions) {
        #{directed('right')}: 10px;
    }
}

.assessments__end-button {
    background-color: transparent;
    border: none;
}

.assessments__center-button {

}

.assessments__badge {
    // padding-right: 1.2em;
    // padding-left: 1.2em;
    //display: unset;
    padding: 0.6em 1.2em 0.4em 1.2em;
    font-size: 0.88em;
    font-weight: 600;
    letter-spacing: 1.6px;

    @include themify($themes) {
        background: $color-dark-gray-3;
        color: themed("colorDarkGrey");
    }
}

.assessment-overall-score {
    margin-top: 3px;
}


.assessments__detail-section {
    padding: 20px 30px 40px 30px;
}

.badge__text-count {
    display: flex;
    font-weight: 500;
    font-size: 1rem;
    padding: 2px 2.5px;
    justify-content: center;
    align-items: center;
    padding-left: 6px;
    background: transparent;
    float: left;
    margin-right: 5px;
    margin-top: 3px;

    @include themify($themes) {
        border: 1px solid $color-link-water;
        color: themed("colorDarkGrey");
    }

    span {
        margin-left: 8px;
        font-weight: 400;
        border-radius: 25px;
        font-size: 12px;

        @include themify($themes) {
            background: $color-link-water;
            color: themed("colorText");
        }

    }
}

.responses__numerics-card {
    @include themify($themes) {
        background: themed("colorAlabaster");
    }
}

.assessments-detail-container {
    display: grid;
}

.assign-assessment__menu-panel {
    display: flex;
}

.assign-assessment__filter-btn {
    background-color: #F5F5F5;
    color: #0092FF;
    padding: 6px;
    margin-bottom: 15px;
    border-radius: 5px;
    cursor: pointer;
    height: fit-content;
}

.assign-assessment__tabs-btn {
    margin-bottom: 0;
    justify-self: right;
}

.assign-assessment__table-container {
    margin-top: 30px;
}

.assign-assessment__filter-panel {
    background-color: #FAFAFA;
    border-radius: 16px;
    display: flex;
    padding: 30px 75px 30px 75px;
}

.assign-assessment__clear-filer {
    background: none;
    color: #0092FF !important;
    width: 36rem;
    padding: 10px 25px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: auto;
}

.assign-assessment-btn-apply-filter{

    width: 36rem !important;
    margin-bottom: auto !important;
}

.assign-assessment__menu-panel-sub {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
}

// scss 01-04-21
.nav-assessment-padding {
    padding-left: 3rem !important;
}

@media screen and (max-width: 480px){ 
    .nav-assessment-padding {
        padding-left: 1rem !important;
    }
    .assessment-remv-pad-mobile {
        padding: 0px;
    }
    .assessments__basic-information {
        width: 65%;
        text-align: left;
    }
    .assessments__basic-body {
        font-size:12.6px;
    }
    .assessments__skills-type h4 {
        font-size: 16px;
    }
    .assessment-overall-score {
        margin-left: 1px !important;
    }
    .skilltitle-width-mobile {
        max-width: 50%;
        min-width: 30%;
        margin-right: 5px;
    }
    .skill-type-view-accordian-btn-mobile {
        padding: 5px 5px !important;
        width: 90px;
        margin-bottom: 5px;
    }
    .assessmentDetailTitleMobile {
        font-size: 18px !important;
        margin-top: 0px !important;
    }
    .assessment-details-mobile {
        padding: 1.00rem 0.25rem 0.25rem 0.25rem !important;
    }
    .assessment-header-mobile {
        padding-left: 0.25rem ;
    }

    // for search bar
    .sidebar_search {
        display: flex;
        padding-left: 30px;
        background: #F5F6F7;
        align-items: center;
    }
    .sidebar_search input {
        border: none !important;
        background: #F5F6F7;
        height: 50px;
        // :focus, :active {
        //     border: none;
        //     background: red !important;
        // }
    }
    .sidebar_search input:focus {
        background: #F5F6F7;
    }
    .container__wrap {
        padding: 70px 0 0 !important;
    }
    .profile__card .nav-tabs li .nav-link {
        margin-right: 7px !important; 
        padding: 0px 0px 14px 0px !important;
    }
    .profile__card .nav-tabs {
        margin-top: 5px;
    }
    .profile__card .nav-item {
        font-size: 14px !important;
        margin-top: 10px;
    }
    .theme-light .tabs .nav-tabs {
        border-bottom: 2px solid #b5b5c6!important;
    }
    .padding-0-mobile {
        padding: 0px;
    }
    .tab-content-search-mobile {
        padding: 0px 15px;
    }
}
