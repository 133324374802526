// for circle css

.smart-skill-circle {

  text-align: center;
  border-radius: 50%;
  background-color: #4CBDEC;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //margin: auto;

  .score {
    color: white;
    font-size: 16px;
    font-weight: 700;
    flex: 0 1 100%;
    align-self: flex-end;
  }

  .level {
    color: black;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    flex: 0 1 100%;
  }

}

//for overview css

.selected-ss{
  border: 3px solid ;
  border-color: #3a414b;
}

.ss-high {
  height: 150px;
  width: 150px;
}

.ss-medium {
  height: 120px;
  width: 120px;
  margin-top: 15px;
}

.ss-low {
  height: 90px;
  width: 90px;
  margin-top: 30px;
}
.smart-skill-card {
  display: grid;
  justify-content: center;
}

.smart-skill-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 249px;
  background-color: #FAFAFA !important;
  border: 0px !important;
}

.smart-skill-text {
  width: 190px;
  height: 70px;
  float: bottom;
  margin: auto;
  //margin-top: 10px;
  word-break: break-word;
}

.smart-skill-circle-center {
  height: 150px;
  width: 150px;
  justify-content: center;
  align-content: center;
  display: flex;
  margin: auto;
  margin-bottom: 20px;
}

//For detailed css

.container-smarts {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  text-align: center;
}

.div-container-smart-skill {
  width: 50%;
  height: 50%;
  float: left;
  overflow: hidden;
}

.ss-detail-circle-center {
  justify-content: center;
  align-content: center;
  display: flex;
  margin: auto;
  //width: 90px;
  height: 90px;
  width: 100%;
}

.ss-detailed-high {
  height: 90px;
  width: 90px;
  margin: 0;
}

.ss-detailed-medium {
  height: 80px;
  width: 80px;
  margin: 0;
}

.ss-detailed-low {
  height: 70px;
  width: 70px;
  margin: 0;
}


.smart-skill-text-detailed {
  //width: 190px;
  margin: auto;
  //margin-top: 10px;
  word-break: break-word;
  font-size: 8px;
  font-weight: 600;
}

// for ss description
