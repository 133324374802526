.team_table_team_number{
  float: left;
  color: #525A60;
  padding-top: 13px;
}.team_table_team_photo{
   float: left;
   width:50px;
   height:50px;
   padding: 0px 12px 0px 12px;
 }
.team_table_team_name{
  font-weight: bold;
  text-transform: none;
  font-size: 12px; // old 12 px
  color: #2E485C;
  padding-top: 5px;
}
.team_table_team_member{
  text-transform: none;
  font-size: 12px;
  color: #8F9AA0;
}