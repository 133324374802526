.accountGroupIconParentDiv {
  background-color: #bdbdbd;
  border-radius: 10%;
  width: 100%;
  height: 60px;
  object-fit: cover;
  text-align: center;
  align-content: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
.accountGroupIcon {
  color: #F5F5F5;
  height: 35px;
  width: 35px;
}
.winner-tabs {
  border-bottom: none !important;
  .nav-link.active {
    text-align: center;
    padding: 7px !important;
    color: white !important;
    font-weight: normal !important;
    width: 95%;
    border-radius: 40px !important;
    border-bottom: none !important;
    background: #525A60 !important;
  }
  .nav-link {
    text-align: center;
    padding: 7px !important;
    color: #525A60 !important;
    font-weight: normal !important;
    width: 95%;
    margin:5px;
    border-radius: 40px !important;
    border-bottom: none !important;
    background: #F5F5F5 !important;
  }
}

.public_winners_main{
  .public_winners_congo {
    border-bottom: 1px solid #E8E8E8;
    .public_winners_congo_title {
      font-size: 16px;
      color: $color-accent;
      font-weight: 600;

    }

    .public_winners_congo_desc {
      color: $color-accent;
      width: 675px;
      height: 150px;
      font-size: 14px;
      padding-top: 15px;
      text-align: justify;
    }
  }

  .select_winners_box{
   border-bottom: 1px solid #E8E8E8 ;
    label{
      font-size: 16px;
      color: $color-accent;
      font-weight: 600;

    }
  }

  .public_winners_box{
    padding-top: 15px;

    .public_winners_left_box {
      width: 25%;
      label {
        font-size: 16px;
        color: $color-accent;
        font-weight: 600;
      }
      .winners_page_note {
        width: 175px;
        height: auto;
        text-align: justify;
        span {
          color: $color-accent;
          font-size: 14px;
        }
      }
    }

    .public_winners_middle_box_main_border{
      border-bottom: 1px solid #E8E8E8;
    }
    .public_winners_middle_box_main {
      .public_winners_middle_box {
        width: 100%;

        .public_winners_prize {
          display: flex;
          align-items: center;

          .public_winners_prize_img {
            width: 72px;
            height: 72px;
            min-width: 72px;
            border-radius: 100%;
            background: #f4f4f4;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 43px;

            img {
              width: auto;
            }
          }

          .public_winners_prize_detail {
            label {
              width: 100%;
              font-size: 16px;
              font-weight: bold;
              color: $color-dark-gray;
              margin-bottom: 0px;
            }

            span {
              color: $color-dark-gray;
              font-size: 12px;
            }
          }
        }
      }
    }

    .public_winners_last_box {
      width: 100%;
      .public_winners_prize_team{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .public_winners_prize_team_profile {
          width: 60px;
          min-width: 60px;
          margin-right: 43px;
          img{
            border-radius: 10%;
            width: 100%;
            height: 60px;
            object-fit: cover;
          }
        }
        .public_winners_prize_team_detail {
          label {
            width: 100%;
            font-size: 16px;
            font-weight: bold;
            color: $color-dark-gray;
            margin-bottom: 0px;
          }
          span {
            color: $color-dark-gray-2;
            font-size: 12px;
          }
        }
      }
    }


  }


  .public_winners_gallery{
    border-top: 1px solid #e8e8e8;
    padding-top: 15px;
    label {
      font-size: 16px;
      color: $color-accent;
      font-weight: 600;
    }
  }
}
.public_winners_table{
  background-color: #FAFAFA;
  padding: 15px;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  .public_winners_table_prize{
    padding: 10px 0px 10px 30px;
    display: flex;
    align-items: center;
    .public_winners_table_prize_img {
      width: 50px;
      height: 50px;
      min-width: 50px;
      border-radius: 100%;
      background: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 43px;

      img {
        width: auto;
      }
    }
    .public_winners_table_prize_detail {
      label {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        color: $color-dark-gray;
        margin-bottom: 0px;
      }

      span {
        color: $color-dark-gray;
        font-size: 12px;
      }
    }

  }

}
.public_winners_team_profile_detail{
  display: flex;
  .public_winners_team_profile_photo{
    width: 150px;
    height: 150px;
    min-width: 150px;
    margin-right: 43px;
    img {
      border-radius: 10%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .public_winners_team_name{
    font-weight: bold;
    color: $color-accent;
    text-transform: none;
  }
  .public_winners_team_formed{
    font-weight: bold;
    color: #2E485C;
    text-transform: none;
    font-size: 12px;
  }
  .public_winners_team_formed_date{
    color:#2E485C;
    margin-left: 10px;
    font-size: 12px;
  }

  .public_winners_team_desc{
    color: #2E485C;
    font-size: 12px;
  }


}
.public_winners_team_members{
  padding-top: 35px;
  .public_winners_team_members_title {
    label {
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      color: $color-dark-gray;
      margin-bottom: 0px;
    }
  }


  .public_winners_team_member_main_bottom{
    border-bottom: 1px solid #E8E8E8;
    padding: 10px 0px 10px 0px;
  }
  .public_winners_team_member_main {
    padding: 10px 0px 10px 0px;
    .public_winners_team_member_num {
      display: flex;
      padding-top: 13px;
      font-size: 12px;
      color: $color-dark-gray;
    }

    .public_winners_team_member_profilephoto {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .public_winners_team_member_name {
      margin-top: 12px;
      font-size: 12px;
      font-weight: bold;
      text-transform: none;
      color: $color-accent;

    }
  }
}
.public_winner_team_submitted_main{
  .public_winner_team_submitted{
    display: flex;
    .public_winner_team_submitted_title{
      label {
        font-size: 14px;
        font-weight: bold;
        color: $color-dark-gray;
        margin-bottom: 0px;
      }
    }

    .public_winner_team_submitted_button {
      padding-left: 315px;
    }
  }

  .public_winner_team_submitted_file{
    display: flex;
    padding: 20px 20px 10px 20px;
    border-bottom: 1px solid #D8DADB;
    .public_winner_team_submitted_file_icon{
      color: #A8ACAF;
    }
    .public_winner_team_submitted_file_name{
      span{
        font-weight: bold;
        color: #2E485C;
        text-transform: none;
        font-size: 12px;
        padding-left: 20px;
      }
    }

  }

  .public_winners_team_members_text_submission {
    label {
      padding-top: 15px;
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      color: $color-dark-gray;
    }
    .public_winners_team_members_text_submission_desc{
      color: #2E485C;
      font-size: 12px;
    }
  }

}



.public_your_Winner_main{
  .public_your_winners_prize{
    display: flex;
    align-items: center;
    .public_your_winners_prize_img {
      width: 60px;
      height: 60px;
      min-width: 60px;
      border-radius: 100%;
      background: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 43px;

      .public_your_winners_prize_num{
        font-size: xx-large;
        color: #2E485C;
      }
    }

    .public_your_winners_prize_detail {
      label {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        color: $color-dark-gray;
        margin-bottom: 0px;
      }

      span {
        color: $color-dark-gray;
        font-size: 12px;
      }
    }
  }

  .public_your_winners_prize_team_active{
    background-color: #F5F5F5;
  }

  .public_your_winners_prize_team_main {
    padding-left: 100px;



    .public_your_winners_prize_team {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .public_your_winners_prize_team_profile {
        width: 60px;
        height: 60px;
        min-width: 60px;
        margin-right: 43px;

        img {
          border-radius: 10%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .public_your_winners_prize_team_detail {
        label {
          width: 100%;
          font-size: 16px;
          font-weight: bold;
          color: $color-dark-gray;
          margin-bottom: 0px;
        }

        span {
          color: $color-dark-gray-2;
          font-size: 12px;
        }
      }
    }
  }


  .public_your_winner_shortlisted_team_Members{
    label {
      width: 100%;
      font-size: 14px;
      font-weight: bold;
     // color: $color-dark-gray;
      margin-bottom: 0px;
    }
  }


  .public_your_winners_team_member_main{
    border-bottom: 1px solid #D8DADB;
    padding: 10px 0px 0px 0px;
  }
  .public_your_winners_team_member_num{
    display: flex;
    padding-top: 13px;
    font-size: 12px;
    color: $color-dark-gray;
  }
  .public_your_winners_team_member_profilephoto {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  .public_your_winners_team_member_detail {
    label {
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      color: $color-dark-gray;
      margin-bottom: 0px;
    }

    span {
      color: $color-dark-gray-2;
      font-size: 12px;
    }
  }


}


.public_your_winners_team_member_profilephoto_account{
  background-color: #bdbdbd;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
  align-content: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
.public_your_winners_team_member_profilephoto_accountIcon {
  color: #F5F5F5;

}

