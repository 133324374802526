.organization-details {
	width: 100%;
	margin: 0;
	padding: 0;

	.header {
		display: flex;
		align-items: center;
		padding-bottom: 50px;
		width: 100%;

		.back-arrow {
			font-size: x-large;
		}

		h4 {
			font-weight: 800;
			margin-left: 10px;
		}
	}

	.body {
		display: flex;

		.left-content {
			padding: 0 30px;

			img {
				width: 100px;
				height: 100px;
				border: none;
				border-radius: 12%;
			}
		}

		.right-content {
			margin-right: 30px;
			width: 100%;

			.org-details {
				h4 {
					font-weight: 700;
					padding-bottom: 10px;
				}

				.org-descrption {
					padding-bottom: 10px;
					text-align: justify;
				}
			}

			.office-images {
				margin-top: 30px;
				display: flex;
				flex-flow: column;

				h4 {
					font-weight: 700;
					padding-bottom: 10px;
				}
			}

			.benefits {
				margin-top: 30px;
				width: 100%;

				h4 {
					font-weight: 700;
				}

				.row {
					background-color: #fcfcfc;
					margin-top: 20px;
					border-radius: 10px;
					padding: 30px 0;
				}
			}

			.no_data {
				margin: auto;
				font-size: 15px;
			}
		}
	}

	@media only screen and (max-width: 1400px) {
		.body {
			.right-content {
				width: 100%;
			}
		}
	}

	@media only screen and (max-width: 1056px) {
		.body {
			.right-content {
				width: 100%;
			}
		}
	}

	@media only screen and (max-width: 600px) {
		.header {
			padding-bottom: 20px;
		}

		.body {
			display: block;
			.left-content {
				text-align: center;
			}

			.right-content {
				margin: 0;
				width: 100%;
			}
		}
	}
}
.org-work-benefit__main {
	display: flex;
	padding: 15px;
}

.org-work-benefit__icon-outer {
	display: flex;
	//align-items: center;
	padding-top: 10px;
	padding-right: 15px;
}

.work-benefit__icon {
	display: inline-table;
	color: #0092FF;
	padding: 12px;
	border-radius: 50%;
	background-color: #DEF1FF;
}

.org-work-benefit__heading {
	text-transform: none;
	word-break: break-word;
}

.org-work-benefit__description {
	font-weight: 400;
	color: #4E6174;
	display: block;
	word-break: break-all;
}
@media screen and (max-width: 480px) {
	.work-benefit__icon {
		display: inline-table;
		color: #951B1E;
		padding: 12px;
		border-radius: 50%;
		background-color: #F5F6F7;
	}
	.org-work-benefit__icon-outer {
		padding-top: 0px;
	}
}