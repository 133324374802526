.email-tag-box {
  padding: 5px;
  border: 1px solid #D8DADB;
  margin-right: 8px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.input-icons .mdi-icon {
  color: #951B1E;
  //position: relative;
  //color: #0092FF;
  //float: right;
  //top: -31px;
  //right: 10px;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  min-width: 40px;
}

.input-field {
  width: 100%;
  padding: 10px;
}

.panel__refresh:before {
  background: white;
  opacity: 0.4;
}



// ......................................................
select.drp-nav {
  max-width: 220px;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border-color: transparent;
  background-color: #fafafa;
  padding-left: 15px;
  padding-right: 15px;
}
a#dropdownMenuLink {
  width: 100%;
  padding: 21px 10px;
  margin: 0;
  background-color: red;
  border-radius: 0;
}
.drp-nav .btn.btn-primary {
  background-color: #951B1E;
  border-color: #951B1E;
  margin: 0;
  height: 100%;
  border-radius: 0;
  background-color: #fafafa;
  color: #000;
  border-color: transparent;
}
.nav-dropdown-box-inr{
  display: flex;
  flex-direction: row;
  padding: 16px 14px 16px 21px;
  align-items: center;
}
.nav-dropdown-box-inr:hover {
  cursor:pointer;
  background-color: #FAFAFA;
}
.nav-dropdown-box-inr {
  .rotateIcon {
    transform: rotate(180deg);
    transition: all 0.25s ease-in-out;
  }
}
.nav-dropdown-box-inr .dropdown_image_inr{ width:34px; height: 34px; border-radius: 100%; object-fit: cover}
.nav-dropdown-box-content{ display: flex; flex-direction: column; padding-left:10px; cursor:pointer;}
.nav-dropdown-box-content label{
  text-align: left;
  letter-spacing: 0.42px;
  color: #1D2B36;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 3px;
  cursor:pointer;
}
.nav-dropdown-box-content label span{
  letter-spacing: 0px;
  color: #525A60;
  opacity: 1;
  font-size: 12px;
  font-weight: normal;
  display: inline-block;
  width: 100%;
  cursor:pointer;
}
.nav-dropdown-box-content p{
  text-align: left;
  letter-spacing: 0px;
  color: #CC3030;
  opacity: 1;
  font-size: 10px;
  margin-top: 0px;
  font-weight: bold;
  cursor:pointer;
}
.nav-dropdown-box{
  background: #ffffff;
  //padding:5px 15px;
  position: relative;
  height: 70px;
  min-width: 300px;
  width: 300px;
  margin-right: 15px;
}
.nav-dropdown-box-show {
  margin-right: 15px;
  background: #ffffff;
  position: relative;
  top: 0;
  //left: 14.4%;
  z-index: 1044;
  width: 300px;
  height: 70px;
}
.showDisplacementDiv{
  //width:22.1%;
}
.drop_box{
  position: absolute;
  left: 0;
  width: 100%;
  top:100%;
  padding:0 0px;
  background: #fff;
}
.drop_box:hover {
  cursor:pointer;
}
//.topbar{ height: 102px;}
//.container__wrap{padding-top: 120px;}
.drop_box > div { border-top: 1px solid #F5F5F5; padding-top: 3%}
.nav-dropdown-box > .nav-dropdown-box-inr{
  //border-bottom: 1px solid #FAFAFA;
  padding: 16px 14px 16px 21px; }

.light_green{ color: #21CFBA !important; }



.first-box{ position: relative; }
.first-box:hover {
  background-color: #ffffff;
}
//.first-box:after,
//.first-box:before {
//	content: "\005E";
//	//border-style: solid;
//	//border-width: 10px;
//	width: 0;
//	height: 0;
//	position: absolute;
//	top: 0;
//	right: 12px;
//	z-index: 11;
//	bottom: 0;
//	margin: auto;
//}
//.first-box:before {
//  border-color: #8F9AA0 transparent transparent;
//}
//
//.first-box:after {
//  top: -7px;
//  border-color: #fff transparent transparent;
//}