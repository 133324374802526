.course_details__completed {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left_header {
      display: flex;

      .back_arrow {
        margin-top: 4px;
      }

      .left_heading {
        padding-left: 10px;

        ol {
          background: none !important;
          padding: 0;
          margin: 0;
          padding-top: 10px;
          font-size: small;

          .breadcrumb-item {
            color: #b8b8c3 !important;
          }

          .breadcrumb-item::before {
            color: #b8b8c3 !important;
          }
        }

        h3 {
          font-weight: 700;
        }
      }
    }

    .right_header {
      background-color: #1493ff;
      color: white;
      padding: 8px 40px;
      font-size: smaller;
      border: none;
      border-radius: 3px;
      cursor: pointer;
    }
  }

  .body {
    display: flex;
    margin: 0 20px;
    margin-right: 170px;
    margin-top: 40px;

    .image {
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding-right: 40px;

      img {
        height: 100px;
        width: 100px;
      }

      .status {
        margin-top: 20px;
        background-color: #f5f5f5;
        padding: 10px 20px;
        border-radius: 20px;
        font-size: smaller;
      }
    }

    .content {
      h4 {
        font-weight: 500;
        padding-bottom: 20px;
      }

      .content_data {
        padding-bottom: 20px;
        color: #72797e;
        text-align: justify;
      }

      .skills {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .heading {
          font-weight: 600;
          padding-right: 8px;
        }

        .skill {
          margin-right: 5px;
          background-color: #f5f5f5;
          color: #72797e;
          padding: 5px 15px;
          border-radius: 20px;
        }
      }

      .continue__learning {
        width: fit-content;
        margin-top: 5px;
        background-color: #1493ff;
        color: white;
        padding: 8px 20px;
        font-size: smaller;
        border: none;
        border-radius: 3px;
        cursor: pointer;
      }
      .company_logo{
        max-width: 163px;
      }
      .image_logo{
        display: flex;
        justify-content: center;
        vertical-align: middle;
        height: 135px;
        align-items: center;
      }
      .logos {
        margin-top: 40px;
      }
      .logos p {
        font-size: 15px;
      }
    }
  }

  .schedule {
    margin-top: 40px;

    h4 {
      font-weight: 500;
      padding-bottom: 20px;
    }

    .icon {
      height: 32px;
      width: 32px;
    }

    .time {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;

      .heading {
        font-weight: 200;
        color: #72797e;
      }

      .date {
        display: flex;
        width: 20%;
        margin-right: 20px;
      }

      .location,
      .provider,
      .mode {
        display: flex;
        width: 16%;
        margin-right: 20px;
      }

      .mode {
        width: 32%;
        display: flex;
        //align-items: center;
        //justify-content: flex-end;
        .info {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          height: 100%;

          .sub-info {
            background-color: #f2f2f2;
            font-size: smaller;
            border: none;
            border-radius: 50px;
            width: fit-content;
            padding: 2px 15px;
          }
        }

        .price {
          //margin-left: 10px;
          //display: flex;
          align-items: center;
          text-align: right;
          //padding-left
          margin-left: auto;

        }
      }

      .purchase {
        //display: flex;
        //align-items: center;
        margin-left: 15px;
        .purchase-button {
          width: fit-content;
          font-size: smaller;
          margin: auto;
          border: none;
          border-radius: 5px;
          padding: 10px 30px;
          color: white;
          background-color: #13ad13;
          cursor: pointer;
        }
      }
    }

    .no_data_message {
      display: flex;
      justify-content: center;
      font-size: large;
      margin-top: 30px;

      p {
        padding: 0 5px;
        color: #1d91fa;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .course_details__completed {
    .body {
      flex-flow: column;
      margin: 0;

      .image {
        padding-right: 0;
        align-items: center;
      }

      .content {
        h4 {
          text-align: center;
          padding-top: 40px;
        }

        .skills {
          justify-content: space-evenly;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .course_details__completed {
    .header {
      flex-flow: column;

      .right_header {
        margin-top: 30px;
      }
    }

    .body {
      .content {
        .skills {
          flex-flow: column;

          .heading {
            padding: 0;
            margin-bottom: 15px;
          }

          .skill {
            margin-bottom: 5px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
.course-detail-icon {
  color:#902027 !important;
}

// for mobile responsive css
@media screen and (max-width: 480px){ 
  .inquire-icon-mobile {
    width: 100%;
  }
  .course-detail-icon {
    color:#902027 !important;
  }
  .course_details__completed .header .left_header .left_heading h3 {
    font-size: 18px;
  }
  .course_details__completed .header .left_header .left_heading ol {
    padding-top: 0px;
  }
  .course_details__completed .schedule .icon {
    height: 38px;
    width: 24px;
    background: #f2f2f2;
    border-radius: 50%;
    padding: 6px 0px 0px 8px;
    margin-right: 10px;
  }
  .course_details__completed
  {
    .header {
      align-items: initial;
    }
    .schedule .time {
      display: block;
      border: 1px solid #8080806b;
      border-radius: 5px;
      padding: 10px; 
      font-weight: 500;
      font-size: 16px;
    }
    .schedule .time .date {
      width: 100%;
    }
    .schedule .time {
      .heading {
        font-weight: 400;
      }
      .purchase .purchase-button {
        border-radius: 3px;
      }
    }
    .schedule .time .location, 
    .schedule .time .provider, 
    .schedule .time .mode {
      margin-top: 2%;
      width: 100%;
    }
    .schedule .time .mode {
      width: 100%;
      display: block;
      .info,.price,.purchase {
        margin-top: 2%;
        margin-left: 13% !important;
        text-align: left;
        align-content: initial; 
        align-items: initial;
      }
    }
    .body {
      .image {
        align-items: initial;
      }
      .content {
        h4 {
          text-align: left;
          padding: 15px 0px 12px 0px !important;
        }
        .content_data {
          text-align: left;
        }
        .skills {
          align-items: initial;
          .skill {
            width: fit-content;
          }
        }
      }
      
    }
  }
 
}