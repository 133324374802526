.competition_qanda_main {
  .competition_qa_text {
    font-size: 12px;
    color: #2E485C;
    font-weight: bold;
    text-transform: none;
    margin: 0px 0px 15px;
  }

  .competition_qa_company_text{
    font-size: 12px;
    color: #8E8E8E;
    font-weight: bold;
    text-transform: none;
    margin: 0px 0px 15px;
  }


  .competition_qa_question {
    padding-right: 150px;

    .competition_qa_user_question_title {
      font-weight: bold;
      text-transform: none;
      color: #2E485C;
    }

    .competition_qa_photo {
      padding-top: 25px;
      align-items: center;

      .competition_qa_photo_profile {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        float: left;
      }

      .competition_qa_user_pd {
        padding: 0px 0px 0px 45px;

        .competition_qa_user_name {
          font-size: 12px;
          color: #2E485C;
          font-weight: bold;
          text-transform: none;
        }

        .competition_qa_user_date {
          font-size: 12px;
          color: #8F9AA0;
        }

      }


    }

  }

  .competition_qa_user_question {
    margin-top: 25px;
    font-size: 12px;
  }

  .competition_qa_company_answer{
    font-size: 12px;
  }

  .competition_qa_company_not_answer{
    font-weight: bold;
    color: #2E485C;
    text-transform: none;
  }

  .competition_qa_company_not_answer_text{
    font-size: 12px;
  }

  .competition_qa_icon{
    margin: 0px 0px 15px;

    .competition_qa_like_follow {
      color: #8e8e8e;
      margin-top: 18px;

      span {
        margin-right: 10px;
      }

      .card-icon {

        &.selected {
          color: $color-brand
        }
      }

    }
  }

}
