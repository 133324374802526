.after-join-team-overview {
    color: #2E485C;
    .upload-sub-tabs {

        .nav-link.active {
            text-align: center;
            padding: 7px !important;
            color: white !important;
            font-weight: normal !important;
            width: 100%;
            border-radius: 40px !important;
            border-bottom: none !important;
            background: #525A60 !important;
        }
        .nav-link {
            text-align: center;
            padding: 7px !important;
            color: #525A60 !important;
            font-weight: normal !important;
            width: 100%;
            border-radius: 40px !important;
            border-bottom: none !important;
            background: #F5F5F5 !important;
        }
        .link-pad {
            padding: 7px !important;
        }
        .nav-item {
            margin-right: 10px;
        }
    }
    .nav-tabs {
        border-bottom: none !important;
    }
    .nav-item {
        font-size: 12px !important;
    }
    .nav-link.active {
        border-bottom: none !important;
    }
    .orgprofile__avatar-img {
        border-radius: 7% !important;
        width: 150px !important;
        height: 150px !important;
        object-fit:cover !important;
    }
    .orgprofile__avatar-edit-icon {
        width: 24px;
        height: 24px;
        margin-top: 5px;
        cursor: pointer;
    }
    .orgprofile__avatar-desc-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f8fd;
        color: #2e485c;
        margin-left: 5px;
    }
    .orgprofile__avatar-no {
        margin-right: 0;
        margin-left: 0;
        justify-content: initial;
    }
    .team-name-title {
        font-size: 30px;
        font-weight: 700;
        float: left;
    }
    .team-formed {
        font-size: 14px;
        span {
            font-weight: 700;
        }
    }
    .team-description-title {
        font-size: 14px;
        font-weight: 700;
    }
    .team-member-list-div {
        background-color: #F4F4F4;
        .team-member-title {
            color:#525A60;
            font-weight: 600;
            font-size: 12px;
            padding:20px
        }
    }
    .request-sent-member-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 0px;
        display: flex;
        border-bottom: 1px solid #e5e4e4;

        .accept-btn {
            background-color: #951B1E;
            border-color: #951B1E;
            margin-right: 15px;
            padding: 4px 0px;
            height: 50%;
            font-size: small;
            width: 45%;
            border-width: medium;
        }
        .decline-btn {
            float: right;
            border-width: medium !important;
            color: #8F9AA0 !important;
            padding: 4px 0px;
            height: 50%;
            font-size: small;
            width: 45%;
            border-color: #e7e2e2;
        }
        .profile-pic {
            height: 65px !important;
            width: 65px !important;
            border-radius: 17%;
        }
        .person-name {
            font-weight: 600;
            font-size: 14px;
        }
        .file-name {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-weight: 600;
            font-size: 14px;
            width: 70%;
            overflow-y: hidden;
            height: 22px;
        }
        .file-div {
            display: flex;
        }
        .grey-small-text {
            color: #525A608c;
            font-weight: 400;
            font-size: 12px;
        }
        .grey-text-joined-date {
            color: #525A608c;
            font-weight: 600;
            font-size: 13px;
            padding-left: 20px;
        }
        .member-detail-isAdmin {
            margin-top: 0px;
            text-align: center;
            border: 1px solid #951B1E;
            background-color: #F8AAAC;
            color: #951B1E;
            font-weight: bold;
            border-radius: 6px;
            padding: 3px 9px;
            width: 47%;
            height: 2%;
            font-size: 8px;
        }
    }
    .bottom-btn {
        width: 13%;
        padding: 7px 0px;
        height: 60%;
        font-size: small;
    }
    .botton-btn-grey {
        border-width: medium !important;
        color: #8F9AA0 !important;
    }
    .progress-team-submission-upload {
        float: right;
        width:50%;
    }
}
.delete-file-icon {
    color: #A8ACAF;
}
.delete-file-icon:hover {
    color: maroon;
    cursor: pointer;
}
.profile-pic-already-member {
    height: 65px !important;
    width: 65px !important;
    border-radius: 50%;
    object-fit:cover !important;
}
.profile-pic-after-join-modal {
    height: 65px !important;
    width: 65px !important;
    border-radius: 17%;
}
.person-name-after-join-modal {
    font-weight: 600;
    font-size: 14px;
}
.grey-small-text-after-join-modal {
    color: #525A608c;
    font-weight: 400;
    font-size: 12px;
}
.modal-team-show {
    align-items: center;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}
.uploaded-files-tab {
    padding: 0px 2px;
    min-height: 30rem;
    .text-submission-main-div {
        border: 2px solid #F4F4F4;
    }
    .text-submission-right-div {
        border-left: 2px solid #F4F4F4;
    }
    .uploaded-on {
        font-weight: 600;
        font-size: 13px;
        padding-left: 20px;
    }
    .uploaded-date-out {
        font-weight: 600;
        font-size: 15px;
        padding-left: 20px;
    }
    .uploaded-time-out {
        color: #525A608c;
        font-weight: 400;
        font-size: 12px;
        padding-left: 20px;
    }
    .red_btn {
        background: #951b1e;
        border-radius: 4px;
        border: 0px;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        max-width: 208px;
        width: 100%;
        height: 44px;
        margin-top: 30px;
     }
     .edit_content_box {
        textarea {
           font-size: 14px;
           color: $color-accent;
           margin-bottom: 20px;
        }
     }
     .edit_save_btn {
        margin-top: 0px;
        white-space: nowrap;
        font-size: 14px;
        width: auto !important;
        max-width: unset;
        min-width: 117px;
        padding: 0;
        height: 44px;
        margin: 0;
        font-size: small;
        min-width: 98px;
        height: 38px;
     }
     .desc-div {
         margin-bottom: 6rem;
     }
    .competition_right_box_description {
        min-width: 30rem;
    }
}
