//@import "../settings/variable";

.dashboard-individual {
	.learning__gap,
	.skills__gap {
		border: 1px solid #dddddd;
		border-radius: 9px;
	}

	.skills__gap {
		height: 100%;
	}

	.learning__gap-title,
	.skills__gap-title {
		margin-top: 20px;
		margin-left: 20px;
		font-weight: bold;
		font-size: large;
		vertical-align: middle;
	}

	.skills__gap-row {
		display: flex;
		align-items: center;
		justify-content: center;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 20px;
		}
	}

	.skills__gap-chart {
		position: relative;
		text-align: center;
	}

	.skills__gap-label {
		position: absolute;
		font-size: 24px;
		line-height: 20px;
		top: calc(50% - 10px);
		width: 100%;
		margin: 0;
		animation: label 1.5s ease-in;
		color: #dddddd;
		font-family: 'Poppins', sans-serif;
	}

	.skills__gap-domain {
		font-weight: 600;
		color: #2e485c;
		opacity: 1;
	}

	.skills__gap-level {
		margin-top: -2px;
		color: $color-additional;
	}

	.learning__gap-module {
		padding: 60px 30px;
	}

	.learning__gap-icon {
		display: inline-block;
	}

	.learning-progress {
		display: inline-block;
		text-align: center;
	}

	.learning-progress .percentage {
		color: #13ad13;
		font-size: 27px;
		font-weight: 300;
	}

	.learning-progress .text {
		margin: 0;
	}

	.learning__gap-module .instructor {
		display: inline-block;
		margin-left: 10px;
	}

	.vertical-line {
		height: 52px;
		width: 2px;
		margin: 0 13px;
		background-color: #d8dadb;
		display: inline-block;
		position: relative;
	}

	.upcoming-course {
		display: inline-block;
	}

	.upcoming-course .text {
		color: #9db0b9;
		font-weight: 600;
		text-transform: uppercase;
	}

	.upcoming-course .course-name {
		color: #2e485c;
		font-size: initial;
		font-weight: 600;
		font-family: Poppins, sans-serif;
		text-transform: capitalize;
	}

	@media (max-width: 360px) {
		.upcoming-course {
			margin-top: 22px;
			margin-left: 9px;
		}
		.skills__gap-row {
			padding-left: 62px;
		}
	}
}
