.padding-around {
  padding: 20px 13px;
}
.hide-overflow {
  height: inherit;
  width: inherit;
}
.CSV-button {
  float: right;
  margin-top: 5px;
}
.CSV-button:hover {
  background-color: transparent;
}
.fontHead {
  font-size: small;
}

.no_data_found{
  text-align: center;
  align-content: center;
  justify-content: center;
  display: flex;
  align-items: center;
}