.karisma-header-img {
    background-image: url('https://images.unsplash.com/photo-1569849093193-4f1a293992a1');
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 390px;
    background-size: 100%;
    display: flex;
    align-items: flex-start;
    padding-left: 29px;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 480px) {
        padding-left: 0;
        padding-left: 28px;
     }
}

.karisma-main-div {
    position: relative;
 }

 .karisma-box {
   width: 400px;
   height: 322px;
   left: -42px;
   top: 40px;
   transform: skew(35deg);
   background-color: #44474e;
    @media (max-width: 480px) {
       max-width: 320px;
       max-height: 105px;
    }
    position: relative;
 }

 .karisma-desc {
   width: 475px;
   letter-spacing: 0.34px;
    padding: 0 60px 20px;
    transform: skewX(-35deg);
    transform-origin: top;
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .karisma-image {   
    width: 100px;
       height: 52px;
       img {
          width: 100%;
          height: 52px;
       }
 }
 .karisma-text {
    width: 88%;
    margin: 6px 8rem;
 }

 .karisma-heading {
    color: #3facc4;
    font-weight: bolder;
    font-size: 35px;
    font-style: italic;
 }
 .karisma-heading-requirement {
   color: #3facc4 ;
   font-weight: bolder;
   font-size: 18px;
   text-transform: uppercase;
   font-style: italic;
}

 .karisma-paragraph {
   color: #656565   !important;
   font-size: 18px;
}

.karisma-footer-box {
   width: 324px;
   height: 170px;
   transform: skew(35deg);
    @media (max-width: 480px) {
       max-width: 320px;
       max-height: 105px;
    }
    position: relative;
 }

 .karisma-logo {
    width: 37%;
    /* height: 45px; */
    background-image: url(https://images.unsplash.com/photo-1569849093193-4f1a293992a1);
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 200px;
    margin-left: -35px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
 }

 .karisma-logo-text {
   width: 310px;
   font-weight: bolder;
   margin-top: 20px;
   line-height: 1.2;
   font-size: 36px;
    margin-left: 100px;
    transform: skewX(-35deg);
	transform-origin: top;
	display: flex;
	justify-content: center;
	align-items: center;
   
 }
 .karisma-logo-span {
   font-weight: bolder;
   color: #3facc4;
   margin-top: 10px;
   transform: skewX(-35deg);
	transform-origin: top;
	display: flex;
   margin-left: 51px;
   text-transform: uppercase;
	justify-content: center;
	align-items: center;
}

.karisma__basic-icon {
   width: 30px;
   height: 28px;
   border-radius: 50% !important;
   border: none;
   border-radius: 3px 0 0 3px;
   background-color: black;
   color: white;
   margin: auto;
   padding: 1px;
 }

 .karisma-icon-font {
   color: #dddee0   !important;
    font-size: 18px;
    padding-top: 20px;
    padding-left: 15px;
    font-family: ui-sans-serif;
    flex-grow: 1;
 }

 .karisma-info-font {
   color: #7c7979 !important;
    font-size: 18px;
    padding-top: 20px;
    padding-left: 15px;
    font-family: ui-sans-serif;
    flex-grow: 1;
 }

 .karisma-logo-footer-text {
   color: #3facc4   !important;
   font-weight: bolder;
   font-size: 20px;
}
.karisma-logo-footer-span {
  font-weight: bolder;
  color: #056c6cbf;
  margin-top: 10px;
}

.karisma-logo-footer {
   width: 137%;
   background-repeat: no-repeat;
   min-height: 130px;
   display: flex;
   position: absolute;
   bottom: 0;
   align-items: flex-start;
   flex-direction: column;
}

.karisma-footer__basic-icon {
   width: 30px;
   height: 28px;
   border-radius: 50% !important;
   border: none;
   border-radius: 3px 0 0 3px;
   background-color: white;
   color: black;
   margin: auto;
   padding: 1px;
 }

 .karisma-star-image {
   width: 100px;
   height: 35px;
   img {
      width: 92%;
      height: 90px;
   }
}

.triangle-bottomleft {
   width: 0;
    height: 0;
    border-bottom: 227px solid #3facc4;
    border-right: 123px solid transparent;
    transform: skewX(-35deg);
    position: absolute;
    top: 95px;
    left: -45px;
 }

 .karisma-trapezoid {
   min-height: 201px;
   height: 26%;
   display: flex;
   position: absolute;
   bottom: 0;
   align-items: flex-start;
   flex-direction: column;
   width: 370px;
   border-bottom: 180px solid #44474e;
   border-left: 0px solid transparent;
   border-right: 108px solid transparent;
   padding: 40px 8px 0 0;
  }

  .karisma-border {
     border-right: 3px solid #0d0e0e
     ;
  }

  .karisma-trapezoid-replicate {
   min-height: 201px;
   height: 26%;
   display: flex;
   position: absolute;
   bottom: 0;
   align-items: flex-start;
   flex-direction: column;
   width: 374px;
   border-bottom: 186px solid #3facc4;
   border-left: 0px solid transparent;
   border-right: 108px solid transparent;
   padding: 25px 8px 0 0;
  }

  .karisma-footer-program{
   color: #3facc4   !important;
   font-weight: bolder;
  }
