.assessment_survey {
  width: 100%;
  height: 92vh;
  border: none;
}

.assessment_survey {
  //background-image: url('./loading.gif');
  //background-position: center;
  //background-repeat: no-repeat;
}
.assessment_survey iframe {
  background: transparent;
}
