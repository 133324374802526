.invite-member-row {
    align-items: center;
    border-bottom:1px solid #e1dbdb;
    cursor: pointer;
}
.profile-pic-left {
    height:50px;
    width:50px;
    border-radius: 10%;
}
.join-team-modal {
    .title {
        color:#2E485C;
        font-weight: bold;
        text-transform: none;
        font-size: medium;
    }
    .msg-title {
        color:#2E485C;
        font-weight: bold;
    }
    .text-area {
        height: 14rem !important;
        width: 100%;
        border: 1px solid #d8dadb;
        border-radius: 10px;
    }
}
.team-details {
    
    padding: 45px;

    .profile-pic {
        height:90px;
        width:90px;
        border-radius: 10%;
    }
    .team-name {
        color: #525A60;
        font-weight: 600;
        font-size: 18px;
        margin-top: 7px;
    }
    .person-name {
        font-weight: 500;
        font-size: 12px;
    }
    .grey-small-text {
        color:#525A608c;
        font-weight: 400;
        font-size: 11px;
    }
    .invite-btn {
        float: right;
        width: 70%;
    }
    .skill-level-component {
        display: flex;
        min-height: 36px;
        margin: 10px 0 0 20px;
        background: #f5f5f5;
        border-radius: 50px;
        height: auto;
        width: fit-content;
    }
    .skill-level-component-verified {
        display: flex;
        min-height: 36px;
        margin: 10px 0 0 20px;
        background: #E8FDF1;
        border-radius: 50px;
        height: auto;
        width: fit-content;
    }
    .invite-team-skill {
        display: flex;
    }
    .skill-match-color {
        color: #13AD13;
        text-align: right;
        font-weight: 600;
    }
    .skill-claimed-badge {
        background-color: #f5f5f5;
        height: 28px;
        width: 28px;
        background-repeat: no-repeat;
        background-size: contain;
        margin: auto 0 auto 4px;
        border-radius: 50%;
        font-size: 14px;
        text-align: center;
        line-height: 26px;
    }
    .view_button {
        align-items: center;
        font-weight: 700;
        cursor: pointer;
        background: white;
        justify-content: center;
        text-align: center;
    }
    .view-btn-center {
        align-content: center;
        flex-direction: column;
        display: flex;
        justify-content: center;
    }
    .individual__skills_list_close {
        height: 130px;
        overflow:hidden;
    }
    .individual__skills_list_open {
        height: auto;
    }
    .team-detail-title {
        color:#2E485C;
        font-weight: bold;
        font-size: 12px;
    }
    .team-detail-subtitle {
        color:#2E485C;
        font-size: 12px;
        margin-top: 3px;
    }
    .member-detail-isAdmin {
        margin-top: 0px;
        text-align: center;
        border: 1px solid #951B1E;
        background-color: #F8AAAC;
        color: #951B1E;
        font-weight: bold;
        border-radius: 6px;
        padding: 3px 5px;
        width: 47%;
        height: 2%;
        font-size: 8px;
    }
    .profile-pic-team-deatils {
        height:50px;
        width:50px;
        border-radius: 50%;
    }
    .member-row {
        align-items: center;
    }
}