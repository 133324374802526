.invite-team-tab {
    margin-bottom: 70px !important;
    .member-title {
        color: #525A60;
        font-weight: 600;
        font-size: 12px;
        padding: 20px 0px !important;
    }
    .invite-member-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 0px;
        display: flex;
        border-bottom: 1px solid #e5e4e4;

        .accept-btn {
            background-color: #951B1E;
            border-color: #951B1E;
            margin-right: 15px;
            padding: 4px 0px;
            height: 50%;
            font-size: small;
            width: 45%;
            border-width: medium;
        }
        .decline-btn {
            border-width: medium !important;
            color: #8F9AA0 !important;
            padding: 4px 0px;
            height: 50%;
            font-size: small;
            width: 45%;
            border-color: #e7e2e2;
        }
        .profile-pic {
            height: 65px !important;
            width: 65px !important;
            border-radius: 50%;
        }
        .person-name {
            font-weight: 600;
            font-size: 14px;
        }
        .grey-small-text {
            color: #525A608c;
            font-weight: 400;
            font-size: 12px;
        }
        .grey-text-joined-date {
            color: #525A608c;
            font-weight: 600;
            font-size: 13px;
        }
        .member-detail-isAdmin {
            margin-top: 0px;
            text-align: center;
            border: 1px solid #951B1E;
            background-color: #F8AAAC;
            color: #951B1E;
            font-weight: bold;
            border-radius: 6px;
            padding: 3px 9px;
            width: 47%;
            height: 2%;
            font-size: 8px;
        }
    }
   
}