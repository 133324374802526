.empprofile__trophy-verified-skills {
   width: 30px;
   height: 28px;
   border-radius: 50% !important;
   border: none;
   border-radius: 3px 0 0 3px;
   cursor: pointer;
   margin: auto;
   margin-left: 0px;
   margin-right: 10px;
   padding-left: 5px;
   @include themify($themes) {
      color: themed('colorOrange');
      background-color: themed('colorOrangeBackground');
   }
}

.empprofile__trophy-other-skills {
   width: 30px;
   height: 28px;
   border-radius: 50% !important;
   border: none;
   border-radius: 3px 0 0 3px;
   cursor: pointer;
   margin: auto;
   margin-left: 0px;
   margin-right: 10px;
   padding-left: 5px;

   @include themify($themes) {
      color: themed('colotLigthGreen');
      background-color: themed('colotLigthGreenBackground');
   }
}

.skill-level-component {
   display: flex;
   cursor: pointer;
   min-height: 36px;
   margin: 10px 0 0 20px;
   background: #f5f5f5;
   border-radius: 50px;
   height: auto;
   width: fit-content;
   //padding-right: 12px;
   .add-skill {
      color: #8f9aa0;
      margin: auto 10px auto 0;
   }
}

.skill-level-component.selected {
   border: 2px solid #525a60;
   //padding-right: 0 !important;
}

.skill-component__skill-name {
   //margin: 14px 15px 0 15px !important;
   padding: 7px 15px 7px 15px;
}

.skill-badge__bronze {
   width: 36px;
   height: 34px;
   border-radius: 50% !important;
   border: none;
   border-radius: 3px 0 0 3px;
   cursor: pointer;
   padding-top: 5px;
   text-align: center;

   @include themify($themes) {
      color: themed('colorBroze');
      background-color: themed('colorBronzeBackground');
   }
}

.skill-badge__silver {
   width: 36px;
   height: 34px;
   border-radius: 50% !important;
   border: none;
   border-radius: 3px 0 0 3px;
   cursor: pointer;
   padding-top: 5px;

   text-align: center;

   @include themify($themes) {
      color: themed('colorSilver');
      background-color: themed('colorSilverBackground');
   }
}

.skill-badge__gold {
   width: 28px;
   height: 28px;
   border-radius: 50% !important;
   border: none;
   cursor: pointer;
   text-align: center;
   margin: auto 0 auto 4px;
   align-items: center;
   justify-content: center;
   display: flex;

   @include themify($themes) {
      color: themed('colorGold');
      background-color: themed('colorGoldBaackground');
   }
}

.skill-claimed-badge {
   background-color: #f5f5f5;
   border: 2px solid #525a60;
   height: 28px;
   width: 28px;
   background-repeat: no-repeat;
   background-size: contain;
   margin: auto 0 auto 4px;
   border-radius: 50%;
   font-size: 14px;
   text-align: center;
   line-height: 26px;
}
.skill-completed-badge {
   background-image: url('../../shared/img/skills/CompeltedSkill.svg');
   height: 28px;
   width: 28px;
   background-repeat: no-repeat;
   background-size: contain;
   margin: auto 0 auto 4px;
}
.skill-certified-badge {
   background-image: url('../../shared/img/skills/CertifiedSkill.svg');
   height: 28px;
   width: 28px;
   background-repeat: no-repeat;
   background-size: contain;
   margin: 4px 0 auto 4px;
   min-width: 28px;
}
.badge-title {
   font-weight: 600;
   margin-top: 5px;
   margin-right: 6px;
}

.level0 {
   @include themify($themes) {
      color: themed('colorText');
   }
   font-weight: bold;
   padding: 7px 15px 0 0;
}
.level1 {
   @include themify($themes) {
      color: themed('colorGreen');
   }
   font-weight: bold;
   padding: 7px 15px 0 0;
}

.level2 {
   @include themify($themes) {
      color: themed('colorLevel2Skill');
   }
   font-weight: bold;
   padding: 7px 15px 0 0;
}
.level3 {
   color: $color-blue;
   font-weight: bold;
   padding: 7px 15px 0 0;
}
.level4 {
   @include themify($themes) {
      color: themed('colorDeepBlue');
   }
   font-weight: bold;
   padding: 7px 15px 0 0;
}
.level5 {
   @include themify($themes) {
      color: themed('colorLevel5Skill');
   }
   font-weight: bold;
   padding: 7px 15px 0 0;
}

.preferred-skill {
   @include themify($themes) {
      color: themed('colorBackgroundBody');
      background-color: themed('colorDarkGrey');
   }
}

.employee-certifications__title {
   margin: 15px;
}

.employee-certifications {
   display: flex;
   flex-wrap: wrap;
   //margin-top: 15px;
}

.employee-certifications__block {
   margin: 15px 15px 15px 10px; //old - 15 15 15 15 px
   height: 113px; //165px;
   width: 113px; //165px;
   border-radius: 19px;
   cursor: pointer;
   border: 1px solid;
   display: flex;
   align-items: center;
   justify-content: center;
   @include themify($themes) {
      border-color: themed('borderColor');
   }
   &:hover {
      @include themify($themes) {
         box-shadow: 0px 0px 15px 1px themed('colorLightGrey');
      }
   }
}

.employee-certifications__skill {
   height: 115px;
   width: 115px;
}

.employee-certifications__skill-small {
   height: 86px;
   width: 86px;
}

.certification-certificate__document {
   display: flex;
   justify-content: center;
   align-items: center;
}
.certification-iframe {
   width: 685px;
   height: 487px;
}

.inline-badge {
   padding-top: 3px;
   width: 26px;
   height: 24px;
   margin-left: 5px;
}

.individual__skills_list {
   height: 330px;
   overflow: hidden;
   padding: 1.25rem;

   .skill-title {
      font-size: 18px;
      font-weight: 600;
      color: #2e485c;
   }
   .skill-subtitle {
      font-size: 11px;
      font-weight: 500;
      color: #8f9aa0;
   }
   .view_all__button {
      z-index: 10;
      font-weight: 700;
      cursor: pointer;
      position: absolute;
      top: 318px;
      width: 93%;
      left: 20px;
      background: white;
      justify-content: center;
      text-align: center;
   }
}

.subtitle{
      font-size: 11px;
      font-weight: 500;
      color: #8f9aa0;
}

@media (max-width: 768px) {
   .individual__skills_list{
      padding: 0;
      height: auto;
   }
   .skills-card-body {
      border: none !important;
   }
   .view_all__button {
      display: none;
   }
   .skill-legends {
      display: none;
   }
   .skill-legends-want{
      display: none;
   }
   .other-skill-title {
      font-size: 18px;
      font-weight: 600;
      @include themify($themes) {
         color: themed('colorText');
      }
   }
}

.skill-legends {
   border-top: 1px solid #d8dadb;
   height: 200px;
   margin-top: 15px;

   .legend-text {
      font-weight: 500;
      padding-top: 4px;
      //max-width: 50%;
   }
}
.view_all_button{
   display: flex;
   justify-content: center;
   align-items: center;
}

.skill-legends-want{
   //border-top: 1px solid #d8dadb;
   height: 30px;
   margin-top: 15px;

   .legend-text {
      font-weight: 500;
      padding-top: 4px;
      //max-width: 50%;
   }
}

.skill-legends-mobile {

   height: 200px;
   margin-top: 15px;

   .legend-text {
      font-weight: 500;
      padding-top: 4px;
      //max-width: 50%;
   }
}

.individual__skills_list_open {
   height: auto;

   .view_all__button {
      top: unset;
      bottom: 201px;
   }
   //.individual__skills_list__arrow {
   //   -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
   //   -moz-transform: rotate(180deg); /* FF */
   //   -o-transform: rotate(180deg); /* Opera */
   //   -ms-transform: rotate(180deg);
   //   transform: rotate(180deg);
   //}
}
