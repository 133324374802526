.job__form {
  border: none !important;

  .MuiFormGroup-root {
    display: block !important;
  }
  label.MuiFormControlLabel-root {
    margin-bottom: 0;
  }
}

.css-2b097c-container {
  width: 100%;
}

.job__form-title {
  text-transform: unset;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;

  h5 {
    font-weight: 500;
    font-size: 16px;

    @include themify($themes) {
      color: themed("colorDarkGrey");
    }
  }
}

.job__form-group-radio {
  margin-bottom: 0px;
}

.job__form-group-lable {
  font-weight: 500;
  font-size: 16px;

  @include themify($themes) {
    color: themed("colorDarkGrey");
  }
}

.react-select-multi-select {
  .react-select__control {
    @include themify($themes) {
      border: 2px solid themed("borderColor");
      background-color: themed("colorBackgroundBody");
    }
  }
}

.job__skills-one {
  width: auto;
  float: left;
  margin-right: 15px;
  flex-direction: column;
}

.job__form-salary {
  display: flex;

  div {
    justify-content: center;
  }
}

.job__form-button {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;

  button {
    width: 10.25rem;
  }
}

.job__form-text-editor {
  flex-direction: column;
}

.job__form_information-text {
  display: inline;
  font-size: 10px;
  background-color: #F7E0B6;
  color: #895A00;
  padding: 4px;
  margin-left: 5px;
}

.panel__btn_close-icon {
  width: 14px;
  height: 14px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  @include directify($directions) {
    #{directed('margin-left')}: 5px;
  }

  svg {
    width: 14px;
    height: 14px;
    fill: #895A00;
  }

  &:focus {
    outline: none;
  }
}

.panel__btn_alert-circle {
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 0;

  @include directify($directions) {
    #{directed('margin-left')}: 5px;
  }

  svg {
    width: 24px;
    height:24px;
    fill: #525a60;
  }

  &:focus {
    outline: none;
  }

}
