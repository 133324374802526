@media screen and (max-width: 768px) {
   .mobile-responsive-modal,
   .modal-dialog {
      position: fixed;
      margin: 0;
      bottom: -2px;
      width: 100%;

      .modal-header {
         padding: 0;
         .modal-title {
            text-transform: none;
            font-size: 20px;
            color: #2e485c;
            font-weight: 600;
         }
      }

      .modal-body {
         margin: 0;
         padding: 0;
      }

      &.modal-dialog-centered {
        display: flex;
        align-items: center;
        min-height: auto;
      }
   }
}
