$themes: (light: (colorBackground: #fafafa,
    colorBackgroundBody: #ffffff,
    borderColor: #d8dadb,
    inboxButtonBackground: #f2f4f7,
    inboxButtonBackgroundHover: darken(#f2f4f7, 5%),
    colorHeaderDataPicker: #6db0ff,
    colorText: #2e485c,
    colorTextAdditional: #2e485c,
    // logoImg: url(../../shared/img/logo/CADS_BW2_light@2x.png),
    colorHover: #fafbfe,
    colorFolderHover: #f0eeee,
    colorBorder: #eff1f5,
        colorIcon: #cccccc, //colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #b4bfd0,
    colorFitness: #2e485c,
    colorEmoji: #232329,
    sidebarColor: #0092ff,
    colorLinkSecondary: #0092ff,
    colorLinkSecondaryHover: #105b95,
    colorDeepBlue: #105b95,
    colorBackgorundIconSecondary: #f0f3f8,
    colorOrange: #ff6200,
    colorOrangeBackground: #fff1dd,
    colorGreen: #13ad13,
    colotLigthGreen: #0cd370,
    colotLigthGreenBackground: #e5faef,
    colorDarkGrey: #525a60,
    colorLightGrey: #a3a3b1,
    colorBorderContainer: #b5b5c6,
    colorLightBlue: #f5f8fd,
    colorLevel2Skill: #fcb52f,
    colorLevel5Skill: #994eff,
    colorBroze: #51412d,
    colorBronzeBackground: #cd7f32,
    colorSilver: #808080,
    colorSilverBackground: #d3d3d3,
    colorGold: #8a4b00,
    colorGoldBaackground: #e6bc5c,
    colorAlabaster: #FCFCFC,
  ),
  dark: (colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    borderColor: #ffffff,
    inboxButtonBackground: #2a2a31,
    inboxButtonBackgroundHover: lighten(#2a2a31, 5%),
    colorHeaderDataPicker: #063263,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    // logoImg: url(../../shared/img/logo/logo_dark.svg),
    colorHover: #38373f,
    colorFolderHover: #ffffff1a,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorEmoji: #ffffff,
    sidebarColor: #232329,
    colorLinkSecondary: #ffffff,
    colorLinkSecondaryHover: #105b95,
    colorDeepBlue: #105b95,
    colorBackgorundIconSecondary: #f0f3f8,
    colorGreen: #13ad13,
    colorDarkGrey: #525a60,
    colorLightGrey: #a3a3b1,
    colorBorderContainer: #b5b5c6,
    colorLightBlue: #f5f8fd,
    colorLevel2Skill: #fcb52f,
    colorLevel5Skill: #994eff,
    colorAlabaster: #FCFCFC,
  ));

@mixin themify($themes) {

  @each $theme,
  $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge($theme-map,
            ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-accent: #2e485c;
$color-accent-hover: darken($color-accent, 10%);

$color-assign-btn: #13AD13;
$color-assign-btn-hover: darken($color-assign-btn, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #0092ff;
$color-blue-hover: darken($color-blue, 10%);

$color-brand: #951B1E;
$color-brand-hover: darken($color-brand, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-light-gray-2: #f5f5f5;
$color-light-gray-3: #e8e9ea;

$color-dark-gray-2: #8f9aa0;
$color-dark-gray-3: #efefef;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);

$color-link-water: #ECF1FA;

$color-dark-gray: #525A60;
$color-white: #ffffff;
$border-color: #D8DADB;
$color-light-gray: #A3A3B1;
$color-light-gray-4: #fafafa;

$directions: (ltr: (direction: ltr,
    right: right,
    left: left,
    margin-right: "margin-right",
    margin-left: "margin-left",
    padding-left: "padding-left",
    padding-right: "padding-right",
    border-top-left-radius: "border-top-left-radius",
    border-bottom-left-radius: "border-bottom-left-radius",
    border-top-right-radius: "border-top-right-radius",
    border-bottom-right-radius: "border-bottom-right-radius",
    border-left: "border-left",
    border-right: "border-right",
    translate: "translate(-50%, -50%);",
    mirrorY: "scale(1, 1)",
    border-right-color: "border-right-color",
    transform-sidebar: "rotate(90deg)",
    flex-flow: row nowrap,
    row: "row",
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    "-": "-"
  ),
  rtl: (direction: rtl,
    right: left,
    left: right,
    margin-right: "margin-left",
    margin-left: "margin-right",
    padding-left: "padding-right",
    padding-right: "padding-left",
    border-top-left-radius: "border-top-right-radius",
    border-bottom-left-radius: "border-bottom-right-radius",
    border-top-right-radius: "border-top-left-radius",
    border-bottom-right-radius: "border-bottom-left-radius",
    border-left: "border-right",
    border-right: "border-left",
    translate: "translate(50%, -50%);",
    mirrorY: "scale(1, -1)",
    border-right-color: "border-left-color",
    transform-sidebar: "rotate(-90deg)",
    flex-flow: row-reverse nowrap,
    row: "row-reverse",
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(100%)),
    "-": "+"
  ));

@mixin directify($directions) {

  @each $direction,
  $map in $directions {
    .#{$direction}-support & {
      $direction-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($directions, $direction), "#{$key}");
        $direction-map: map-merge($direction-map,
            ($key: $value)) !global;
      }

      @content;
      $direction-map: null !global;
    }
  }
}

@function directed($key) {
  @return map-get($direction-map, $key);
}
