@import 'settings/variable'; //themes' colors

//vendor...
@import './vendor';

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding'; // styles of base elements
@import './base/typography'; // base styles of h1-h6, p, span

@import 'containers/covid-map.scss';
// B2CIndividual/Home/components/covidMap/CovidMap.js
@import 'containers/post-section-style.scss';
// B2CIndividual/Home/components/posts/Posts.js
@import 'containers/post-details.scss';
@import 'containers/saved-data.scss';
@import 'containers/organization-details.scss';
@import 'containers/winners.scss';
@import 'containers/dashboard-competition.scss';
@import 'containers/course-details.scss';
@import 'containers/jobOrProjectChart.scss';


@import 'containers/company-profile.scss';
@import 'containers/add-benefits.scss';

@import 'containers/inquiry-page.scss';

//components...
@import './component/alert'; // components/Alert.js ('reactstrap')
@import './component/btn'; // Button from 'reactstrap'
@import './component/calendar'; // components/Calendar.js  ('react-big-calendar')
@import './component/card'; // Card, CardBody from 'reactstrap'
@import './component/carousel'; // components/carousel/* ('react-slick')
@import './component/catalog_item'; // components/catalog/CatalogItems.js
@import './component/chat'; // components/chat/*
@import './component/charts'; // components/charts/*
@import './component/check-box'; // components/form/Checkbox.js and other components with checkboxes
@import './component/check-box-rounded'; // components/form/Checkbox.js and other components with checkboxes
@import './component/collapse'; // components/Collapse.js ('reactstrap')
@import './component/color-picker'; // components/form/*ColorPicker.js ('react-color')
@import './component/date-picker'; // components/form/*Date*Picker.js ('react-datepicker')
@import './component/dropdown'; //
@import './component/dropzone'; // components/form/DropZone*.js ('react-dropzone')
@import './component/fileUploadDropzone'; // css for resume uplode dropzone 
@import './component/edit-table'; // components/table/EditableTable.js ('react-data-grid')
@import './component/form'; // <form> and all types of inputs
@import './component/gallery'; // components/Gallery.js
@import './component/grid'; // useless. using for grid presentation
@import './component/inbox'; // components/inbox/*
@import './component/list';
@import './component/map'; // GoogleMap from 'react-google-maps'
@import './component/material-form'; // for 'material-ui'
@import './component/modal'; // components/Modal.js ('reactstrap')
@import './component/notification'; // components/Notification.js ('reactstrap')
@import './component/pagination'; // components/Pagination.js ('reactstrap')
@import './component/panel'; // components/Panel.js
@import './component/popover'; // Popover from 'reactstrap'
@import './component/pricing-card';
@import './component/product-gallery'; // components/catalog/ProductGallery.js
@import './component/progress-bar'; // ProgressBar from 'reactstrap'
@import './component/project-member'; // components/ProjectMember.js
@import './component/radio-btn'; // components/form/RadioButton.js
@import './component/react-select';
@import './component/scroll';
@import './component/search-result'; // components/SearchResult.js
@import './component/select-options'; // <select> and <option>
@import './component/sidebar'; // components/sidebar/*
@import './component/slider'; // components/range_slider/* ('rc-slider')
@import './component/table'; // components/Table.js ('reactstrap')
@import './component/tabs';
@import './component/search-board';
// Nav, NavItem, NavLink, TabContent, TabPane from 'reactstrap'
@import './component/text-editor';
// components/text-editor/TextEditor.js ('react-draft-wysiwyg')
@import './component/time-picker';
// components/form/TimePicker.js ('react-times')
@import './component/timeline';
// components/TimeLineItem.js
@import './component/todo';
// components/ToDo.js
@import './component/toggle-btn';
// components/form/ToggleButton.js
@import './component/tooltip';
// Tooltip from 'reactstrap'
@import './component/topbar';
// components/topbar/*
@import './component/wizard';
// pages/form/wizard_form/*
@import './component/email-confirmation';
@import 'component/learning-path';
@import 'component/learning-path-module';
// pages/form/wizard_form/*
@import './component/material-table';
// pages/tables/material_table/*
@import './component/load';
@import './component/customizer';
@import './component/input.scss';
@import './component/breadcrumbs';
@import './component/post-job-form';
@import './component/organization-profile';
@import './component/employee-visit';
@import './component/assessments';
@import './component/dashboard-indvidual';
@import 'component/dashboard-individual-imgs';
@import './component/assessment-survey';
@import './component/heatmap-chart';
@import 'containers/chartPage';
@import './component/individual-profile';
@import './component/individual-profile-details';
@import './component/other-individual-skill.scss';
@import './component/quiz-modal.scss'; 
@import './component/mui-components.scss';
@import './component/mobile-modal.scss';
@import './component/qandACard.scss';
@import './component/invite-team.scss';
@import './component/join-team.scss';
//@import './component/typeahead';
//pages...
@import 'containers/account';
@import 'containers/qandA.scss';

// pages/account/* (except profile)
@import 'containers/cart';
// pages/e-commerce/cart/*
@import 'containers/dashboard';
// pages/dashboards/*
@import 'containers/invoice';
// pages/default_page/invoice_template
@import 'containers/not-found';
// pages/default_page/404/*
@import 'containers/payment';
// pages/e-commerce/payment/*
@import 'containers/product-card';
// pages/e-commerce/product-page/components/ProductCard.js
@import 'containers/product-edit'; // pages/e-commerce/product_edit/*
@import 'containers/products-list'; // pages/e-commerce/product_list/*
@import 'containers/profile'; // pages/account/profile/*
@import 'containers/project-summary'; // pages/default_pages/project_summary/*
@import 'containers/documentation';
@import 'containers/landing';
@import 'containers/opporunities';
@import 'containers/opportunities-mobile';
@import 'containers/competition';
@import 'containers/competition-details.scss';
@import 'containers/competition-q&a.scss';
@import 'containers/participants-team-table.scss';
@import 'containers/competition-team-profileInfomation';


@import './PerkesoContainer/Perkeso.scss';

@import 'containers/messages';
@import 'containers/home-page';
@import 'containers/custom-mobile-alert';
@import './containers/team-individual-base.scss';
@import './containers/own-team.scss';
@import './containers/fom-team-page.scss';
@import './containers/invite-team-tab.scss';

@import './containers/group-chat.scss';

@import "./containers/profilePopOver.scss";
@import './containers/before-join-team.scss';
@import './containers/after-join-team.scss';
@import './component/opportunitiesCards.scss';
@import './component/myTable.scss';
@import './component/compare-employee.scss';
@import './component/compare-employees-add-profile-modal.scss';
@import './component/compare-employees-certifications.scss';
@import './component/compare-employees-data-skills.scss';
@import './component/compare-employees-experience.scss';
@import './component/compare-employees-profile.scss';
@import './component/compare-employees-smart-skills.scss';
@import './component/compare-employees-smart-skills-show-more.scss';

@import './containers/data-star-home.scss';
@import './containers/karisma';

//objects...
@import 'objects/layout.scss'; // layout settings

.app-tour {
   background-color: #951b1e !important;
   color: #fff !important;
}

.reactour__close {
   color: white !important;
}

.tour_prev_btn {
   color: white !important;
   font-size: 14px;
   font-weight: 600;
   &.first {
      cursor: default;
      color: grey !important;
   }
}

.tour_next_btn {
   color: white !important;
   font-size: 14px;
   font-weight: 600;
   &.last {
      cursor: default;
      color: grey !important;
   }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type='number'] {
   -moz-appearance: textfield;
}

// Smarskills tabs design

.smartskill-tabs {
   background-color: #f7f7f8;
   padding: 6px 0 2px 0;
}


.ck .ck-balloon-panel {
   z-index:  9999 !important;
}

.top1-span {
   height: 50px;
   width: 100px;
   background: white;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   left: 60px;
   // top: 234px;
   bottom: 50px;
   border-radius: 3px 3px 0 0;
   border-bottom: 1px solid gray;
   font-size: 20px;
   font-weight: 700;
}
.copy_link_plot{
   display: flex;
   flex-direction: row;
   align-items: center;
   // height: 40px;
   background-color: #F5F5F5;
}

.nomination_note{
   background-color: #F5F5F5;
}
.skill_view{
   display: flex;
   flex-flow: wrap;

   .preferred-skill {
      color: #fff;
      background-color:#525a60;
   }
}
