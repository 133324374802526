.container__wrap {
   .dark-grey-color {
      color: #525a60;
   }

   .dark-red-color {
      background-color: #951b1e;
   }

   .btn.btn-secondary:active,
   .btn.btn-secondary:focus,
   .btn.btn-secondary:active:before,
   .btn.btn-secondary:focus:before {
      background-color: #951b1e;
      color: #fff;
   }

   .red-btn {
      border-radius: 4px;
      color: #fff;
   }

   .light-blue {
      color: #2e485c;
   }
}
.pull-right {
   float: right;
}

.img-fir-cover {
   object-fit: cover;
}

.pb-40 {
   padding-bottom: 40px;
}

.pt-40 {
   padding-top: 40px;
}

.card-location {
   .location-name {
      font-size: 14px;
      font-weight: 300;
   }

   .location-date {
      font-size: 11px;
      svg {
         margin-right: 5px;
      }
   }
}

.card-link {
   color: #13ad13;
   border-radius: 16px;
}

.card-height {
   height: 240px;
}

.data-star-card {
   border-radius: 12px;
   max-height: 530px;
   overflow: hidden;
}

.data-star-header-img {
   background-image: url('https://images.unsplash.com/photo-1569849093193-4f1a293992a1');
   background-position: center center;
   background-repeat: no-repeat;
   min-height: 290px;
   background-size: 100% 100%;
   display: flex;
   align-items: flex-start;
   padding-left: 40px;
   flex-direction: column;
   justify-content: flex-end;

   @media (max-width: 480px) {
      padding-left: 0;
      padding-left: 28px;
   }
}

.data-star-main-div {
   position: relative;
}

.data-star-image {
   width: 100px;
   bottom: 0;
   left: 0;
}

.data-star-box {
   max-width: 390px;
   @media (max-width: 480px) {
      max-width: 320px;
   }
   position: relative;
}

.data-star-box h3 {
   padding: 37px 27px 0;
}

.data-star-title {
   font-weight: bold;
   font-size: 27px;
}

.data-star-desc {
   size: 17px;
   letter-spacing: 0.34px;
   padding: 0 27px 20px;
}

.data-star-accelerator-card-title {
   font-weight: 600;
   letter-spacing: 0px;
   font-size: 24px;
}

.font-16 {
   font-size: 16px;
}

.company-text {
   color: #8f9aa0;
   font-size: 12px;
}

.data-professional-img {
   object-fit: cover;
   height: 200px;
}

.sponsor-img {
   object-fit: cover;
   width: 100%;
   height: 120px;
}

.desc {
   letter-spacing: 0.32px;
   font-size: 16px;
}

.professional-card {
   border-radius: 8px;
   width: 20%;
   padding: 15px;
   &:hover {
      background: #f3f0f0;
   }
}

.tag {
   background: #2e485c;
   color: #fff;
   max-width: 96px;
   padding: 8px 10px;
   text-align: center;
   position: absolute;
   left: 10px;
   span {
      font-weight: 600;
   }
}

.back-link {
   height: 40px;
   width: 40px;
}

.data-star-application-modal {
   @media (min-width: 576px) {
      max-width: 800px;
      margin: 1.75rem auto;
   }
   .MuiGrid-container {
      margin-top: 0 !important;
      .submit-btn {
         background-color: #6b747d;
         color: #fff;
      }
   }

   .modal-header {
      padding: 0;
      justify-content: center;
      .close {
         position: absolute;
         right: 20px;
      }
   }

   .modal-title {
      text-align: center;
      .title {
         text-transform: none;
         color: #2e485c;
         font-size: 22px;
      }
      .subtitle {
         color: #525a60;
         font-size: 16px;
         text-transform: none;
         font-weight: 500;
      }
   }
}

.application-status {
   padding-left: 0;
   margin-top: 30px;

   .status-inner {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-end;
      .status-items {
         padding: 8px 8px 8px 0;
         margin-bottom: 10px;
         width: 33.33%;
         font-size: 16px;
         color: #2e485c;

         span {
            display: block;
         }
      }
   }
}

.accelerator-footer {
   background: #fafafa;
   padding: 15px;
   min-height: 73px;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   color: #2e485c;
}

.accelerator-thumb {
   overflow: hidden;
   height: 205px;
   border-top-right-radius: 12px;
   border-top-left-radius: 12px;
}

.data-star-indv-box {
   max-width: 406px;
   position: relative;
   h3 {
      padding: 37px 27px 0;
   }
   p {
      padding: 0 27px 20px;
   }
   .data-star-image {
      width: 100px;
      height: 52px;
      img {
         width: 100%;
         height: 52px;
      }
   }
   @media (max-width: 480px) {
      max-width: 300px;
   }
}

.brand-color-green {
   color: #48ae19;
}

.assessment-status {
   float: right;
   margin-right: 70px;
   color: #48ae19;
}

.application-status-message {
   margin-top: 50px;
   p {
      font-weight: 400;
      font-size: 16px;
   }
   .error-desc {
      letter-spacing: 0.32px;
      font-size: 16px;
      color: red;
   }
   .inelligible-status {
      font-weight: 600;
      color: #000;
      text-align: left;
   }
}

.assessment-invalid-status {
   color: red;
   float: right;
   margin-right: 70px;
}

.assesments-completed-message {
   margin-top: 3rem;
   font-size: 16px;
   color: #2e485c;
}
