.breadcrumbs {
    display: flex;

    @include themify($themes) {
        color: themed('colorLightGrey');
    }

    div>a {
        @include themify($themes) {
            color: themed('colorLightGrey');
        }
    }
}