.orgprofile__avatar {
   height: 100%;
   display: flex;
   cursor: pointer;
   position: relative;
   border-radius: 0;
   border: none;
   transition: all 0.3s;
   box-shadow: none;
   padding: 0 15px;
   background-color: transparent;
}

.orgprofile__avatar-img {
   object-fit: cover;
   margin: auto 0;
   border-radius: 12%; // old value - 12%
   height: 120px; // old value - 135px
   width: 120px; // old value - 135px
   // box-shadow: 0 0 8px 2px #e1e1e1;
   //border: 2px solid #cacaca;
}

.orgprofile__avatar-image {
   margin: auto 0;
   border-radius: 12%; // old value - 12%
   height: auto !important; // old value - 135px
   width: 120px !important; // old value - 135px
   // box-shadow: 0 0 8px 2px #e1e1e1;
   //border: 2px solid #cacaca;
   .MuiAvatar-img {
      height: auto; // old value - 135px
      width: 120px; // old value - 135px
   }
}

.orgprofile__avatar-edit-icon {
   // position: absolute;
   // bottom: 0;
   // left: 0;
   width: 28px;
   height: 28px;
   border-radius: 15px;
   display: flex;
   justify-content: center;
   align-items: center;
   // padding-top: 4px;
   // padding-left: 8px;
   position: absolute;
   right: 15px;
   margin-top: 5px;

   @include themify($themes) {
      background: themed('colorLightBlue');
      color: themed('colorText');
   }
}

@media (max-width: 768px) {
   .orgprofile__avatar-img {
      margin: auto 0;
      border-radius: 12%; // old value - 12%
      height: auto; // old value - 135px
      width: 5rem; // old value - 135px
      // box-shadow: 0 0 8px 2px #e1e1e1;
      //border: 2px solid #cacaca;
   }
   .orgprofile__avatar-edit-icon {
      display: none;
   }
   .orgprofile__avatar {
      padding: 0;
   }
   .orgprofile__avatar-no {
      padding-right: 0;
   }
}

.opentowork img{
   width: 100%;
}.opentoworkRecommendations img{
   height: 40px !important;
   min-width: 40px !important;

 }


.orgprofile__basic-detail {
   height: 100%;
   display: flex;
   float: left;
   cursor: pointer;
   position: relative;
   // border-radius: 0;
   // border: none;
   transition: all 0.3s;
   //min-height: 67px;
}

.orgprofile__basic-icon {
   width: 30px;
   height: 28px;
   border-radius: 50% !important;
   border: none;
   border-radius: 3px 0 0 3px;
   cursor: pointer;
   margin: auto;
   margin-left: 0;
   margin-right: 10px;
   padding-left: 5px;

   @include themify($themes) {
      color: themed('colorLinkSecondary');
      background-color: themed('colorBackgorundIconSecondary');
   }
}

.orgprofile__basic-information {
   text-align: left;
   margin-left: 10px;
   margin-top: 10px;
   width: 100%;
}

.orgprofile__basic-body {
   font-weight: 500;
   line-height: 18px;
   font-size: 1rem;
   margin-top: 5px;

   textarea {
      width: 100%;
      //height: 85px;

      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      text-align: justify;
   }

   .orgprofile__info-edit-icon {
      visibility: hidden;
   }




   textarea::-webkit-scrollbar {
      display: none;
   }

   @include themify($themes) {
      color: themed('colorText');
   }
}

.orgprofile__basic-body:hover {
   .orgprofile__info-edit-icon {
      visibility: visible;
   }
}

.orgprofile__basic-header {
   display: flex;
   font-weight: 400;
   // margin-bottom: 10px;
   margin-top: 0;
   line-height: 18px;
   align-items: center;
}

.orgprofile__basic-header-title {
   opacity: 0.6;

   @include themify($themes) {
      color: themed('colorDarkgrey');
   }
}

.orgprofile__info-edit-icon {
   width: 24px;
   height: 24px;
   border-radius: 15px;
   // padding-top: 3px;
   // padding-left: 3px;
   display: flex;
   justify-content: center;
   align-items: center;
   /* left: 0; */
   /* position: absolute; */
   /* right: 0; */
   /* background: red; */
   margin-left: 9px;
   color: initial;
   cursor: pointer;

   @include themify($themes) {
      background: themed('colorLightBlue');
      color: themed('colorText');
   }
}

.orgprofile__container {
   overflow: unset;
}

.orgprofile__avatar-no {
   //display: flex;
   //margin: auto;
   margin-right: 0;
   margin-left: 0;
   justify-content: center;
   //align-items: center;

   label {
      display: flex;
   }
}

.orgprofile__avatar-no-img {
   /* margin: auto 0; */
   border-radius: 12%;
   height: 120px;
   width: 120px;
   color: $color-blue;

   @include themify($themes) {
      background: themed('colorLightBlue');
      // color: themed("colorText");
   }
}

.orgprofile__avatar-icon-only {
   width: 135px;
}

.save-button,
.cancel-button {
   border: none;
   border-radius: 4px;
   height: 34px;
   width: 34px;
   margin-right: 5px;
   margin-top: 5px;
}

.save-button {
   @include themify($themes) {
      background: themed('colorGreen');
      color: themed('colorBackgroundBody');
   }

   svg {
      width: auto;
   }
}

.cancel-button {
   background: $color-light-gray-2;
   color: $color-dark-gray-2;

   svg {
      width: auto;
   }
}

.orgprofile__input {
   // min-width: 250px;
   z-index: 1;
   position: relative;
}

.orgprofile__input-button {
   // right: 9px;
   // position: absolute;
   // z-index: 100;
   text-align: right;

}

.indv_side{
   .ck-reset_all{
      display: none;  
     }
}

.orgprofile__input-location-text {
   margin-bottom: 0;
}


.indv_side{
   .ck-reset_all{
      display: none;
   }
}

.orgprofile__input-location-select {
   margin-top: 1rem;
}

.vertical-line {
   float: left;
   border: 1px solid rgba(128, 123, 123, 0.43);
   height: 67px;
}

.orgprofile_bio {
   fontsize: 13px;
   margintop: 0;
}

.orgprofile-name {
   font-size: 24px;
   font-weight: 600;
   @include themify($themes) {
      color: themed('colorDarkgrey');
   }
}

@media (max-width: 768px) {

   .progress {
      height: 2px !important;
      width: 100% !important;
   }
   .orgprofile-name {
      font-size: 20px;
      font-weight: 500;
   }

   .orgprofile__basic-body {
      margin: 0;
      line-height: 1;

      // .orgprofile__info-edit-icon {
      //    visibility: visible;
      // }
   }

   .orgprofile-field-Location {
      font-size: 13px;
   }
   .orgprofile-field-Email {
      color: #2e485c;
      fontsize: 12px;
      fontweight: 300;
   }
}

.profile__bio_count {
   font-size: 11px;
   margin-top: 6px;
   //position: absolute;
   font-weight: normal;
}

.custom-placeholder {
   height: 140px;
   background-color: whitesmoke;
   //border: 2px solid #CCC;
}

.orgprofile__info-delete-icon {
   width: 24px;
   height: 24px;
   border-color: transparent;
   border-radius: 15px;
   cursor: pointer;
   color: grey;
   @include themify($themes) {
      background: themed('colorLightBlue');
      color: themed('colorText');
   }
}
.orgprofile__info-menu-icon {
   width: 24px;
   height: 24px;
   border-color: transparent;
   border-radius: 15px;
   color: initial;
   cursor: pointer;
   margin-left: 9px;
   @include themify($themes) {
      background: themed('colorLightBlue');
      color: themed('colorText');
   }
}

.hidden {
   //max-height: 40px;
   //max-height: 4.284em ;
   text-overflow: ellipsis;
   overflow: hidden;
}
.show_content {
   //max-height: content-box;
   //line-height: 1.428;
}

.read-more {
   cursor: pointer;
   width: fit-content;
   color: #659aca;
}

.read-more:hover {
   color: #0092ff;
}

.orgprofile_avatar-no-img-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #efefef;
   color: grey;
   border-radius: 12%;
   height: 120px;
   width: 120px;
}

.individual__bio {

}

@media (max-width: 768px) {
   .individual__bio {

   }
}
