.qandA-title {
   padding: 20px;

   h2 {
      font-weight: 600;
   }
}
.questionCount {
   p {
      font-weight: 700;
      color: #8E8E8E;
   }
}
.newest-tab-container {
   .btn-top-right {
      margin-right: 6% !important;
   }
}
.questionModal {
   .modal-dialog {
      max-width: 600px;
   }
}
.tabs__qandA {
   padding: 0px !important;
   .tab-pane {
      padding-top: 0px !important;
   } 
}

.three-dots-delete-answer {
   .topbar__link-title {
      margin: 0;
      font-size: 12px;
      line-height: 16px;
   }
   .topbar__menu-wrap {
      z-index: 101;
      position: absolute;
      width: 100%;
      min-width: 145px;
   }
   .topbar__menu {
      padding: 2px 0;
   }
}

.question-details-main {
   .question {
      display: flex;
      background-color: #fafafa;

      .back-btn {
         color: #4E6174;
         margin-top: 12px;
      }

      .edit-question {
         position: absolute;
         left: 70%;
         margin-top: 10px;

         .orgprofile__avatar-edit-icon{
            right: 25px;
         }

      }

      .question-detail {
         margin-left: 30px;
         width: 100%;
         max-width: 70%;
         min-height: 310px;
         .edit-question-actions {
            display: flex;
         }

         .qa-qst {
            margin-top: 15px;
            font-size: 20px;
            font-weight: 600;
         }

         .qa-desc {
            white-space: break-spaces
         }

         .qa-desc img{
            object-fit: cover;
            margin: auto 0;
            width: 100%;
         }

         .bottom-content {
            // position: absolute;
            // bottom: 60px;


            .question-time {
               color: #525A60;
               font-size: 12px;
               font-weight: 500;
               margin: 12px 0;
            }

            .like-follow {
               color: #8e8e8e;
               margin-top: 12px;

               span {
                  margin-right: 10px;
               }
               .card-icon {


                  &.selected {
                      color: $color-brand
                  }
              }
            }
         }
      }
   }

   .answers {
      margin-left: 50px;
      margin-top: 0px;
      max-width: 70%;

      .ans-counts {
         font-size: 18px;
         font-weight: 600;
      }

      .ans-component {
         display: flex;
         margin: 20px 0;
         .user-pic {
            img {
               height: 45px;
               width: 45px;
               border-radius: 50%;
            }
         }

         .edit-ans {
            position: absolute;
            left: 70%;
            margin-top: 15px;

            .orgprofile__avatar-edit-icon{
               margin-top: 6px;
               right: 25px;
            }
         }

         .ans-component-sub {
            margin-left: 8px;
            padding-top: 4px;
            width: 100%;
            .ans-time {
               color: #8F9AA0;
               font-size: 12px;
            }
            .ans-user {
               font-size: 16px;
               font-weight: 500;
               color: #525A60;
            }
            .ans-text {
               margin-top: 5px;
               white-space: break-spaces;
               color: #2E485C;
            }

            .edit-ans-sub {

               .edit-ans-actions {
                  display: flex;
               }
            }
            .like-follow {
               color: #8e8e8e;
               margin-top: 12px;

               span {
                  margin-right: 10px;
               }

               .card-icon {

                  &.selected {
                     color: $color-brand
                  }
               }
            }
         }
      }
   }

   .submit-ans {
      margin-left: 50px;
      margin-top: 20px;
      max-width: 70%;
      display: flex;

      .ans-circle {
         height: 45px;
         width: 45px;
         border-radius: 50%;
         // background-color: pink;
      }

      .submit-ans-sub {
         margin-left: 8px;
         width: 100%;
         display: grid;
      }
   }
}

.qa-posted-by-component {
   display: flex;
   align-items: center;


   .qandA-profile-pic {
      height: 45px;
      width: 45px;
      border-radius: 50%;
   }
   .postedby-sub {
      margin-left: 8px;
      .postedby-sub-text {
         color: #525A60;
         font-size: 12px;
         font-weight: 500;
      }

      .postedby-sub-name {
         font-size: 16px;
         font-weight: 500;
      }
      p {
         margin: 0;
      }
   }

}



