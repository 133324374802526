.inquiry__page {
  .modal-content {
	border-radius: 10px;
	padding: 20px 20px 0 20px;

    .inquiry__page_body {
      .inquiry__page_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .header_title {
          font-weight: 600;
          font-size: medium;
        }

        button {
          margin-bottom: 3px;
        }
      }

      Label {
        font-weight: 600;
      }

      textarea {
        height: 200px;
      }

      .inquiry__page_footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 480px){ 
  .inquiry__page {
    
    border-radius: 10px;
 
      .inquiry__page_body {
        .inquiry__page_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
  
          .header_title {
            font-weight: 600;
            font-size: medium;
          }
  
          button {
            margin-bottom: 3px;
          }
        }
  
        Label {
          font-weight: 600;
        }
  
        textarea {
          height: 200px;
        }
  
        .inquiry__page_footer {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
        }
      }
      .inquiry_text_mobile {
        width:100%;
      }
  }
}

