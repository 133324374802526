@import "./Learn/CourseHeader.scss";
@import "./Learn/Difficulty.scss";
@import "./Learn/Duration.scss";
@import "./Learn/Language.scss";
@import "./Learn/learning_path_courses.scss";
@import "./Learn/PerkesoOffice.scss";
@import "./Learn/PreferredProfession.scss";
@import "./Learn/PreferredSkill.scss";
@import "./Learn/Professions.scss";
@import "./Learn/Rating.scss";
@import "./Learn/SlidingPanelComponent.scss";
@import "./Learn/SubtitleLanguage.scss";
@import "./MyProfileSubComponent/MyProfilesubContainer";
@import "./MySmartSkills/PerkesoSmartSkills";

.perkeso__skills_match_slider {
    .MuiSlider-root {
        color: #951B1E;
    }
}
