.opportunities-filter-panel{
  max-width: 90vh;
}

.job-project__post__other-skill_add {
  display: flex;
  max-width: 300px;
}

.job-project__post__other-skill_add input {
  width: 200px;
}

.post-job-btn {
  position: absolute;
  right: 25px;
  top: 70px;

}

.jobs_tab {
  .upload-sub-tabs {

    .nav-link.active {
      text-align: center;
      padding: 7px !important;
      color: white !important;
      font-weight: normal !important;
      width: 100%;
      border-radius: 40px !important;
      border-bottom: none !important;
      background: #525A60 !important;
    }
    .nav-link {
      text-align: center;
      padding: 5px !important;
      color: #525A60 !important;
      font-weight: normal !important;
      width: 100%;
      border-radius: 40px !important;
      border-bottom: none !important;
      background: #F5F5F5 !important;
    }
    .link-pad {
      padding: 7px !important;
    }
    .nav-item {
      margin-right: 10px;
    }
    .nav-tabs {
      margin-bottom: 20px;
      border-bottom: none;
    }
  }
}
.job_post_data_star{
  height: 25px;
  text-align: center;
}

.job_post_data_star .btn{
  margin-bottom: 0px ;
}

  .post_job_projeect_box_slider .MuiSlider-root{
    color: #6a1315
  }

.profile_tab_switch .MuiSwitch-colorSecondary.Mui-checked {
  color: #6a1315
}.profile_tab_switch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #6a1315
}
.common_color{
  color:#2e485c;
}

.font_size_16{
  font-size: 16px;
}