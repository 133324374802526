.form-team-tab {
    color: #2E485C;
    .nav-tabs {
        border-bottom: none !important;
    }
    .nav-item {
        font-size: 12px !important;
    }
    .nav-link.active {
        border-bottom: none !important;
    }
    .orgprofile__avatar-img {
        border-radius: 7% !important;
        width: 150px !important;
        height: 150px !important;
        object-fit:cover !important;
    }
    .orgprofile__avatar-edit-icon {
        width: 24px;
        height: 24px;
        margin-top: 5px;
        cursor: pointer;
    }
    .orgprofile__avatar-desc-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f8fd;
        color: #2e485c;
        margin-left: 5px;
    }
    .orgprofile__avatar-no {
        margin-right: 0;
        margin-left: 0;
        justify-content: initial;
    }
    .team-name-title {
        font-size: 30px;
        font-weight: 700;
        float: left;
    }
    .team-formed {
        font-size: 14px;
        span {
            font-weight: 700;
        }
    }
    .team-description-title {
        font-size: 14px;
        font-weight: 700;
    }
    .team-member-list-div {
        background-color: #F4F4F4;
        .team-member-title {
            color:#525A60;
            font-weight: 600;
            font-size: 12px;
            padding:20px
        }
    }
    .common_red_border_btn {
        background: #ffffff;
        border: 2px solid #951B1E;
        border-radius: 4px;
        font-size: 14px;
        color: #951B1E;
        font-weight: bold;
        padding: 0px 10px !important;
        height: 31px;
    }
    .team-member-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 0px;
        display: flex;
        border-bottom: 1px solid #e5e4e4;

        .profile-pic {
            height: 65px !important;
            width: 65px !important;
            border-radius: 50%;
        }
        .person-name {
            font-weight: 600;
            font-size: 14px;
        }
        .grey-small-text {
            color:#525A608c;
            font-weight: 400;
            font-size: 11px;
        }
        .member-detail-isAdmin {
            margin-top: 0px;
            text-align: center;
            border: 1px solid #951B1E;
            background-color: #F8AAAC;
            color: #951B1E;
            font-weight: bold;
            border-radius: 6px;
            padding: 3px 9px;
            width: 47%;
            height: 2%;
            font-size: 8px;
        }
    }
    .upper-btn {
        width: 45%;
    }
    .bottom-btn {
        width: 13%;
        padding: 7px 0px;
        height: 60%;
        font-size: small;
    }
    .progress-team-submission-upload {
        float: right;
        width:50%;
    }
    .uploadAndSubmitted-btn {
        background-color: #DFE4E6 !important;
        color: #2E485C !important;
        border: none !important;
        padding: 5px 15px;
        font-size: 12px;
        border-radius: 3px;
        margin-bottom: 20px;
        transition: all 0.4s;
        font-size: 14px;
        position: relative;
        overflow: hidden;
        line-height: 1.5;
        line-height: 1.5;
        z-index: 0;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    .botton-btn-grey {
        border-width: medium !important;
        color: #8F9AA0 !important;
    }
    .assign-role-btn {
        border: 2px solid #8F9AA0;
        color: #8F9AA0 !important;
        width: 50%;
        padding: 7px 0px;
        height: 60%;
        float: right;
        font-size: small;
    }
    .add-remove-member {
        .topbar__menu {
            width: 100%;
        }
        .topbar__btn svg {
            fill : #8F9AA0 !important;
            height: 20px;
            width: 18px;
        }
        .topbar__link {
            display: flex;
            padding: 9px 14px;
        }
    }
    
}

.assign-role-modal {
    .modal-content {
        text-align: unset;
    }
    .title {
        font-weight: bold;
        font-size: 18px;
        text-transform: none;
    }
    .subtitle {
        margin-top: 0px;
        font-size: 13px;
        text-transform: none;
    }
    .team-member-list-modal {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 0px;
        display: flex;
        border-bottom: 1px solid #e5e4e4;

        .profile-pic {
            height: 45px !important;
            width: 45px !important;
            border-radius: 50%;
        }
        .person-name {
            font-weight: 600;
            font-size: 14px;
            text-align: initial;
        }
        .grey-small-text {
            color:#525A608c;
            font-weight: 400;
            font-size: 11px;
        }
        .member-detail-isAdmin {
            margin-top: 0px;
            text-align: center;
            border: 1px solid #951B1E;
            background-color: #F8AAAC;
            color: #951B1E;
            font-weight: bold;
            border-radius: 11px;
            padding: 3px 9px;
            width: 47%;
            height: 2%;
            font-size: 8px;
        }
    }
}
.no-data-found {
    width: inherit;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 110px;
    font-weight: 600;
}