.learning-path-main {
   height: 300px;
   background: #fcf2f7;
   width: 100%;
   border-radius: 12px;
   padding: 25px 30px;
   margin-bottom: 60px;
}

.learning-path-modules-outer {
   display: flex;
   height: 300px;
   align-items: center;
   overflow: auto;

   .learning_path__is_faded {
      opacity: 0.5;
   }
}

.lp-display {
   //max-width: fit-content;
}

.lp-image {
   text-align: center;
}

.lp-sub {
}

.lp-sub h4 {
   width: auto;
   font-size: 17px;
}

.lp-sub h4,
h5 {
   font-weight: 700;
}

.lp-sub h4,
h5 {
   text-transform: uppercase;
}

.lp-sub img {
   margin-top: 10px;
   margin-bottom: 10px;
}

.lp-sub ul {
   list-style: none; /* Remove default bullets */
}

.lp-sub ul li::before {
   content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
   color: black; /* Change the color */
   font-weight: bold; /* If you want it to be bold */
   display: inline-block; /* Needed to add space between the bullet and the text */
   width: 1em; /* Also needed for space (tweak if needed) */
   margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.skill-subtitle-mobile {   
   display: none;
}

.learn-tab-mt {
   margin-top: 1rem;
}

// for mobile responsive css
@media screen and (max-width: 480px){ 
   .learn-tab-mt {
      margin-top: 0px !important;
   }
   .learning-path-main {
      height: 248px;
      width: 190px;
      float: left !important;
      padding: 15px 15px;
      margin-bottom: 10px;
   }
   .lp-sub {
      width: 160px;
   }
   .theme-light .card-body {
      border: none; 
      padding: 0px;
   }
   .lp-sub h4 {
      font-size: 14px;
   }
   .learning-path-mobile-view-div {
      width: 51vh;
      overflow: auto;
      display: flex;
   }
   img {
      width: 100%;
   }
   .modules {
      margin-left: 25px;
      align-items: center;
      // display: block  !important;
      height: 280px;
      width: 100%;
   }
   .remv-pad-mobile {
      padding: 0px !important;
   }
   .padding-less-mobile {
      padding-left: 14px !important;
   }
   .page-title-learning-path {
      font-size: 16px !important;
   }
   .skill-title-mobile {
      font-size: 18px;
   }
   .skill-subtitle-mobile {   
      display: block;
      color: #2e485cb5 !important;
   }
   ::-webkit-scrollbar {
      display: none;
   }
}