
.applicant-profile_personal_details{
  text-align: left;
}

.applicant-profile__bio{
  height: auto;
  min-height: 0.5rem;
  margin : 0 25px 0 25px;
  width: 100%
}
