.job-project-list {
   color: #2e485c;
   .job-project-list-item {
      display: flex;
      margin: 25px 0;
      align-items: center;

      .job-basic-icon {
         min-width: 30px;
         height: 30px;
         width: 30px;
         margin-top: 0;
         display: flex;
         align-items: center;
         justify-content: center;
         background-color: #f5f8fd;
      }
      .job-title__mobile {
         font-size: 14px;
         font-weight: 600;
      }
      .job-location__mobile {
         font-size: 11px;
         font-weight: 400;
         color: #7d8488;
      }
      .job-basic-icon__end {
         margin-left: auto;
         color: #d8dadb;
      }
   }
}

.job-details-main__mobile {
   padding-bottom: 80px;
   .title {
      color: #2e485c;
      padding: 10px;
      display: flex;
      align-items: center;
      .page-title {
         margin-left: 8px;
         font-size: 20px;
         font-weight: 600;
         margin-bottom: 0;
      }
   }
   .details {
      .detail-item {
         display: flex;
         margin: 10px 15px;
         padding: 2px 6px;
         .icon-basic {
            color: $color-brand;
            background-color: #f5f8fd;
            border-radius: 50%;
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            margin-top: 6px;
         }

         .basic {
            .title-basic {
               font-size: 15px;
               font-weight: 400;
               color: #7d8488;
            }
            .value-basic {
               font-size: 18px;
               font-weight: 500;
            }
         }
      }
   }
   .secondary-details {
      margin: 20px 20px;
      .title-secondary {
         font-size: 20px;
         font-weight: 500;
      }
      .job-skills-detail-secondary {
         margin-top: 5px;
         margin-bottom: 5px;
         display: flex;
         align-items: center;

         .skill-icon {
            background-color: #fff1dd;
            border-radius: 50%;
            height: 28px;
            width: 28px;
            color: #ff7219;
            display: flex;
            justify-content: center;
            align-items: center;
         }

         .jobs-skills-header-secondary {
            margin-left: 8px;
         }
      }

      .job-desc-p {
         font-size: 16px;
         color: #525a60;
         word-break: break-all;
      }
   }

   .job-apply-save-section__mobile {
      position: fixed;
      bottom: 0;
      height: 80px;
      width: 100%;
      background-color: white;
      .apply-save-btns {
         display: flex;
         justify-content: center;
         align-items: center;
         padding: 20px;

         .save-job-btn {
            width: 43px;
            max-width: 43px !important;
            min-width: 43px !important;
            padding: 0;
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
               height: 28px;
               width: 28px;
               margin: 0;
            }
         }

         .apply-job-btn {
            width: 100%;
         }
      }
   }
}

.mandatory-assessment-alert {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 80%;
   .assessment-required-icon {
      height: 60px;
      width: 60px;
      background: #fbf8f3;
      color: #e07900;
      margin: auto;
      border-radius: 50%;
   }
   .assessment-required-title {
      font-size: 16px;
      font-weight: 600;
   }

   .application-successfull-icon {
      height: 60px;
      width: 60px;
      background: #f3fbf3;
      color: #13ad13;
      margin: auto;
      border-radius: 50%;
   }
}
.mandatory-assessment-actions {
   position: fixed;
   bottom: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px;
   width: 100%;
   button {
      margin: 0;
      width: 100%;
   }
}
