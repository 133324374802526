@import "./components/Profile/Profile.scss";
@import "./components/Profile/PersonalDetails.scss";

//.nav {
//    padding-left: 0 !important;
//}

.my_profile_title {
    margin-left: 2.2rem;
}
