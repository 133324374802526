.nav {
	@include directify($directions) {
		#{directed('padding-left')}: 10px;
	}
}
.alert {
	padding: 20px;
	background-color: #f44336;
	color: white;

	.closebtn {
		margin-left: 15px;
		color: white;
		font-weight: bold;
		float: right;
		font-size: 22px;
		line-height: 20px;
		cursor: pointer;
		transition: 0.3s;
	}

	.closebtn:hover {
		color: black;
	}
}
.tabs {
	overflow: auto;

	.tabs__wrap {
		@include directify($directions) {
			text-align: directed('left');
		}

		min-width: min-content;
		//-webkit-min-width: min-content;
	}

	.nav-tabs {
		@include themify($themes) {
			border-bottom: 2px solid themed('colorBorderContainer');
		}
		//padding: 0 5% !important;

		li {
			.nav-link {
				// padding: 10px 20px;
				margin-right: 25px !important;
				padding: 0 ;
				padding-bottom: 10px !important;

				@include directify($directions) {
					#{directed('margin-right')}: 0px;
				}

				transition: background-color 0.3s;
				cursor: pointer;

				@include themify($themes) {
					color: themed('colorText');
				}

				&:hover,
				&:focus,
				&:active {
					outline: none;

					@include themify($themes) {
						background-color: themed('colorHover');
						border-color: themed('colorHover');
						border-bottom-color: #dddddd;
					}
				}
			}

			.nav-link.active,
			.nav-link.active:focus,
			.nav-link.active:hover {
				background-color: transparent;

				@include themify($themes) {
					border-color: #dddddd;
					border-bottom-color: themed('colorBackground');
					color: themed('colorText');
				}
			}

			&.disabled .nav-link {
				color: $color-additional;
			}
		}
	}

	.tab-pane {
		padding-top: 0px; //20px //5px- modified on 6-5-21:2:30pm
	}

	&.tabs--bordered-top {
		.nav-tabs {
			li .nav-link {
				border-top: 2px solid transparent;
				border-radius: 0;
			}

			li .nav-link.active {
				&,
				&:focus,
				&:hover {
					border-radius: 0;
					border-top: 2px solid $color-accent;
				}
			}
		}
	}

	&.tabs--bordered-bottom {
		.nav-tabs {
			li .nav-link {
				border-color: transparent;
				border-bottom: 1px solid transparent;
				padding-bottom: 11px;
			}

			li .nav-link.active {
				&,
				&:focus,
				&:hover {
					border-radius: 0;
					border-color: transparent;
					border-bottom: 2px solid $color-accent;
					padding-bottom: 10px;
					font-weight: 700;
				}
			}
		}
	}

	&.tabs--justify {
		.nav-tabs {
			display: flex;
			flex-wrap: nowrap;

			li {
				width: 100%;

				a {
					text-align: center;
				}
			}
		}
	}

	&.tabs--vertical {
		overflow-y: auto;

		.tabs__wrap {
			display: flex;
		}

		.nav-tabs {
			border-bottom: none;
			width: 90px;
			height: 100%;
			flex-wrap: wrap;

			.nav-item {
				margin-bottom: 0;
			}

			li {
				margin-top: 0;
				width: 100%;

				.nav-link {
					@include directify($directions) {
						#{directed('padding-right')}: 10px;
						#{directed('padding-left')}: 0px;
						#{directed('margin-right')}: 0px;
						#{directed('border-right')}: 2px solid #dddddd;
					}

					padding-top: 10px;
					padding-bottom: 10px;
					transition: background-color 0.3s;
					border-radius: 0;
					border: none;

					@include themify($themes) {
						color: themed('colorText');
					}

					&:hover,
					&:focus,
					&:active {
						outline: none;

						@include themify($themes) {
							background-color: themed('colorHover');
						}
					}
				}

				.nav-link.active,
				.nav-link.active:focus,
				.nav-link.active:hover {
					background-color: transparent;

					@include directify($directions) {
						#{directed('border-right-color')}: $color-accent;
					}
				}

				&.disabled .nav-link {
					color: $color-additional;
				}
			}
		}

		.tab-pane {
			padding-top: 0;

			@include directify($directions) {
				#{directed('padding-left')}: 20px;
			}
		}

		&.tabs--vertical-colored {
			.nav-tabs {
				li {
					.nav-link {
						@include directify($directions) {
							#{directed('border-right-color')}: #eeeeee;
						}

						@include directify($directions) {
							#{directed('padding-left')}: 10px;
						}

						text-align: center;
					}

					.nav-link.active,
					.nav-link.active:focus,
					.nav-link.active:hover {
						background-color: #eeeeee;

						@include directify($directions) {
							#{directed('border-right-color')}: #eeeeee;
						}

						color: #646777;
					}
				}
			}
		}
	}
}

.tabs__opportunities {
	padding: 0px !important;
}

.recommendations-tabs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@include themify($themes) {
		border: none !important;

		li > a {
			padding: 0px 12px !important;
		}
	}
}

.recommendation-view {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.recommendation-dropdown {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	min-width: 20%;
	margin: 10px 0px 0px 10px;
	width: 100%;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	.recommendation-dropdown {
		margin: unset;
		width: unset;
	}
}

.matches_applicant {
	height: 400px;
	overflow-y: auto;
}

.no-matches {
	justify-content: center;
	align-items: center;
	display: flex;
	width: 100%;
	height: 100%;
	margin: auto;
}


