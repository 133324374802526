.competition_overview_team-profile-main {
  padding: 0px 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;


  .competition_overview_team-profile {
    display: flex;
    .competition_overview_team{
      padding: 0px 12px;

      .competition_overview_team_title {
        color: #2E485C;
        font-weight: bold;
        text-transform: none;
      }

      .competition_overview_team_light_text {
        color: #A3A3B1;
        font-size: 11px;
      }
    }

  }
}


.competition_overview_profile_photo_account{
  background-color: #bdbdbd;
  width: 160px;
  height: 160px;
  border-radius: 5%;
  object-fit: cover;
  text-align: center;
  align-content: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
.competition_overview_profile_photo_accountIcon {
  color: #F5F5F5;
  height: 35px;
  width: 35px;
}


.competition_overview_information{
  .competition_overview_profile_photo{
    img {
      width: 160px;
      height: 160px;
      border-radius: 5%;
      object-fit: cover;
    }
  }
  .competition_overview_team_maintitle{
    font-weight: bold;
    color: #2E485C;
    text-transform: none;
  }

  .competition_overview_team_join{
    font-weight: bold;
    color: #2E485C;
    text-transform: none;
    font-size: 12px;
  }

  .competition_overview_team_join_date{
    color:#2E485C;
    margin-left: 10px;
    font-size: 12px;
  }


  .competition_overview_team_profile_desc{
    color: #2E485C;
    font-size: 12px;

  }



  .competition_overview_submited_title{
    color: #2E485C;
    background-color: #F4F4F4;
    padding: 20px;
    width: 100%;
    font-weight: bold;
    text-transform: none;
  }

  .competition_overview_submited{
    .competition_overview_submited_text {
      font-weight: bold;
      color: #525A60;
      text-transform: none;
    }
    .competition_overview_submited_number{
      font-size: 12px;
      color: #525A60;
    }
    .competition_overview_submited_file_description{
      font-weight: bold;
      color: #2E485C;
      font-size: 14px;
      text-transform: none;
    }

  }

  .competition_overview_submission {
    .competition_overview_submission_text{
      font-weight: bold;
      color: #525A60;
      text-transform: none;
    }

    .competition_overview_submission_desc{
      color: #2E485C;
      font-size: 12px;

    }
  }

  .competition_overview_team_member_main{
    border-bottom: 1px solid #F4F4F4;
    padding: 10px 0px 10px 0px;

    .competition_overview_team_member_num{

      font-size: 12px;
      color: #525A60;
    }
    .competition_overview_team_member_profilephoto {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .competition_overview_team_member_name{
      margin-top: 12px;
      font-size: 14px;
      font-weight: bold;
      text-transform: none;
      color: #2E485C;
    }
    .competition_overview_team_member_job {
      color: #8F9AA0;
      font-size: 12px;
    }
  }

}
.competition_skillasessment_main{
  padding-top: 5px;
  padding-left: 16px;
  .competition_skillasessment_title{
    color: #2e485c;
    font-weight: 600;
    text-transform: none;
  }

  .competition_skillasessment_card_main {
    margin-top: 10px;

    .competition_skillasessment_card {
      background-color: #FAFAFA;
      height: 50px;

      .competition_skillasessment_card_text {
        padding: 15px 0px 0px 42px;
        font-weight: bold;
        text-transform: none;
        font-size: 12px;
        color: #2E485C;
      }

      .competition_skillasessment_card_desc {
        padding: 5px 0px 0px 515px;

        .competition_skillasessment_card_desc_per {
          color: #FCC357;
          font-size: 12px;
        }

        .competition_skillasessment_card_desc_title {
          font-size: 12px;
          color: #525A60;
        }

      }
    }
  }

}


