.perkeso_utilities__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-right: 1.5rem;

    .perkeso__saved_opportunities {
        border: 1px solid black;
        height: fit-content;
        width: fit-content;
        padding-right: 10px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        background: #ffffff;

        .icon {
            color: #000000;
            padding: 8px;
        }
    }

    .header_search_btn {
        width: 8.25rem;
        margin-bottom: 0 !important;
    }

    .filter__container {
        padding: 6px 0;
        margin-left: 60px;
        margin-top: auto;
        margin-bottom: 7px;
        cursor: pointer;
        background: #f5f5f5;
        // height: 45px; //fit-content
        height: fit-content;
        // padding: 6px 12px;
        border-radius: 5px;

        width: 8.25rem;
        text-align: center;
        // padding-top: 10px;
        padding-right: 23px;

        .icon {
            color: #951b1e;
            padding: 6px;
        }
    }
}
