.collapse__wrapper {
	@include directify($directions) {
		text-align: directed('left');
	}

	&.opened,
	&.opening {
		.collapse__title {
			p {
				color: $color-additional;
			}

			svg {
				fill: $color-additional;
			}
		}
	}

	&.boxed {
		border: solid 1px #dddddd;
		margin-top: -1px;

		.collapse__title {
			background-color: #f2f4f7;
			border-bottom: solid 1px #dddddd;
			padding: 8px 15px;

			p {
				color: #444444;
			}

			svg {
				display: none;
			}
		}

		.collapse__content {
			@include directify($directions) {
				#{directed('padding-right')}: 20px;
				#{directed('padding-left')}: 15px;
			}

			padding-top: 16px;
			padding-bottom: 20px;
		}

		&.closed {
			.collapse__title {
				border-bottom: none;
			}
		}
	}

	&.with-shadow {
		margin-bottom: 10px;
		box-shadow: none;

		.collapse__content {
			@include directify($directions) {
				#{directed('padding-right')}: 20px;
				#{directed('padding-left')}: 25px;
			}

			padding-top: 15px;
			padding-bottom: 0px;

			@include themify($themes) {
				color: themed('colorText');
			}
		}

		.collapse__title {
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 20px;
			padding-right: 25px;
			box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
			transition: background-color 0.3s;

			@include themify($themes) {
				color: themed('colorText');
			}

			&:hover {
				@include themify($themes) {
					background-color: themed('colorHover');
				}
			}

			svg {
				display: none;
			}

			p {
				svg {
					display: block;
					top: calc(50% - 7px);
					transition: all 0.3s;

					@include themify($themes) {
						fill: themed('colorText');
					}
				}
			}
		}

		&.opened .collapse__title,
		&.opening .collapse__title {
			p {
				color: inherit;
			}

			svg {
				fill: inherit;
				transform: rotate(180deg);
			}
		}
	}

	&.closing {
		.collapse__content {
			padding-top: 0;
			padding-bottom: 0;
			transition: all 0.3s;
		}
	}
}

.collapse__title,
.collapse__content {
	@include directify($directions) {
		#{directed('padding-left')}: 20px;
		#{directed('padding-right')}: 15px;
	}
}

.collapse__title {
	padding-bottom: 10px;
	padding-top: 6px;
	cursor: pointer;
	position: relative;
	background: transparent;
	display: block;
	width: 100%;
	border: none;

	@include directify($directions) {
		text-align: directed('left');
	}

	p {
		font-weight: 700;
		margin-top: 0;
		transition: all 0.3s;
		position: relative;

		svg {
			@include directify($directions) {
				#{directed('right')}: -10px;
				#{directed('left')}: auto;
			}

			display: none;
		}
	}

	svg {
		position: absolute;

		@include directify($directions) {
			#{directed('left')}: 0px;
		}

		width: 14px;
		height: 14px;
		top: 8px;
		transition: all 0.3s;

		@include themify($themes) {
			fill: themed('colorText');
		}
	}
}

.collapse__content {
	padding-bottom: 14px;
}

.job-basic-detail {
	height: 100%;
	display: flex;
	float: left;
	cursor: pointer;
	padding: 5px 5px;
	//margin: 5px 0;
	position: relative;
	// border-radius: 0;
	// border: none;
	transition: all 0.3s;
	min-height: 70px;
	max-width: 200px;
	min-width: 200px;
}

.job-basic-icon {
	width: 30px;
	height: 28px;
	border-radius: 50% !important;
	border: none;
	border-radius: 3px 0 0 3px;
	cursor: pointer;
	margin: 10px 10px 0 0;

	@include themify($themes) {
		color: $color-brand;
		background-color: themed('colorBackgorundIconSecondary');
	}
}

.jobs-basic-information {
	text-align: left;
	margin-left: 10px;
	margin-top: 10px;
}

.jobs-basic-body {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-weight: 500;
	line-height: 20px;
	font-size: 1rem;
	margin-top: 5px;
}

.jobs-skills-header,
.jobs-basic-header {
	font-weight: 400;
	margin-bottom: 10px;
	margin-top: 0;
	// opacity: 0.6;
	line-height: 18px;

	@include themify($themes) {
		color: themed('colorDarkGrey');
	}
}

.job-basic {
	border: none !important;
	padding: 0.5rem 1.25rem;
}
.job-basic > ul.list-inline {
	display: inline-flex;
	flex-wrap: wrap;
}

.jobs-description {
	@include themify($themes) {
		background-color: themed('colorBackground');
		border: none;
		// border-top: 1px solid rgba(0, 0, 0, 0.125);
		padding-top: 0px;
		// border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
	}
}

.job-icon {
	display: block;
	top: calc(50% - 7px);
	transition: all 0.3s;

	@include themify($themes) {
		fill: themed('borderColor');
	}
}

.job-icon-collapse {
	fill: inherit;
	transform: rotate(180deg);
}

.job__end-collapse-btns {
	position: absolute;
	margin: 0.8rem;
	margin: auto;

	@include directify($directions) {
		#{directed('right')}: 10px;
	}
}

.job-end-button {
	background-color: transparent;
	border: none;
}

.job-desc-row {
	margin: 0px 15px;
}

.job-desc-div-center {
	padding: 1.5rem 0.25rem 1.25rem 0.25rem;

	@include themify($themes) {
		border-top: 1px solid themed('borderColor');
	}
}

.job-desc-div-left {
	padding: 1.5rem 0.25rem 1.25rem 0.25rem;
}

@media only screen and (min-width: 768px) {
	.job-desc-div-left {
		padding: 1.5rem 2.25rem 1.5rem 0.25rem;
	}
}

.job-desc-p {
	line-height: 26px;
	white-space: pre-wrap;
}

.job-skills-detail {
	height: 100%;
	display: flex;
	float: left;
	cursor: pointer;
	position: relative;
	border-radius: 0;
	border: none;
	transition: all 0.3s;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
}

.job-skills-icon {
	width: 28px;
	height: 28px;
	border-radius: 50% !important;
	border: none;
	border-radius: 3px 0 0 3px;
	cursor: pointer;
	margin: auto;
	margin-left: 0px;
	margin-right: 10px;

	@include themify($themes) {
		color: themed('colorOrange');
		background-color: themed('colorOrangeBackground');
	}
}
