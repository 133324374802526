.add_benefits__modal {
	.modal-content {
		border-radius: 10px;

		.modal__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;

			p {
				font-weight: 600;
				font-size: large;
			}
		}

		.modal__form {
			Input {
				margin-bottom: 10px;
			}

			.description__length,
			.heading__length {
				font-size: 14px;
				font-weight: 500;
			}

			.heading__length {
				margin-bottom: 10px;
			}

			.description__full,
			.heading__full {
				color: red;
			}

			.description__not_full,
			.heading__not_full {
				color: green;
			}
		}

		.icons_container {
			margin-top: 20px;

			.benefits_container {
				display: flex;
				padding: 15px;
				justify-content: center;
				align-items: center;

				.icon_container {
					display: flex;

					.icon_img {
						cursor: pointer;
						display: inline-table;
						color: $color-brand;
						padding: 12px;
						border-radius: 50%;
						background-color: #f4f4f4;
					}

					.icon_selected {
						border: 2px solid $color-brand;
					}
				}
			}
		}

		.save__btn {
			display: flex;
			justify-content: flex-end;
			margin-top: 10px;

			p {
				cursor: pointer;
				padding: 5px 10px;
				color: #def1ff;
				background-color: #0092ff;
				border-radius: 5px;
			}
		}
	}
}
