.participants_team_profilephoto_accountGroupIconParentDiv {
  background-color: #bdbdbd;
  padding: 0px 20px 0px 20px;

}
.participants_team_profilephoto_accountGroupIcon {
  color: #F5F5F5;
  height: 50px;
  width: 100%;
}

.participants_team_table{
  .participants_team_number{
    float: left;
    color: #525A60;
    padding-top: 13px;
  }
  .participants_team_profilephoto{
    float: left;
    width: 95px;
    height: 50px;
    padding: 0px 20px 0px 20px;
    img{
      height: 50px;
      width: 100%;
      object-fit: cover;
    }
  }
  .participants_team_name{
    font-weight: bold;
    text-transform: none;
    font-size: 15px;
    color: #2E485C;
    padding-top: 5px;
  }
  .participants_team_member{
    text-transform: none;
    font-size: 12px;
    color: #8F9AA0;
  }
  .participants_team_status_shortlist_dot{
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -2px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: #62B453;
    background-color: #62B453;
    height: 12px;
    width: 12px;
  }
  .participants_team_status_pending_dot{
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -2px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: #ED9A47;
    background-color: #ED9A47;
    height: 12px;
    width: 12px;
  }
  .participants_team_status{
    color: #2E485C;
    font-size: 14px;
  }

}