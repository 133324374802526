.post-details-page {
   height: 80vh;
   display: flex;

   .post-page-icons {
      display: flex;
      justify-content: space-between;
      position: sticky;
      top: 0;
      padding: 10px;
      z-index: 1;

      .back-icon {
         color: black;
      }

      .share-post {
         display: flex;
         height: auto;

         .saved_article_notification {
            margin-top: -9px;
            margin-bottom: -8px;
            margin-right: 10px;
            background: #13ad1321;
            color: #13ad13;
            padding: 10px;
            border-radius: 5px;
         }

         p {
            cursor: pointer;
            margin: auto;
            //background-color: #1493ff;
            //color: white;
            border: none;
            border-radius: 20px;
            padding: 2px 10px;
         }

         .favourite-icon {
            cursor: pointer;
            margin-right: 10px;
            font-weight: 100;
         }
      }
   }

   .page-body {
      padding-top: 20px;
      overflow: scroll;

      -ms-overflow-style: none;
      scrollbar-width: none;

      .body__image {
         display: flex;
         flex-flow: column;
         align-items: center;

         .inner__container {
            height: 400px;
            width: 95%;

            img {
               border-radius: 16px;
               height: 100%;
               width: 100%;
            }
         }
      }

      .body__title {
         margin: 10px 0;
         text-align: left;
         margin: 10px 39px;
      }

      .body__description {
         white-space: break-spaces;
         text-align: justify;
         margin: 10px 39px;
      }
   }

   .page-body::-webkit-scrollbar {
      display: none;
   }
}
.share-icon-mobile-view {
   cursor: pointer;
   margin: auto;
   background-color: #951B1E;
   color: white !important;
   border: none;
   border-radius: 20px;
   padding: 2px 10px;
}