@import "./Duration.scss";
@import "./Rating.scss";
@import "./Difficulty.scss";
@import "./Language.scss";
@import "./SubtitleLanguage.scss";

.perkeso_sliding_panel__component {
    background: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding : 20px;


    .MuiSlider-thumb {
        background-color: #6a1315;
    }
    .MuiSlider-track {
        background-color: #6a1315 ;
    }
    .MuiSlider-rail {
        background-color: #7d2324;
    }
    .MuiSlider-thumb:hover {
         box-shadow: none;
    }
    .MuiSlider-valueLabel {
        left: -20px !important;
        top: -65px !important;
        color: #6a1315;
    }
    .MuiSlider-valueLabel > span {
        width: 3.3rem !important;
        height: 3.3rem !important;
    }



    .upper__component {
        max-height: 30rem;
        min-height: 25rem;
        overflow-y: auto;
        overflow-x: hidden;
        .heading__component {
            display: flex;
            justify-content: space-between;
            align-items: center;
            //padding: 1.7rem 1.2rem;
            padding: 10px 0px;
            color: #a7a7a7;
            width: 18.8rem;

            .icon {
                text-align: end;
                cursor: pointer;
                padding: 0;
                //margin-right: -18px;
            }
        }


        .body__container {
            .MuiFormGroup-root {
                display: flex !important;
                flex-direction: column !important;
            }
            .form-control:focus,
            .form-control:active {
                border-color: #951B1E !important;
            }

            .card {
                .card-body {
                    padding: 3px !important;
                }
                .job-basic {
                    padding:0.5rem 2px !important;
                }
                .job-end-button {
                    padding: 0px
                }
                 .card-header {
                    cursor: pointer;
                    background: none !important;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span {
                        font-weight: 700;
                    }

                    .job__end-collapse-btns {
                        display: flex;
                        align-items: center;
                        position:relative !important;
                        margin: 0;
                    }
                }

                .jobs-description {
                    background: none !important;
                }
            }
        }
    }

    .footer__component {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
        //padding-bottom: 1rem;
        //padding-right: 2rem;
        font-weight: 700;

        .clear_all__container,
        .apply_filters__container {
            cursor: pointer;
            padding: 0.5rem 1rem;
        }

        .clear_all__container {
            color: $color-brand;
        }
        .apply_filters__container {
            background: #951B1E;
            color: #ffffff;
            border-radius: 5px;
        }
    }
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #951B1E !important;
}
.sliding-panel-container {
    position: fixed;
    top: 0;
    left: 0;
}

.sliding-panel-container.active {
    position: fixed;
    z-index: 15000;
    background-color: rgba(0,0,0,.3);
    will-change: transform;
}

.currencyBoxes {
    justify-content: space-between;
    width: 100%;
    display: flex;
    .MuiOutlinedInput-input {
        padding:10px !important;
    }
    .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(14px, 12px) scale(01);
    }

}
