.fileupload-dropzone {
    .dropzone {
        flex: 1;
        width:16rem;
        display: flex;
        //flex-direction: column;
        align-items: center;
        justify-content: left;
        padding: 14px;
        border-width: 2px;
        border-radius: 5px;
        border-color: #D3D3D3;
        border-style: dashed;
        //background-color: #fefefe;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
    }

    .dropzone-smart-resume {
        flex: 1;
        width:16rem;
        display: flex;
        cursor:pointer;
        //flex-direction: column;
        align-items: center;
        justify-content: left;
        padding: 14px;
        border-width: 2px;
        border-radius: 5px;
        border-color: #D3D3D3;
        border-style: solid;
        //background-color: #fefefe;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
    }

    .file-upload-icon {
        float:left;
        margin-right: 10px;
    }
    .file-upload-text {
        float:right;
    }
    .browse-resume-title {
        color: #951B1E;
        font-weight: 700;
        font-size:12px;
        margin: 0px 0px 0px 0px;
    }
    .drop-it-here-text {
        color:#8F9AA0;
        margin: 0px 0px 0px 0px;
        font-size:10px;
    }
    .browse-resume-smart-title {
        color:#2E485C;
        font-weight: 700;
        font-size:12px;
        margin: 0px 0px 0px 0px;
    }
    .drop-it-here-smart-text {
        color:#8F9AA0;
        font-size:10px;
        margin: 0px 0px 0px 0px;
    }
    .browse-resume-uploaded-title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 90%;
        font-size:12px;
        overflow-y: hidden;
        color:#2E485C;
        font-weight: 700;
        margin: 0px 0px 0px 0px;
    }
    .pdf-icon {
        height: 40px;
        width: 40px;
        color: #951B1E;
        float: left;
    }
     
    .uploaded-resume-div:hover{
        .uploaded-resume-remove-icon {
            visibility: visible;
        }
    }
    .uploaded-resume-remove-icon {
        float: right;
        width: 24px;
        height: 24px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 9px;
        color: initial;
        cursor: pointer;
        visibility: hidden;
        margin-top: -15px;
        
        a {
            text-decoration: none;
            color: #2e485c !important ;
        }
        @include themify($themes) {
           background: themed('colorLightBlue');
           color: themed('colorText');
        }
    }
}

.dropzoneWhole {
    box-sizing: border-box;
    display: none;
    position: fixed;
    justify-content: center;
    align-items:center;
    text-align: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;

    background: rgba(#951B1E,.8);
    border: 11px dashed #951B1E;
}