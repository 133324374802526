.compare-employees-profile-row {
  .compare-employees-profile-cell-title {
    border-bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 25px;
  }

  .compare-employees-profile-cell {
    border-bottom: 0;
    display: flex;
    align-items: center;
    position: relative;
    height: 100px;
    .profile-photo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      min-width: 40px;
      margin: 25px 10px 25px 10px;
      z-index: 1;

      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .info-container {
      .name {
        font-weight: bold;
        font-size: 14px;
        text-transform: none;
      }

      .location {
        font-weight: bold;
        font-size: 12px;
        text-transform: none;
      }
    }

    .Internal {
      background: #13ad1333;
      color: #026a02;
    }

    .External {
      background: #faecd1;
      color: #e6ae43;
    }

    .scope {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      margin-top: 5px;
      margin-right: 20px;
      border-radius: 5px;
    }

    .compare-employees-profile-action-buttons {

      .icon {
        opacity: 0;
      }
    }
  }

  .compare-employees-profile-cell:hover {
    .compare-employees-profile-action-buttons {
      color: white;
      position: absolute;
      height: -webkit-fill-available;
      width: -webkit-fill-available;
      left: 0;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.3s ease-out;
      -webkit-transition: background-color 0.3s ease-out;

      .icon {
        opacity: 1;
        padding: 10px;
        cursor: pointer;
        border-radius: 50%;
        margin: 0 5px;
        background-color: white;
        color: #2e485c;
        transition: opacity 0.3s ease-out;
        -webkit-transition: opacity 0.3s ease-out;
      }
    }
  }
}
