.rc-slider {
    margin-top: 1rem;
}

.rc-slider-track,
.rc-slider-rail {
    height: 3px !important;
}

.rc-slider-track {
    background: #1f89f4 !important;
}

.rc-slider-handle {
    margin-top: -8px !important;
    background: #1f89f4 !important;
}
