.posts-page-container {
  margin: 0 35px;
  margin-bottom: 35px;
  .insights {
    max-width: 100%;
  }
  p {
    margin: 0;
  }
  h5, h6 {
    text-transform: none;
  }

  .posts-heading {
    font-weight: 700;
    font-size: x-large;
    color: #4e6174;
    margin-top: 100px;
  }

  .post-video-container,
  .post-image-container {
    margin-bottom: 40px;

    h5 {
      font-size: 18px;
      line-height: 1.1;
      margin-bottom: 5px;
    }
  }

  .post-video {
    height: 300px;
    border-radius: 15px;
  }

  .post-link {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    margin-right: 180px;
    width: 100%;
    margin: 10px;
    .post-image {
      width: 100px;
      height: 100px;

      img {
        height: 100px;
        width: 100px;
        border: none;
        border-radius: 10px;
      }
    }

    h5 {
      font-size: 20px;
      margin-bottom: 10px;
      text-transform: none;
    }
  }

  .links-data {
    padding-left: 25px;
    height: 80px;
    overflow: hidden;

    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .insight-common-img {
    border-radius: 10px;
  }

  .object-fit{
    object-fit: contain;
  }

  .post__description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    margin: 0 0 1em 0;
    overflow: hidden;
    margin: 0;
    font-size: 14px;
    line-height: 1.1;
    height: 31px;
  }
}

@media (max-width: 768px) {
  .posts-page-container {
    margin: 0 0 30px 0;

    .posts-heading {
      margin-top: 30px;
      font-size: 20px;
      color: #2e4866;
    }


    .post-link {
      margin: 10px 15px;
      align-items: initial;

      .links-data {
        height: auto;
      }
    }
  }
}
