.messages-send-message-main__div {
   position: fixed; //static
   right: 0;
   left: 1%;
}

.messages-send-message__div {
   display: flex;
   width: 100vw;
   height: 4vw;
}

.messages-main-row {
   //padding: 0 15px;
   //padding: 0 40px;
}

.input-container {
   width: 250px;
   border: 1px solid #a9a9a9;
   display: inline-block;
}
.input-container input:focus,
.input-container input:active {
   outline: none;
}
.input-container input {
   width: 80%;
   border: none;
}

.input-container button {
   float: right;
}

.send-message_modal_rounded {
   .modal-content {
      border-radius: 5px;
   }
}

.send-message_modal_header {
   color: #2e485c;
   .modal-title {
      text-transform: none;
   }
}

.send-message_modal_para {
   color: #2e485c;
   font-size: 15px;
   font-weight: 600;
}
