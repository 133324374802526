@import "./compare-employees-smart-skills-show-more.scss";

.compare-employees-smart-skills-row {
  .compare-employees-smart-skills-cell-title {
    .colors {
      display: flex;
      justify-content: center;
      align-items: center;

      .blue,
      .orange,
      .pink,
      .green {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin: 8px 5px;
        cursor: pointer;
      }

      .active {
        height: 35px;
        width: 35px;
      }

      .blue {
        background: #50bdec;
      }

      .activeBlue {
        border: 4px solid
          darken(
            $color: (
              #50bdec
            ),
            $amount: 20%
          );
      }

      .orange {
        background: #ea7f2f;
      }

      .activeOrange {
        border: 4px solid
          darken(
            $color: (
              #ea7f2f
            ),
            $amount: 20%
          );
      }

      .pink {
        background: #e55589;
      }

      .activePink {
        border: 4px solid
          darken(
            $color: (
              #e55589
            ),
            $amount: 20%
          );
      }

      .green {
        background: #61b375;
      }

      .activeGreen {
        border: 4px solid
          darken(
            $color: (
              #61b375
            ),
            $amount: 20%
          );
      }
    }
  }

  .blueData {
    max-height: 40rem;
    overflow-y: auto;
    width: 100%;
    color: #50bdec;
    padding: 10px 10px;
  }

  .score-container-blue {
    background: #50bdec;
    border: 6px solid
      darken(
        $color: (
          #50bdec
        ),
        $amount: 20%
      );
  }

  .status-blue {
    color: darken(
      $color: (
        #50bdec
      ),
      $amount: 20%
    );
  }

  .pinkData {
    max-height: 40rem;
    overflow-y: auto;
    width: 100%;
    color: #e55589;
    padding: 10px 10px;
  }

  .score-container-pink {
    background: #e55589;
    border: 6px solid
      darken(
        $color: (
          #e55589
        ),
        $amount: 20%
      );
  }

  .status-pink {
    color: darken(
      $color: (
        #e55589
      ),
      $amount: 20%
    );
  }

  .greenData {
    max-height: 40rem;
    overflow-y: auto;
    width: 100%;
    color: #61b375;
    padding: 10px 10px;
  }

  .score-container-green {
    background: #61b375;
    border: 6px solid
      darken(
        $color: (
          #61b375
        ),
        $amount: 20%
      );
  }

  .status-green {
    color: darken(
      $color: (
        #61b375
      ),
      $amount: 20%
    );
  }

  .orangeData {
    max-height: 40rem;
    overflow-y: auto;
    width: 100%;
    color: #ea7f2f;
    padding: 10px 10px;
  }

  .score-container-orange {
    background: #ea7f2f;
    border: 6px solid
      darken(
        $color: (
          #ea7f2f
        ),
        $amount: 20%
      );
  }

  .status {
    font-weight: bold;
    font-size: 12px;
    text-transform: none;
  }

  .status-orange {
    color: darken(
      $color: (
        #ea7f2f
      ),
      $amount: 20%
    );
  }

  .compare-employees-smart-skills-cell {
    .ss-high {
      height: 110px;
      width: 110px;
    }

    .ss-medium {
      height: 100px;
      width: 100px;
    }

    .ss-low {
      height: 90px;
      width: 90px;
    }

    .smart-skills-main-skill-component {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
     background: #f7f7f7;
      height: 260px;

      .score-container {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        margin-bottom: 40px;

        .score {
          color: #fff;
          font-weight: 700;
          font-size: 20px;
        }
      }

      .name {
        font-weight: bold;
        font-size: 14px;
        text-transform: none;
        width: 140px;
        text-align: center;
      }
    }

    .smart-skills-other-skills {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;

      .smart-skills-other-skill {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin: 0 12px;

        .score-container {
          border-radius: 50%;
          display: flex;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          flex-flow: column;
          border: 0 !important;

          .score {
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            text-transform: none;
          }
        }

        .name {
          text-align: center;
          font-weight: bold;
          text-transform: none;
          margin-top: 10px;
          font-size: 12px;
        }
      }
    }
  }
}
