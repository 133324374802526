

.start-end-date{
    .MuiInputBase-input{
      cursor: pointer;
    }
    .MuiOutlinedInput-input {
        padding: 10px 14px;
    }
}

.exp_org_avatar-no-img {

    display: flex;
    margin: auto;
    margin-right: 0;
    margin-left: 0;

    label {
        display: flex;
    }
    /* margin: auto 0; */
    border-radius: 50%;
    height: 75px;
    width: 75px;
    color: $color-blue;

}

.individual-exp-name{
    font-weight: 500;
    font-size: 16px;
    color: #2e485c;
    @include themify($themes) {
        color: themed("colorDarkgrey");
    }
}


.individual-exp-loc-org{
    font-weight: 300;
    font-size: 13px;
    color: #2e485c;
    @include themify($themes) {
        color: themed("colorDarkgrey");
    }
}

.individual-exp-desc{
    font-weight: 400;
    font-size: 13.5px;
    color: #2e485c;
    white-space: pre-wrap;
    flex-wrap: wrap;
    @include themify($themes) {
        color: themed("colorDarkgrey");
    }
    border-bottom: 1px solid #d8d2d2;
    padding-bottom: 25px;
}

@media (max-width: 768px) {
    .individual-exp-desc{
        //border: none;
        margin-left: 35px;
    }
}

.hide {
    visibility: hidden;
}
.on-hover-show-hide:hover .hide {
    visibility: visible;
}

.orgprofile__avatar {
    .orgprofile__avatar-edit-icon{
        visibility: hidden;
    }
}

.orgprofile__avatar:hover {
    .orgprofile__avatar-edit-icon{
        visibility: visible;
    }
}

.individual-exp-list{
  cursor : pointer;
    margin-top: 15px;
}

.exp-modal-header{
    font-size: 17.5px;
    text-transform: none;
    font-weight: 600;
    color: #2e485c;
    @include themify($themes) {
        color: themed("colorDarkgrey");
    }
}

.experience-company__no-img-icon {
    padding: 12px;
    display: -webkit-inline-block;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    color: grey;
    border-radius: 12%;
    height: 80px ;
    width: 80px;
}

.experience-company__img {
    height: 80px;
    width: 80px;
}

.experience-main-col {
    padding: 0 55px;
}
@media (max-width: 768px) {

    .experience-main-col {
        padding: 0;
    }
}
@media (max-width: 768px) {
    .employee-certifications__title div.float-right {
        padding-top: 12px;
        cursor: pointer;
        display: flex;
        margin-left: auto;
        margin-right: 15px;
    }
    .experience-company__no-img-icon {
        margin-left: 15px;
    }

}

.save-btn-padding {
    padding-left: 56px;
}
@media screen and (max-width: 480px){
    .experience-modal-height {
        overflow-y:scroll;
        max-height:90vh;
    }
    .save-btn-padding {
        padding: 0px;
        text-align: end;
    }
}