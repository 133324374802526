.perkeso_language__body {
    padding-bottom: 0;

    .list__body {
        overflow: scroll;
        height: fit-content;
        max-height: 17vh;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding-left: 5px;

        .PrivateSwitchBase-root-3 {
            padding: 3px;
        }
    }

    .list__body::-webkit-scrollbar {
        display: none;
    }

    .language_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
