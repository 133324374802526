.compare-employees-experience-row {
  .compare-employees-experience-cell {
    padding: 15px;
    .total-experience-title,
    .total-experience-company {
      margin-bottom: 25px;
    }

    .total-experience-title {
      font-weight: 600;
    }

    .title,
    .position {
      font-size: 14px;
      font-weight: bold;
      text-transform: none;
    }
    .duration{
      font-size: 12px;
      font-weight: bold;
      text-transform: none;
    }

    .total-experience-company {
      .position,
      .name,
      .time {
        margin-bottom: 5px;
      }

      .name {
        font-weight: bold;
        font-size: 12px;
      }

      .time {
        color: #8f9aa0;
        font-size: 12px;
      }
    }
  }
}
