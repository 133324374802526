.compare-employees-certifications-row {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .compare-employees-certifications-cell {
    //display: flex;
    //flex-flow: wrap;
    padding-top: 20px !important;
    width: 335px;
    max-width: 335px;

    .certificate {
      margin: 6px 10px 6px 27px;
      align-items: initial;
      text-align: start;
      width: 120px;
      float: left;

      img {
        height: 120px;
        width: 120px;
        cursor: pointer;
      }
    }
  }
}

.compare-employees-cell-fixed-column{
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  min-width: 335px;
}

.compare-employees-cell-fixed-column1{
  min-width: 155px;
  max-width: 115px;
}

