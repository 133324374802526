.container {

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.container__wrap {
  //padding-left: 0;
  //padding-top: 90px;
  min-height: 100vh;

  //transition: padding-left 0.3s;
  padding: 85px 0 0 ; //90px 30px 30px 30px
  &.public {
    //padding: 100px 35px 0 ;
  }
  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    //@include directify($directions) {
    //  #{directed('padding-left')}: 250px;
    //}
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      @include directify($directions) {
        #{directed('padding-left')}: 0px;
      }

      @media screen and (min-width: 576px) {
        //@include directify($directions) {
        //  #{directed('padding-left')}: 60px;
        //}
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        @include directify($directions) {
          #{directed('padding-left')}: 0px;
        }
      }
    }
  }
}
