.perkso__office_location {
    select {
        width: 100%;
        height: 40px;
        border-color: #d8dadb;
        background: white;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 5px;
        color: #525a60;
    }

    select:focus-visible,
    select:focus {
        border-color: #d8dadb;
    }
}
