.heatmap-tooltip-container{
  border-radius: 12px;
  box-shadow: #3ddb42;
  width: 450px;
  padding: 15px;
  height: auto;
}

.heatmap-tooltip-title {
  display: flex;
}

.heatmap-tooltip-title-department {
  font-weight: 600;
  font-size: 16px;
  color: #2E485C;
}

.heatmap-tooltip-title-label {
  padding: 4px;
  background-color: #D8DADB;
  border-radius: 16px;
  margin-right: 6px;
}

.heatmap-tooltip-title-score {
  padding: 4px;
  border-radius: 16px;
  margin-right: 6px;
  background-color: #3ddb42;
}

.heatmap-tooltip-username {
  font-weight: 600;
  margin-bottom: 4px;
}
.heatmap-tooltip-subtitle {
  font-weight: 300;
}
.heatmap-tooltip-jobtitle {
  font-weight: 300;
}

.heatmap-tooltip-score {
  padding: 4px;
  border-radius: 22px;
  height: 40px;
  margin-right: 6px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
}
