.course-component {
  cursor: pointer;
  border: 1px solid #bcc8d3;
  border-radius: 15px;
  // padding: 10px;
  margin: auto;
  min-height: 130px;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.courseNotStarted {
  height: 130px;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center
}

.courseNotStarted:hover .courseName {
  display: none;
}

.courseNotStarted:hover:before {
  content: 'Learn more';
  padding-top: 50px;
  font-size: 14px;
  font-weight: bold;

  background-color: #1D3B55;
  color: #ffffff;
  cursor: pointer;
  border-radius: 15px;
  margin: auto;
  height: 130px;
  width: 130px;
  display: block;
  flex-direction: column;
  justify-content: flex-end;
  align-content: center
}

.course_failed {
  color: #D34040;
}

.course_passed {
  color: #29b11d;
}

.course_progress {
  color: #E6A526;
}

.course_list_component {

  .body__container {
    cursor: pointer;
    border: 1px solid #bcc8d3;
    border-radius: 15px;
    padding: 1rem;
    height: 150px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .heading {
      text-align: center;
      margin-bottom: 10px;
    }

    .complete_status {
      text-align: center;
    }
  }
}

.course_progress_wrap {
  margin: 0 0 13px 0;
}

.course_progress_container {
  padding: 0 14px;
}

.course_progress {
  height: 4px !important;
}

.course_status {
  align-content: center;
  text-align: center;
  width: 150px;
  display: inline-flex;
  margin-top: 10px;
  margin-left: 25px;
}

.course_title {
  padding: 0 1px;
  margin: auto;
}

.course_logo{
  position: relative;
  top: 25px;
  left: 30px;
}
.course_name{
  height: 40px;
}
