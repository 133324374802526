.competition-details-main {
  background: #fafafa;
  .competition {
    display: flex;
    // background-color: #fafafa;

    .competition-detail {
      margin-left: 30px;
      width: 100%;
      max-width: 70%;
      min-height: 310px;

      .ca-qst {
        margin-top: 15px;
        font-size: 35px;
        font-weight: bold;
        text-transform: none;
        color: #2E485C;
      }

      .competition-profile {
        // margin-bottom: 30px;
        margin-top: auto;

        .competition-profile-pic {
          border-radius: 50%;
          height: 35px;
          width: 35px;
        }

        .competition-profile-name {
          height: 30px;
          font-weight: bold;
          text-transform: none;
          color: #2E485C;
          font-size: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 0 0 8px;
        }
      }
      .competition-dec{
        font-size: small;
        marging-top: 100px;
        margin-top: 25px;
        margin-bottom: 40px;
        min-height: 90px;
        overflow: hidden;
        color: #2E485C;
      }

      .competition_timing_name {
        font-size: 14px;
        font-weight: bold;
        text-transform: none;
        color: #2E485C;

      }
      .competition_timing{
        font-size: 13px;
        color: #2E485C;
        padding-left: 22px;
      }

      .competition_participant_member{
        font-size: 13px;
        color: #2E485C;
      }
      .competition_timing_month{
        font-size: 12px;
        color: #8F9AA0;
        padding-left: 22px;
      }


    }

    .timing-count-text{
      font-size: 12px;
      font-weight: bold;
      color: #1A222F;
      text-transform: none;
      justify-content: center;
      align-content: center;
      display: flex;
    }
    .contest-ending {
      width: 24rem;
      text-align: initial;
    }

    .countdown_button{
      width: 380px;
      height: 59px;
      border-radius: 10px;
    }
    .disabled_countdown_button{
      background-color: #D8DADB;
      border-color: #D8DADB;
      font-weight: bold;
      font-size: 15px;
    }
    .disabled_countdown_button:before {
      background-color: #D8DADB;
    }
    .completed_countdown_button {
      background-color: #F4F4F4;
      border-color: #F4F4F4;
      font-weight: bold;
      color: #989898;
      font-size: 15px;
    }
    .completed_countdown_button:before {
      background-color: #F4F4F4;
    }

    .countdown-container{
      display: flex;
      text-align: center;
      flex-wrap: wrap;
      width: auto;
      align-content: center;
      justify-content: center;
    }

    .big-text {
      color: #2E485C;
      font-size: 68px;
      // text-align: center;
      height: 85px;
      width: 95px;
    }

    .interview_big-text{
    color: #2E485C;
    font-size: 40px;
    height: 50px;
    width: 55px;}

    .big-text-large-width {
      color: #2E485C;
      font-size: 68px;
      height: 85px;
      width: 130px !important;
      text-align: start !important;
    }

    .countdown-el {
      margin-right: 5px;
      text-align: center;
      width: auto;
      height: 119px;
    }

    .interview_countdown-el{
    background: #fafafa;
    height: 75px !important;}

    .countdown-el span {
      text-align: center;
      color: #2E485C;
      padding: 15px;
    }
    .button-hw{
      width: 25rem;
      height: 45px;
    }

  }
}


.mycompetition_list{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .mycompetition_header {
    width: 100%;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 15px;
  }
  .mycompetition_title{
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0.32px;
    color: #525A60;
    opacity: 1;

  }
}

.qacard_text{
  color: #2E485C;
}

.qacard_textbold{
  color: #525A60;
  font-weight: 600;
}
.qandACardBig{
  height: auto;
  padding-bottom: 0px;
  text-align: left;
  text-align: left;
    .qandACounts {
      font-size: 13px;
      color: grey;
      margin-left: auto;
    }
    .like-follow {
      color: #8e8e8e;
      margin-top: 12px;

      span {
        margin-right: 10px;
      }
      .card-icon {


        &.selected {
          color: $color-brand
        }
      }
    }
}


.tp{
  padding: 0 !important;
}

