.skills-gap-icon {
  background-image: url("../../shared/img/individual-dashboard/skills-gap-icon.svg");
  height: 45px;
  width: 46px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.learning-path-icon {
  background-image: url("../../shared/img/individual-dashboard/learning-path-icon.svg");
  height: 35px;
  width: 56px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.module-icon {
  background-image: url("../../shared/img/individual-dashboard/module-icon.svg");
  height: 110px;
  width: 110px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  display: inline-block;
}
