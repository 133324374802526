@import "./compare-employees-add-profile-modal";
@import "./compare-employees-certifications.scss";
@import "./compare-employees-data-skills.scss";
@import "./compare-employees-experience.scss";
@import "./compare-employees-profile.scss";
@import "./compare-employees-smart-skills.scss";

.compare-employees-overview {
  display: grid;
  grid-template-columns: 10% 90%;
  padding: 10px;
  color: #2e485c;
  height: auto;
  overflow: hidden;

  .compare-employees-add-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 102px);
    color: #951B1E;
    font-weight: 700;

    .compare-employees-add-profile {
      cursor: pointer;
    }
  }

  .compare-employees-data {
    height: max-content;
    width: fit-content;
    .compare-employees-parent {
      display: flex;
      word-break: break-word;
      color: #2e485c;
      overflow-y : hidden;

      .compare-employees-cell-title {
        font-weight: bold;
        padding:10px;
      }
    }
  }

  .compare-employees-data::-webkit-scrollbar {
    display: none;
  }
}
.compare_employee_border{
  border-left: 1px solid #e0dcdc;
}

.compare-employees-data .MuiTableCell-root{
  border-bottom: none !important;
  padding: 0px !important;
}
.compare-employees-data .MuiTableRow-root{
  border-bottom: 1px solid #e0dcdc;
}
.compare-employees-data .MuiTableHead-root{
  border-bottom: 1px solid #e0dcdc;
}

.MuiTableBody-root{
  background-color: white;
}
.MuiContainer-root{
  border: 1px solid #e0dcdc;
  padding: 0px !important;

}
