.saved-articles-page {
	margin-top: 30px;

	.no-news {
		display: flex;
		justify-content: center;
	}

	.saved-article {
		display: flex;
		margin-bottom: 30px;
		align-items: center;
		margin-right: 180px;

		.post-image {
			width: 100px;
			height: 100px;

			img {
				height: 100px;
				width: 100px;
				border: none;
				border-radius: 10px;
			}
		}

		.post-details {
			padding-left: 25px;
			height: 90px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;

			h5 {
				height: 36px;
			}
		}
	}

	.load-more {
		margin: auto;
		width: fit-content;
		background-color: #1493ff;
		text-align: center;
		cursor: pointer;
		border-radius: 6px;

		button {
			border: none;
			background-color: transparent;
			font-weight: 500;
			color: white;
			width: 100%;
			padding: 10px 30px;
		}
	}
}

@media screen and (max-width: 480px){ 
	.savedData {
		.profile__card .nav-tabs .nav-item .nav-link{
			padding: 0px 10px 10px 10px !important;
			margin-right: 5px !important;
		}
	}
	.post-details {
		padding-left: 0px !important;
	}
	.pl-savedArticle-title {
		padding-left: 15px;
	}
}