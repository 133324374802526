.invite-member-row {
    align-items: center;
    border-bottom:1px solid #D8DADB;
    cursor: pointer;
    padding: 1.5rem 3rem;
    .person-name {
        color: #525A60;
        font-weight: 600;
        font-size: 14px !important;
    }
    .profile-pic {
        height: 55px !important;
        width: 55px !important;
    }
}
.check-circle-icon {
    height: 20px;
    width: 20px;
    color: #13AD13;
    padding: 2px;
    border-radius: 50%;
    background-color: #13ad132e;
}
.activeMember {
    background-color: #FAFAFA;
}
.font-color-class {
    color:#2E485C
}
.member-details {
    
    padding: 30px;

    .profile-pic {
        height:80px;
        width:80px;
        border-radius: 50%;
    }
    .person-name {
        color:#525A60;
        font-weight: 600;
        font-size: large
    }
    .grey-small-text {
        color:#525A608c;
        font-weight: 400;
        font-size: 11px;
    }
    .skill-component__skill-name {
        padding: 5px 15px 5px 15px !important;
    }
    .invite-btn {
        float: right;
        width: 70%;
    }
    .skill-level-component {
        display: flex;
        min-height: 30px;
        margin: 10px 0 0 20px;
        background: #f5f5f5;
        border-radius: 50px;
        height: auto;
        width: fit-content;
    }
    .skill-level-component-verified {
        display: flex;
        min-height: 30px;
        margin: 10px 0 0 20px;
        background: #E8FDF1;
        border-radius: 50px;
        height: auto;
        width: fit-content;
    }
    .invite-team-skill {
        display: flex;
    }
    .skill-match-color {
        color: #13AD13;
        text-align: right;
        font-weight: 600;
    }
    .skill-claimed-badge {
        background-color: #f5f5f5;
        height: 25px;
        width: 25px;
        background-repeat: no-repeat;
        background-size: contain;
        margin: auto 0 auto 4px;
        border-radius: 50%;
        font-size: 14px;
        text-align: center;
        line-height: 26px;
    }
    .skill-certified-badge {
        height: 25px !important;
        width: 25px !important;
    }
    .skill-completed-badge {
        height: 25px !important;
        width: 25px !important;
    }
    .view_button {
        align-items: center;
        font-weight: 700;
        cursor: pointer;
        background: white;
        justify-content: center;
        text-align: center;
        color: #2E485C;
    }
    .view-btn-center {
        align-content: center;
        flex-direction: column;
        display: flex;
        justify-content: center;
    }
    .individual__skills_list_close {
        height: 130px;
        overflow:hidden;
    }
    .individual__skills_list_open {
        height: auto;
    }
}