.modules {
  //margin: 3rem 0;
  margin-left: 25px;
  align-items: center;
  display: flex;
}

.module {
  height: 160px;
  width: 115px;
  text-align: center;
  display: inline-block;
  margin-right: 27px;
}

.module h6 {
  font-weight: 600;
  margin-bottom: 12px;
}

.module-connector {
  height: 1px;
  border: 1px solid #D8DADB;
  width: 15px;
  display: inline-block
}

.current-module {
  position: relative;
  display: inline-block;
  width: 183px;
  height: 250px;
  background: #F4F5F7;
  text-align: center;
  align-items: center;
  border-radius: 17px;
  margin-right: 22px;
  margin-bottom: 10px;
}

.current-module h4 {
  font-weight: 600;
  font-size: initial;
  margin: 15px 6px;
}

.module-status-pill {
  margin-top: 10px;
  background: #F5F5F5;
  border-radius: 15px;
  font-size: 10px;
  height: 16px;
  width: 100%;
}

// for mobile responsive css
@media screen and (max-width: 480px){ 
  .module {
    width: 115px !important;
  }
}