.popoverwidth .MuiPopover-paper{
  padding: 12px;
  height: 460px;
  width: 350px !important;
  margin: -60px -110px;
  border-radius: 10px;
}

.popOverFont .MuiTypography-body1{
    font-size: 12px !important;
    color: #5D5D5D;
}

.popover_title_main{
  display: flex;
  padding-bottom: 5px
}


.popover_main_all_checkboxes_icon{
  margin-left: 6px;
}

.popover__basic-information {
   text-align: left;
   margin-top: 8px;
 }

.popover__basic-icon {
  width: 30px;
  height: 28px;
  border-radius: 50% !important;
  border: none;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
  margin: auto;
  margin-left: 0;
  margin-right: 10px;
  padding-left: 5px;

  @include themify($themes) {
    color: themed('colorLinkSecondary');
    background-color: themed('colorBackgorundIconSecondary');
  }
}

.popover__basic-header {
  display: flex;
  font-weight: 400;
  margin-top: 0;
  line-height: 18px;
  align-items: center;
}

.popover__basic-header-title {
  font-weight: bold;
  color: #2E485C;
}


.onboarding_checkbok svg{
    color: #23C782 ;
}

.onboarding_checkbok_progress__label-bar_text {
  color: #2E485C;
  font-size: 12px;
  bottom: 16px;
  margin-left: 100px;

}

body{
  font-family: 'Open Sans Condensed', sans-serif;
}

h1 {
  color: #23C782;
  text-align: center; }

.progress-circle {
  position: relative;
  display: inline-block;
  margin: 1rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ebebeb; }
//
//.progress-circle:hover {
//  -ms-transform: scale(1.1); /* IE 9 */
//  -webkit-transform: scale(1.1); /* Safari 3-8 */
//  transform: scale(1.1);
//}



.progress-circle:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: colorload 2s;
  animation: colorload 2s; }

.progress-circle span {
  font-size: 12px;
  color: #8b8b8b;
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  text-align: center;
  border-radius: 50%;
  background: #f5f5f5;
  z-index: 1;
  width: 44px;
  height: 45px;
  line-height: 43px;
  margin-left: -22px;
  margin-top: -23px;

}

.floating_action_onboarding_button{
  position: fixed;
  bottom: 65px;
  right: 5px
}

.floating_action_onboarding_background{
  width: 35px;
  height: 60px;
  border-radius: 50% !important;
  bottom: 23px;
  right: 6px;
  background-color: #f5f5f5 !important;
}

.floating_action_onboarding_background:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}



.progress-circle span:after {
  content: "%";
  font-weight: 600;
  color: #8b8b8b; }

.progress-circle.progress-0:after {
  background-image: linear-gradient(90deg, #fff 50%, transparent 50%, transparent), linear-gradient(90deg, #23C782 50%, #fff 50%, #fff); }



.progress-circle.progress-10:after {
  background-image: linear-gradient(90deg, #fff 50%, transparent 50%, transparent), linear-gradient(126deg, #23C782 50%, #fff 50%, #fff); }



.progress-circle.progress-20:after {
  background-image: linear-gradient(90deg, #fff 50%, transparent 50%, transparent), linear-gradient(151.2deg, #23C782 50%, #fff 50%, #fff); }



.progress-circle.progress-30:after {
  background-image: linear-gradient(90deg, #fff 50%, transparent 50%, transparent), linear-gradient(198deg, #23C782 50%, #fff 50%, #fff); }



.progress-circle.progress-40:after {
  background-image: linear-gradient(90deg, #fff 50%, transparent 50%, transparent), linear-gradient(234deg, #23C782 50%, #fff 50%, #fff); }




.progress-circle.progress-50:after {
  background-image: linear-gradient(-90deg, #23C782 50%, transparent 50%, transparent), linear-gradient(270deg, #23C782 50%, #fff 50%, #fff); }



.progress-circle.progress-60:after {
  background-image: linear-gradient(-54deg, #23C782 50%, transparent 50%, transparent), linear-gradient(270deg, #23C782 50%, #fff 50%, #fff); }

.progress-circle.progress-70:after {
  background-image: linear-gradient(-18deg, #23C782 50%, transparent 50%, transparent), linear-gradient(270deg, #23C782 50%, #fff 50%, #fff); }



.progress-circle.progress-80:after {
  background-image: linear-gradient(18deg, #23C782 50%, transparent 50%, transparent), linear-gradient(270deg,#23C782 50%, #ffffff 50%, #ffffff); }





.progress-circle.progress-90:after {
  background-image: linear-gradient(54deg, #23C782 50%, transparent 50%, transparent), linear-gradient(270deg, #23C782 50%, #ffffff 50%, #ffffff); }



.progress-circle.progress-100:after {
  background-image: linear-gradient(90deg, #23C782 50%, transparent 50%, transparent), linear-gradient(270deg, #23C782 50%, #ffffff 50%, #ffffff); }

@-webkit-keyframes colorload {
  0% {
    opacity: 0;
    transform: rotate(0deg); }
  100% {
    opacity: 1;
    transform: rotate(360deg); } }

/*# sourceMappingURL=progress-circle.css.map */
