.home-page-title {
   font-size: 20px;
   font-weight: 600;
   margin-left: 0.9rem;
   color: #2e4866;
}
.home-page {
   color: #2e4866;
   .home-page-title {
      font-size: 20px;
      font-weight: 600;
      margin-left: 0.9rem;
   }

   .page__heading span {
      font-size: 32px;
   }

   @media (max-width: 768px) {
      .page__heading {
         margin-top: 8px;
         span {
            font-size: 16px;
         }
      }
   }
}

.announcement-div {
   margin-top: -5px;
   background-color: #142133;
   width: 100%;
   height: 60px;
   padding: 10px 54px;
   display: flex;
   align-items: center;
   color: #FFFFFF;
}

.announcement-div span p {
   color: #FFFFFF !important;
}

.settingmodal a{
 color: #2e485c;
}