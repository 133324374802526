.perkeso_profile__container {
    margin-top: 2rem;

    .details__container {
        .details__sub_container {
            margin-bottom: 2rem;

            .details__header {
                margin: 0 3.1rem;
                span {
                    font-weight: 600;
                    color: #2e485c80;
                    text-transform: uppercase;
                }
            }
        }
    }
}
